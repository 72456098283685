export const styles = {
  "& .MuiStepLabel-root .Mui-active": {
    color: "#0B2F36", // circle color (ACTIVE)
  },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "#0B2F36", // circle color (COMPLETED)
  },
  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
    fill: "common.white", // circle's number (ACTIVE)
  },
  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
    color: "grey.500", // Just text label (COMPLETED)
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: "#0B2F36", // Just text label (ACTIVE)
  },
  "& .MuiStepLabel-label": {
    color: "grey.700", // Just text label (INACTIVE)
  },
};
