/* eslint-disable no-duplicate-imports */
import * as React from "react";
import dayjs, { type Dayjs } from "dayjs";

import Box from "@mui/material/Box";
import {
  ptBR,
  DataGrid,
  GridToolbar,
  type GridColDef,
  type GridValueGetterParams,
  type GridRenderCellParams,
  GridActionsCellItem,
  GridVisibilityOffIcon,
  type GridFilterModel,
} from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import type { Theme } from "@mui/material/styles";

import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import { lawApi } from "../../../../services/api";

interface IFinancialTable {
  records: any;
  loadingDataGrid: boolean;
  refreshRecords: () => Promise<void>;
  handleShowRecord: (data: any) => void;
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Sem registros</Box>
    </StyledGridOverlay>
  );
}

function FinancialTable({
  records,
  refreshRecords,
  loadingDataGrid,
  handleShowRecord,
}: IFinancialTable) {
  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "date",
      headerName: "Data",
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        dayjs(params.row.date).format("DD/MM/YYYY"),
    },
    {
      field: "value",
      headerName: "Valor",
      width: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `R$${params.row.value || ""}`,
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <MDBox
          display="flex"
          justifyContent="center"
          sx={{
            backgroundColor:
              params.row.type === "Entrada" ? "#0a433b" : "#6B1111",
            borderRadius: "50px",
            width: "100%",
          }}
        >
          <MDTypography
            variant="text"
            sx={{
              color: "#fff",
              fontSize: "12px",
            }}
          >
            {params.row.type}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      field: "action",
      headerName: "Ações",
      type: "actions",
      sortable: false,
      filterable: false,
      flex: 1,
      getActions: (params) => [
        // eslint-disable-next-line react/jsx-key
        <Tooltip title="Ver detalhes">
          <GridActionsCellItem
            icon={<VisibilityIcon sx={{ color: "#fff" }} />}
            label="Ver detalhes"
            onClick={() => {
              handleShowRecord(params.row);
              // console.log(window.innerWidth);
              // if (window.innerWidth < 768) {
              //   window.scrollTo(0, -window.innerHeight);
              // } else {
              //   window.scrollTo(0, 0);
              // }

              const hiddenElement = document.createElement("a");
              hiddenElement.href = "#viewTransaction";
              // hiddenElement.target = "_blank";
              // hiddenElement.download = "animal.csv";
              hiddenElement.click();
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <MDBox
      sx={{
        backgroundColor: "#0B2F36",
        p: 5,
        borderRadius: "20px",
      }}
    >
      <MDBox mb={2} sx={{ width: "100%" }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <MDTypography sx={{ color: "#fff", opacity: "83%" }}>
            Transações
          </MDTypography>
          <Tooltip title="Atualizar lista de registros">
            <IconButton
              onClick={() => {
                void refreshRecords();
              }}
            >
              <RefreshIcon sx={{ color: "#fff", opacity: "83%" }} />
            </IconButton>
          </Tooltip>
        </MDBox>
        {/* <Grid
          container
          direction="row"
          justifyContent="flex-start"
          // alignItems="center"
          spacing={2}
          // sx={{ backgroundColor: "#0ff" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={6} m={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  label="Data inicial"
                  defaultValue={dayjs()}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "#fff",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                      opacity: "83%",
                    },
                    "& .MuiButtonBase-root": {
                      color: "#fff",
                      opacity: "83%",
                    },
                    "&.MuiInputBase-input": {
                      color: "#fff",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#fff",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} m={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  label="Data final"
                  defaultValue={dayjs()}
                  sx={{
                    // "& input": {
                    //   // height: "15px",
                    //   borderRadius: "10px",
                    //   width: "100%",
                    // },
                    "& .MuiInputBase-root": {
                      // width: "180px",
                      // width: "100%",
                      // paddingRight: "10px",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                      opacity: "83%",
                    },
                    "& .MuiButtonBase-root": {
                      color: "#fff",
                      opacity: "83%",
                      // display: "none",
                      // width: "10px",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid> */}
      </MDBox>
      <MDBox
        sx={{
          height: 400,
          width: "100%",
        }}
      >
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={records ?? []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
          slotProps={{
            toolbar: {
              csvOptions: { fileName: "law_financeiro" },
              printOptions: { fileName: "law_financeiro" },
            },
            filterPanel: {
              filterFormProps: {
                // Customize inputs by passing props
                logicOperatorInputProps: {
                  variant: "outlined",
                  size: "small",
                },
                columnInputProps: {
                  variant: "outlined",
                  size: "small",
                  sx: { mt: "auto" },
                },
                operatorInputProps: {
                  variant: "outlined",
                  size: "small",
                  sx: { mt: "auto" },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: "outlined",
                    size: "small",
                  },
                },
                deleteIconProps: {
                  sx: {
                    "& .MuiSvgIcon-root": { color: "#d32f2f" },
                  },
                },
              },
              sx: {
                // Customize inputs using css selectors
                "& .MuiDataGrid-filterForm": { p: 2 },
                "& .MuiDataGrid-filterForm:nth-child(even)": {
                  backgroundColor: (theme: Theme) =>
                    theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
                },
                "& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
                "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
                "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
                "& .MuiDataGrid-filterFormValueInput": { width: 200 },
              },
            },
          }}
          getRowId={(row) => row?._id}
          pageSizeOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          loading={loadingDataGrid}
          sx={{
            backgroundColor: "transparent",
            borderColor: "#0B2F36",
            borderRadius: "20px",
            color: "#ffffff",
            mb: 4,
            "& .css-1lcr87c-MuiInputBase-root-MuiTablePagination-select": {
              backgroundColor: "#f0f1f3",
              borderRadius: "6px",
            },
            "& .MuiTablePagination-displayedRows, & .css-1y1mi5n-MuiTablePagination-root .MuiTablePagination-selectLabel":
              {
                color: "#ffffff",
              },
            "& .MuiTablePagination-actions button": {
              color: "#fff",
            },
            "& .MuiTablePagination-actions .MuiButtonBase-root.Mui-disabled": {
              color: "#a6a6a6",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderColor: "#0B2F36",
            },
            "& .MuiDataGrid-footerContainer": {
              borderColor: "#0B2F36",
            },
            "& .MuiDataGrid-cell": {
              borderColor: "#0B2F36",
            },
            "& .MuiDataGrid-toolbarContainer button": {
              color: "#fff",
            },
            "& .MuiDataGrid-toolbarContainer": {
              padding: 0,
              ml: -0.5,
            },
          }}
        />
      </MDBox>
    </MDBox>
  );
}

export default FinancialTable;
