/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import * as React from "react";

// @mui material components
import {
  ptBR,
  DataGrid,
  GridActionsCellItem,
  type GridColDef,
  type GridValueGetterParams,
} from "@mui/x-data-grid";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReplayIcon from "@mui/icons-material/Replay";

import { styled } from "@mui/material/styles";

// Material Dashboard 2 Components
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import { lawApi } from "../../../../services/api";

interface ICustomer {
  [x: string]: any;
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
  active: boolean;
}

interface ICustomersTable {
  loading: boolean;
  customers: ICustomer;
  handleOpenNewUserDialog: () => void;
  handleOpenUser: (data: ICustomer) => any;
  matches: boolean;
  isUpdate: boolean;
  setIsUpdateClient: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Sem dados</Box>
    </StyledGridOverlay>
  );
}

export default function CustomersTable({
  loading,
  customers,
  handleOpenUser,
  handleOpenNewUserDialog,
  matches,
  isUpdate,
  setIsUpdateClient,
}: ICustomersTable) {
  const [isCustomersFiltered, setIsCustomersFiltered] =
    React.useState<boolean>(false);
  const [loadingFilter, setLoadingFilter] = React.useState<boolean>(false);
  const [filteredCustomers, setFilteredCustomers] = React.useState<
    ICustomer[] | null
  >();
  const [filterByCPF, setFilterByCPF] = React.useState<string>("");
  const [isActiveClient, setIsActiveClient] = React.useState("true");
  const columns = React.useMemo<Array<GridColDef<ICustomer>>>(
    () => [
      {
        field: "fullname",
        headerName: "Nome completo",
        description: "Essa coluna não é ordenável.",
        sortable: false,
        disableColumnMenu: true,
        flex: !matches ? 1 : 0,
        minWidth: 200,

        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.fullname || ""}`,
      },
      {
        field: "cpf",
        headerName: "CPF",
        disableColumnMenu: true,
        flex: !matches ? 1 : 0,
        minWidth: 200,
      },
      {
        field: "action",
        headerName: "",
        type: "actions",
        sortable: false,
        filterable: false,
        flex: !matches ? 1 : 0,
        minWidth: 50,
        getActions: (params) => [
          // eslint-disable-next-line react/jsx-key
          <Tooltip title="Ver Mais">
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Delete"
              onClick={handleOpenUser(params.row)}
            />
          </Tooltip>,
        ],
      },
    ],
    [handleOpenUser],
  );

  const columnsInactive = React.useMemo<Array<GridColDef<ICustomer>>>(
    () => [
      {
        field: "fullname",
        headerName: "Nome completo",
        description: "Essa coluna não é ordenável.",
        sortable: false,
        disableColumnMenu: true,
        flex: !matches ? 1 : 0,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.fullname || ""}`,
      },
      {
        field: "cpf",
        headerName: "CPF",
        disableColumnMenu: true,
        flex: !matches ? 1 : 0,
        minWidth: 200,
      },
      {
        field: "action",
        headerName: "",
        type: "actions",
        sortable: false,
        filterable: false,
        flex: !matches ? 1 : 0,
        minWidth: 50,
        getActions: (params) => [
          // eslint-disable-next-line react/jsx-key
          <Tooltip title="Ativar Cliente">
            <GridActionsCellItem
              icon={<ReplayIcon />}
              label="Ativar cliente"
              onClick={() => {
                void handleActiveClient(params.row);
              }}
            />
          </Tooltip>,
        ],
      },
    ],
    [handleOpenUser],
  );

  const handleActiveClient = async (data: ICustomer) => {
    try {
      const payload = {
        ...data,
        active: true,
      };
      const response = await lawApi.put(`/clients/${data._id}`, payload);
      setIsActiveClient("true");
      await handleFilterCustomerData();
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleFilterCustomerData = async (): Promise<void> => {
    setIsCustomersFiltered(true);

    setLoadingFilter(true);

    try {
      const response = await lawApi.get("/clients", {
        params: {
          isActiveClient,
          find: filterByCPF.trim(),
        },
      });

      setFilteredCustomers(response.data.data.clients);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoadingFilter(false);
    }
  };

  React.useEffect(() => {
    void handleFilterCustomerData();
    setIsUpdateClient(false);
  }, [isActiveClient, filterByCPF, isUpdate]);

  return (
    <MDBox sx={{ width: "100%" }}>
      <MDBox my={2}>
        <MDTypography variant="h5" sx={{ color: "#0B2F36" }}>
          Clientes
        </MDTypography>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        flexDirection={matches ? "column" : "row"}
        mb={4}
        gap={matches && "15px 0"}
        sx={{ width: "100%" }}
      >
        <MDBox
          display="flex"
          gap="1rem"
          flexDirection={matches ? "column-reverse" : "row"}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: matches ? "100%" : "25ch",
              height: "40px",
              backgroundColor: "transparent !important",
              border: "1px solid #DDCA92",
              boxShadow: "none !important",
            }}
          >
            <InputBase
              onChange={(e) => {
                setFilterByCPF(e.target.value);
              }}
              sx={{
                ml: 1,
                flex: 1,
                "& ::placeholder": { fontSize: "14px", mx: 1 },
                "&": { fontSize: "14px" },
              }}
              placeholder="Buscar por nome ou cpf"
              inputProps={{ "aria-label": "buscar" }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <MDBox
              sx={{
                backgroundColor: "#0B2F36",
                borderRadius: 1,
                height: "40px",
              }}
              mx={-0.55}
            >
              <IconButton sx={{ px: "7px" }}>
                <SearchIcon sx={{ color: "#DDCA92" }} />
              </IconButton>
            </MDBox>
          </Paper>

          <MDBox
            display="flex"
            // justifyContent="center"
            justifyContent={matches ? "start" : "center"}
            alignItems="center"
            // alignItems={matches ? "start" : "center"}
            sx={
              {
                // alignItems: {matches ? "center" : "start"}
                // : "center",
              }
            }
            gap="1rem"
          >
            <InputLabel id="demo-simple-select-label">Filtrar: </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={isActiveClient}
              // label="Age"
              onChange={(e) => {
                setIsActiveClient(e.target.value);
              }}
              sx={{
                width: "100px",
                height: "40px",
                outline: "none",

                "&.MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#DDCA92",
                  },
                },
                ".css-1kvvgjv-MuiSvgIcon-root-MuiSelect-icon, .css-ixc2ow-MuiSvgIcon-root-MuiSelect-icon":
                {
                  display: "initial",
                },
              }}
            >
              <MenuItem value={"true"}>Ativo</MenuItem>
              <MenuItem value={"false"}>Inativo</MenuItem>
            </Select>
          </MDBox>
        </MDBox>
        {/* </Paper> */}
        <MDButton
          onClick={handleOpenNewUserDialog}
          variant="gradient"
          color="success"
          size="medium"
          sx={{
            backgroundColor: "#0B2F36",
            boxShadow: "none",
            color: "#f1f1f1",
            "&:hover": {
              backgroundColor: "#0B1F36",
              boxShadow: "none",
            },
            height: "40px",
            textTransform: "capitalize",
          }}
        >
          Cadastrar&nbsp;{" "}
          <Icon sx={{ color: "#DDCA92", fontSize: 40 }}>add</Icon>
        </MDButton>
      </MDBox>
      <MDBox sx={{ height: 400, width: "100%" }}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          // @ts-expect-error
          rows={
            isCustomersFiltered && filteredCustomers != null
              ? filteredCustomers
              : customers
          }
          columns={isActiveClient === "true" ? columns : columnsInactive}
          paginationMode="client"
          pageSizeOptions={[5, 10, 25]}
          disableRowSelectionOnClick
          sortingMode="client"
          filterMode="client"
          // autoHeight
          autoPageSize={true}
          getRowId={(row) => row?._id}
          loading={isCustomersFiltered ? loadingFilter : loading}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          sx={{
            boxShadow: 2,
            backgroundColor: "#fff",
            borderRadius: 2,

            "& .MuiDataGrid-cell:hover": {
              color: "#333",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "#0B2F36",
              fontWeight: 600,
            },
          }}
        />
      </MDBox>
    </MDBox>
  );
}
