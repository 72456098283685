/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";

import { api, lawApi } from "../../services/api";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

import { CircularProgress, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";

// Authentication layout components
import BasicLayout from "./components/BasicLayout";

// Signup components
import CreditCard from "./components/CreditCard";
import SubscriptionPlans from "./components/SubscriptionPlans";

// Images
import logo from "../../../assets/images/logos/logo-law.png";
import SignUpSteps from "./components/SignUpSteps";

// Steps data
import { steps } from "./components/SignUpSteps/data/steps";
import toast from "react-hot-toast";

interface IFormInput {
  name: string;
  surname: string;
  email: string;
  password: string;
  password_confirmation: string;
  application: string;
}

interface IData {
  platform_code: string;
  plan_id: string;
  payday: number;
}

interface IApplication {
  _id: string;
  active: boolean;
  code: string;
  // maximum_users: number;
  name: string;
  owner_id: string;
  payday: number;
  plan_id: string;
  platform_id: string;
  assinatura_pagBank_id: string;
  customer_pagBank_id: string;
}

function PlanUpdate() {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [lockButton, setLockButton] = useState<boolean>(true);

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm<IFormInput>();

  // First step fields - personal info
  const [data, setData] = useState<IData>({
    platform_code: "",
    plan_id: "",
    payday: 0,
  });
  const [userEmail, setEmail] = useState<string>("");
  const [cardTokenID, setCardTokenID] = useState<string>("");

  // Second step - subscription plan
  const [lawPlans, setPlans] = useState<object[]>();
  const [subscription, setSubscription] = useState<number>(-1);
  const [planID, setPlanID] = useState<string>("");
  const [platformID, setPlatformID] = useState<string>("");
  const [platformCode, setPlatformCode] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [isFree, setIsFree] = useState<boolean>(false);
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [currentSteps, setCurrentSteps] = useState<string[]>([
    "Informações",
    "Selecionar Plano",
    "Pagamento",
  ]);

  const [application, setApplication] = useState<IApplication>({
    _id: "",
    active: true,
    code: "",
    // maximum_users: 0,
    name: "",
    owner_id: "",
    payday: 0,
    plan_id: "",
    platform_id: "",
    assinatura_pagBank_id: "",
    customer_pagBank_id: "",
  });

  const params = useParams();

  const handleNext = (): void => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleReset = (): void => {
    setActiveStep(0);
  };

  const getPlatformCode = (platformID: string): void => {
    api
      .get(`/platforms/${platformID}`)
      .then((response) => {
        setPlatformCode(response.data.code);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        const applicationID = params.id;
        const applicationResponse = await api.get(
          `/applications/${applicationID}`,
        );

        setApplication(applicationResponse.data);

        const response = await api.get("/plans");
        const data = response.data;
        setPlans(data);
      } catch (err: any) {
        console.log(err);
      }
    }
    void fetchData();
  }, [params.id]);

  useEffect(() => {
    const currentPlan: any = lawPlans?.find(
      (plans: any) => plans._id === planID,
    );

    if (currentPlan) {
      currentPlan.name === "Gratuito" ? setIsFree(true) : setIsFree(false);
      if (
        currentPlan.name === "Gratuito" ||
        (currentPlan.name !== "Gratuito" &&
          application.assinatura_pagBank_id !== null)
      ) {
        setCurrentSteps(["Informações", "Selecionar Plano"]);
      } else {
        setCurrentSteps(["Informações", "Selecionar Plano", "Pagamento"]);
      }
    }

    if (activeStep === steps.length - 1) {
      const formData = {
        platform_code: platformCode,
        plan_id: planID,
        payday: 10,
      };

      setData(formData);
    }
  }, [
    activeStep,
    planID,
    platformCode,
    application.assinatura_pagBank_id,
    lawPlans,
  ]);

  const onSubmit = async () => {
    setOpenLoading(true);

    try {
      if (isFree && application.assinatura_pagBank_id) {
        await api.put(`/payments/cancel`);
      }

      const formData = {
        plan_id: planID,
      };

      const response = await api.put("/payments/", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        navigate("/updated-plan");
      }
    } catch (err: any) {
      const code = err.response.data.statusCode;
      const message = err.response.data.message;
      if (code === 400) {
        toast.error(message, {
          duration: 5000,
        });
      } else if (code === 404) {
        toast.error("Ocorreu um erro ao logar. Tente novamente mais tarde!", {
          duration: 5000,
        });
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro ao logar. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      } else {
        toast.error("Ocorreu um erro ao logar. Verifique as credenciais!", {
          duration: 5000,
        });
      }
    } finally {
      setOpenLoading(false);
    }
  };

  return (
    <BasicLayout>
      <MDBox
        height="auto"
        maxWidth="1280px"
        minWidth="80vw"
        pt={4}
        pb={3}
        my={8}
        px={4}
        sx={{
          borderRadius: "11px",
          boxShadow: 2,
        }}
      >
        <MDBox display="flex" justifyContent="center" mb={4} mt={-1}>
          <SignUpSteps activeStep={activeStep} steps={currentSteps} />
        </MDBox>
        {activeStep === steps.length ? (
          <MDBox>
            <MDTypography sx={{ mt: 2, mb: 1 }}>
              Todos os passos completos - tudo pronto!
            </MDTypography>
            <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <MDBox sx={{ flex: "1 1 auto" }} />
              <MDButton
                onClick={handleReset}
                sx={{
                  backgroundColor: "#0B2F36",
                  color: "#DDCA92",
                  border: "1px solid #DDCA92",
                }}
              >
                Resetar
              </MDButton>
            </MDBox>
          </MDBox>
        ) : (
          <MDBox>
            <MDBox>
              {activeStep === 1 && (
                <Grid
                  container
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                  gap={2}
                >
                  {lawPlans?.map((item: any, index) => {
                    if (item._id !== application.plan_id) {
                      return (
                        <SubscriptionPlans
                          key={index}
                          plan={item}
                          index={index}
                          setSubscription={setSubscription}
                          subscription={subscription}
                          setPlanID={setPlanID}
                          setPlatformID={setPlatformID}
                          getPlatformCode={getPlatformCode}
                          setAmount={setAmount}
                          setLockButton={setLockButton}
                        />
                      );
                    }

                    return <></>;
                  })}
                </Grid>
              )}
              {activeStep === 2 && (
                <CreditCard
                  email={userEmail}
                  amount={amount}
                  // setCardTokenID={setCardTokenID}
                  handleBack={handleBack}
                  lockButton={lockButton}
                  planId={planID}
                  isFree={isFree}
                  application={application}
                />
              )}
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                pt: 2,
                gap: "0 10px ",
                flexWrap: "wrap",
                mb: 2,
              }}
            >
              {activeStep === 0 ? (
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mt={4}
                  mb={1}
                >
                  <MDButton
                    component={Link}
                    to="/profile"
                    variant="none"
                    sx={{
                      width: "130px",

                      padding: "10px",
                      textTransform: "capitalize",
                      color: "#0B2F36",
                      border: "2px solid #DDCA92",
                      borderRadius: "10px",
                    }}
                  >
                    Voltar ao perfil
                  </MDButton>
                </MDBox>
              ) : activeStep >= 1 && activeStep !== steps.length - 1 ? (
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mt={4}
                  mb={1}
                >
                  <MDButton
                    variant="none"
                    onClick={handleBack}
                    sx={{
                      width: "130px",

                      padding: "10px",
                      textTransform: "capitalize",
                      color: "#0B2F36",
                      border: "2px solid #DDCA92",
                      borderRadius: "10px",
                    }}
                  >
                    Voltar
                  </MDButton>
                </MDBox>
              ) : (
                <></>
              )}
              {activeStep !== steps.length - 1 ? (
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mt={4}
                  mb={1}
                >
                  <MDButton
                    variant="None"
                    // activeStep === 0 ? handleSubmit(handleNext) : handleNext
                    onClick={() => {
                      if (
                        isFree ||
                        (!isFree && application.assinatura_pagBank_id)
                      ) {
                        void onSubmit();
                      } else {
                        handleNext();
                      }
                    }}
                    disabled={lockButton}
                    sx={{
                      width: "130px",

                      padding: "10px",
                      textTransform: "capitalize",
                      color: "#DDCA92",
                      background:
                        "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                      border: "1px solid #DDCA92",
                      borderRadius: "10px",
                    }}
                  >
                    {/* {activeStep === steps.length - 1 ? "Finalizar" : "Avançar"} */}
                    {openLoading ? (
                      <CircularProgress color="primary" />
                    ) : isFree ||
                      (!isFree && application.assinatura_pagBank_id) ? (
                      "Atualizar plano"
                    ) : (
                      "Avançar"
                    )}
                  </MDButton>
                </MDBox>
              ) : (
                <></>
              )}
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </BasicLayout>
  );
}

export default PlanUpdate;
