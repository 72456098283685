/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import dayjs, { Dayjs } from "dayjs";
import { MuiTelInput } from "mui-tel-input";
import { useForm, type SubmitHandler } from "react-hook-form";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

// import { api } from "../../services/api";

// react-router-dom components
import { Link } from "react-router-dom";

import {
  DateCalendar,
  DigitalClock,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, Paper, InputLabel, Switch } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";

import Layout from "../Layout";

import logo from "../../../../assets/images/logos/law_color_secondary.svg";
import check from "../../../../assets/images/icons/check_icon.svg";

export default function ScheduleConfirmed() {
  return (
    <Layout>
      <MDBox display="flex" flexDirection="column" alignItems="center" mb={4}>
        <MDBox display="flex" justifyContent="center" my={2}>
          <img
            src={logo}
            alt="logo da jupiter"
            style={{ maxWidth: "50%", width: "50%" }}
          />
        </MDBox>

        <MDBox sx={{ minWidth: "50vw" }}>
          <Paper
            variant="outlined"
            sx={{ border: "3px solid #DDCA92", borderRadius: "20px" }}
          >
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: "#DDCA92",
                borderRadius: "10px",
              }}
            >
              <MDTypography
                // @ts-expect-error
                py={2}
                variant="h4"
                sx={{ color: "#0B2F36" }}
              >
                Agendamento
              </MDTypography>
            </MDBox>
            <MDBox display="flex" sx={{ padding: "30px 40px 40px 40px" }}>
              <MDBox
                // m={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  // padding: "20px 30px 0 30px",
                }}
                gap="50px 0"
              >
                <MDBox display="flex" flexDirection="column">
                  <MDTypography
                    sx={{ fontWeight: 600, fontSize: "24px", color: "#0B2F36" }}
                  >
                    Agendamento feito com sucesso
                  </MDTypography>
                  <MDTypography
                    sx={{
                      fontWeight: "regular",
                      fontSize: "16px",
                      color: "#0B2F36",
                    }}
                  >
                    Seu agendamento foi confirmado, veja abaixo o resumo.
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap="10px 0"
                >
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="0 10px"
                  >
                    <CalendarTodayIcon
                      fontSize="medium"
                      sx={{ color: "#0B2F36" }}
                    />
                    <MDTypography
                      sx={{
                        fontWeight: 500,
                        color: "#0B2F36",
                        fontSize: "20px",
                      }}
                    >
                      Sexta-feira, 09h00 - 10h00
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="0 10px"
                    sx={{ maxWidth: "90%" }}
                  >
                    <RoomOutlinedIcon
                      fontSize="medium"
                      sx={{ color: "#0B2F36" }}
                    />
                    <MDTypography
                      sx={{
                        fontWeight: 500,
                        color: "#0B2F36",
                        fontSize: "15px",
                      }}
                    >
                      CEP 45600-063 - Travessa do Correio, Centro, Itabuna, n
                      456 - BA
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <img
                  src={check}
                  alt=""
                  style={{ maxWidth: "100%", width: "85%" }}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="center" mb={2}>
              <MDButton
                // onClick={() => {
                //   handleChangeFormStep(1);
                // }}
                sx={{
                  width: "170px",

                  padding: "10px",
                  textTransform: "capitalize",
                  color: "#fff",
                  background:
                    "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                  border: "1px solid #DDCA92",
                  borderRadius: "10px",
                }}
              >
                Novo agendamento
              </MDButton>
            </MDBox>
          </Paper>
        </MDBox>
      </MDBox>
    </Layout>
  );
}
