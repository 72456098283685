/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef, useCallback } from "react";

import dayjs from "dayjs";

import { lawApi } from "../../services/api";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

import TransactionsChart from "./components/TransactionsTable";
import CashFlowStatisticsCard from "./components/StatisticCard";
import FinancialTable from "./components/FinancialTable";
import InForm from "./components/InForm";
import Details from "./components/Details";
import EditForm from "./components/EditForm";
import DeleteRecordDialog from "./components/DeleteRecord";
import useEffectOnce from "../../hooks/useEffectOnce";
import { toast } from "react-hot-toast";

function Financial() {
  const [newRecordForm, setNewRecordForm] = useState<boolean>(false);
  // const [outForm, setOutForm] = useState<boolean>(false);
  const [editForm, setEditForm] = useState<boolean>(false);
  const [newRecordType, setNewRecordType] = useState<string>("Entrada");
  const [editFormType, setEditFormType] = useState<string>("Entrada");
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [recordsFetched, setRecordsFetched] = useState<boolean>(false);
  const [records, setRecords] = useState<any>();
  const [record, setRecord] = useState<any>();
  const [recordToDelete, setRecordToDelete] = useState<any>();
  const [openDeleteRecordDialog, setOpenDeleteRecordDialog] =
    useState<boolean>(false);
  const [analytics, setAnalytics] = useState<any>();
  const [loadingDataGrid, setLoadingDataGrid] = useState<boolean>(false);

  const handleShowNewRecordForm = (type: string) => {
    setNewRecordForm(true);
    setShowDetails(false);
    setEditForm(false);

    setNewRecordType(type);
  };

  const handleShowDetails = () => {
    setShowDetails(true);
    setNewRecordForm(false);
    setEditForm(false);
  };

  const handleShowEditForm = (data: any, type: string) => {
    setEditForm(true);
    setShowDetails(false);
    setNewRecordForm(false);

    setRecord(data);
    setEditFormType(type);
  };

  const handleCloseForms = () => {
    setNewRecordForm(false);
    setShowDetails(false);
    setEditForm(false);
  };

  const handleShowRecord = (data: any) => {
    setRecord(data);
    handleShowDetails();
  };

  const handleOpenDeleteRecordDialog = (data: any) => {
    setRecordToDelete(data);
    setOpenDeleteRecordDialog(true);
  };

  const handleCloseDeleteRecordDialog = () => {
    setOpenDeleteRecordDialog(false);
  };

  const toastFeedbackMessage = (type: string, message: string): any => {
    if (type === "error") {
      return toast.error(`${message}`, {
        duration: 5000,
      });
    }

    return toast.success(`${message}`, {
      duration: 5000,
    });
  };

  const fetchRecords = async (): Promise<void> => {
    // await checkLogin();
    setLoadingDataGrid(true);

    try {
      const response = await lawApi.get("/financial");
      const data = response.data;

      const activeRecords = data.data.financial.filter(
        (record: any) => record.active,
      );

      setRecords(activeRecords);

      const analyticsResponse = await lawApi.get("/financial/analytics");

      setAnalytics(analyticsResponse.data.data.analytics_financial);
    } catch (err: any) {
      // console.log(err);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      }
    } finally {
      setLoadingDataGrid(false);
    }
  };

  const refreshRecords = async () => {
    setLoadingDataGrid(true);
    try {
      const response = await lawApi.get("/financial");
      const data = response.data;

      const activeRecords = data.data.financial.filter(
        (record: any) => record.active,
      );

      setRecords(activeRecords);
    } catch (err: any) {
      // console.log(err);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      }
    } finally {
      setLoadingDataGrid(false);
    }
  };

  useEffect(() => {
    if (!recordsFetched) {
      setRecordsFetched(true);
    }
  }, [recordsFetched]);

  useEffectOnce(() => {
    void fetchRecords();
  }, recordsFetched);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <CashFlowStatisticsCard
                    color="#0B2F36"
                    title={`Entrada ${
                      dayjs().locale("pt-br").format("MMMM")
                      // .toLocaleLowerCase()
                    }
                      `}
                    count={analytics?.total_financial_input ?? 0}
                    percentage={analytics?.percentage_input ?? 0}
                    icon="call_received_outlined"
                    iconColor="#0B2F36"
                    chipColor="#0a433b"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <CashFlowStatisticsCard
                    color="#0B2F36"
                    title={`Saída ${
                      dayjs().locale("pt-br").format("MMMM")
                      // .toLocaleLowerCase()
                    }
                      `}
                    count={analytics?.total_financial_output ?? 0}
                    percentage={analytics?.percentage_output ?? 0}
                    icon="call_made_outlined"
                    iconColor="#6B1111"
                    chipColor="#6B1111"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <CashFlowStatisticsCard
                    color="#0B2F36"
                    title="Entrada mês passado"
                    count={analytics?.total_financial_input_before ?? 0}
                    percentage={analytics?.percentage_input_before ?? 0}
                    icon="call_received_outlined"
                    iconColor="#0B2F36"
                    chipColor="#0a433b"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <CashFlowStatisticsCard
                    color="#0B2F36"
                    title="Saída mês passado"
                    count={analytics?.total_financial_output_before ?? 0}
                    percentage={analytics?.percentage_output_before ?? 0}
                    icon="call_made_outlined"
                    iconColor="#6B1111"
                    chipColor="#6B1111"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <CashFlowStatisticsCard
                    color="#0B2F36"
                    title="Entrada mês seguinte"
                    count={analytics?.total_financial_input_after ?? 0}
                    percentage={analytics?.percentage_input_after ?? 0}
                    icon="call_received_outlined"
                    iconColor="#0B2F36"
                    chipColor="#0a433b"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <CashFlowStatisticsCard
                    color="#0B2F36"
                    title="Saída mês seguinte"
                    count={analytics?.total_financial_output_after ?? 0}
                    percentage={analytics?.percentage_output_after ?? 0}
                    icon="call_made_outlined"
                    iconColor="#6B1111"
                    chipColor="#6B1111"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mb={1}>
                <TransactionsChart
                  // icon={{ color: "info", component: "leaderboard" }}
                  title={`Entradas e saídas ${dayjs().year()}`}
                  // description="Sales related to age average"
                  chart={{
                    labels: [
                      "Jan",
                      "Fev",
                      "Mar",
                      "Abr",
                      "Mai",
                      "Jun",
                      "Jul",
                      "Ago",
                      "Set",
                      "Out",
                      "Nov",
                      "Dez",
                    ],
                    datasets: [
                      {
                        label: "Entrada",
                        color: "#0B2F36",
                        data: [
                          analytics?.total_month.jan.entrada,
                          analytics?.total_month.fev.entrada,
                          analytics?.total_month.mar.entrada,
                          analytics?.total_month.abr.entrada,
                          analytics?.total_month.mai.entrada,
                          analytics?.total_month.jun.entrada,
                          analytics?.total_month.jul.entrada,
                          analytics?.total_month.ago.entrada,
                          analytics?.total_month.set.entrada,
                          analytics?.total_month.out.entrada,
                          analytics?.total_month.nov.entrada,
                          analytics?.total_month.dez.entrada,
                        ],
                      },
                      {
                        label: "Saída",
                        color: "#6B1111",
                        data: [
                          analytics?.total_month.jan.saida,
                          analytics?.total_month.fev.saida,
                          analytics?.total_month.mar.saida,
                          analytics?.total_month.abr.saida,
                          analytics?.total_month.mai.saida,
                          analytics?.total_month.jun.saida,
                          analytics?.total_month.jul.saida,
                          analytics?.total_month.ago.saida,
                          analytics?.total_month.set.saida,
                          analytics?.total_month.out.saida,
                          analytics?.total_month.nov.saida,
                          analytics?.total_month.dez.saida,
                        ],
                      },
                    ],
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FinancialTable
                  records={records}
                  refreshRecords={refreshRecords}
                  loadingDataGrid={loadingDataGrid}
                  handleShowRecord={handleShowRecord}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    border: "1px solid #DDCA92",
                    borderRadius: "20px",
                    py: 4,
                    boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
                  }}
                >
                  {newRecordForm && (
                    <InForm
                      fetchRecords={fetchRecords}
                      newRecordType={newRecordType}
                      handleCloseInForm={handleCloseForms}
                      toastFeedbackMessage={toastFeedbackMessage}
                    />
                  )}
                  {editForm && (
                    <EditForm
                      type={editFormType}
                      record={record}
                      fetchRecords={fetchRecords}
                      handleCloseEditForm={handleCloseForms}
                      toastFeedbackMessage={toastFeedbackMessage}
                    />
                  )}
                  {showDetails && (
                    <Details
                      record={record}
                      handleShowEditForm={handleShowEditForm}
                      handleOpenDeleteRecordDialog={
                        handleOpenDeleteRecordDialog
                      }
                      handleCloseDetails={handleCloseForms}
                    />
                  )}
                  {newRecordForm || showDetails || editForm || (
                    <>
                      <MDTypography variant="h5" sx={{ color: "#0B2F36" }}>
                        Novo registro
                      </MDTypography>
                      <MDTypography
                        variant="subtitle2"
                        sx={{ color: "#131313", textAlign: "center", px: 2 }}
                      >
                        Selecione se deseja registrar uma nova entrada ou nova
                        saída
                      </MDTypography>
                      <MDButton
                        onClick={() => {
                          handleShowNewRecordForm("Entrada");
                        }}
                        variant="contained"
                        sx={{
                          backgroundColor: "#0B2F36",
                          borderRadius: "15px",
                          color: "#FFFFFF",
                          width: "240px",
                          mt: 5,
                          "&:hover": {
                            backgroundColor: "#0B2F36",
                            color: "#FFFFFF",
                          },
                        }}
                      >
                        Entrada
                      </MDButton>
                      <MDButton
                        onClick={() => {
                          handleShowNewRecordForm("Saída");
                        }}
                        variant="contained"
                        sx={{
                          backgroundColor: "#6B1111",
                          borderRadius: "15px",
                          color: "#FFFFFF",
                          width: "240px",
                          my: 2,
                          "&:hover": {
                            backgroundColor: "#6B1111",
                            color: "#FFFFFF",
                          },
                        }}
                      >
                        Saída
                      </MDButton>
                    </>
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DeleteRecordDialog
          openDeleteRecordDialog={openDeleteRecordDialog}
          recordToDelete={recordToDelete}
          handleCloseDeleteRecordDialog={handleCloseDeleteRecordDialog}
          fetchRecords={fetchRecords}
          handleCloseForms={handleCloseForms}
          toastFeedbackMessage={toastFeedbackMessage}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Financial;
