/* eslint-disable no-useless-escape */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { type RadioProps } from "@mui/material/Radio";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  styled,
} from "@mui/material";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import "dayjs/locale/br";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";
import MDInput from "../../../../../components/MDInput";
import { lawApi } from "../../../../../services/api";

interface ISchedulingLink {
  matches: boolean;
  open: boolean;
  handleClose: () => void;
  user: any | undefined;
  edit: boolean;
  scheduleSelected?: string;
  toastFeedbackMessage: (type: string, message: string) => void;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IClient {
  name: string;
  last_name: string;
  email: string;
}

interface ISchedule {
  date: any;
  start_time: string;
  end_time: string;
}

interface IInputs {
  email: string | null;
  title: string;
  description: string | null;
  link: string | null;
  address: string | null;
  client_name: string;
  // client_last_name: string;
  client_email: string;
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "1px auto #0B2F36",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    // backgroundColor: "#0B2F36",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#0B2F36",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#0B2F36,#0B2F36 28%,transparent 32%)",
    border: "2px auto #fff",
    borderRadius: "50%",

    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#0B2F36",
  },
});

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  title: yup
    .string()
    .min(5, "No mínimo 5 caracteres")
    .max(100, "No máximo 100 caracteres")
    .required("Informe o nome título do agendamento"),
  description: yup.string().nullable().default(""),
  address: yup.string().nullable().default(""),
  client_name: yup.string().default(""),
  client_email: yup.string().default(""),
  email: yup
    .string()
    .nullable()
    .default("")
    .matches(/^$|^.*@.*\..*$/, "Formato de e-mail inválido"),
  link: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Formato de link inválido",
    )
    .nullable()
    .default(""),
});

const ScheduleForm = ({
  open,
  matches,
  handleClose,
  user,
  edit,
  scheduleSelected,
  toastFeedbackMessage,
  setUpdated,
}: ISchedulingLink) => {
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });

  const [schedule, setSchedule] = useState<ISchedule>({
    date: null,
    start_time: "",
    end_time: "",
  });
  const [typeMeet, setTypeMeet] = useState<string>("remota");
  const [optionsStartTime, setOptionsStartTime] = useState<string[]>([]);
  const [optionsEndTime, setOptionsEndTime] = useState<string[]>([]);
  const [guest, setGuest] = useState<string>("");
  const [guests, setGuests] = useState<string[]>([]);
  const [loadingAvailableHours, setLoadingAvailableHours] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (typeMeet === "other" && user.address_id) {
      void getAddress(user.address_id);
    } else {
      setValue("address", "");
    }
  }, [typeMeet]);

  const handleGuest = (event: any) => {
    const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const validEmail = event.target.value.match(validRegex);

    if (!validEmail) {
      setGuest(event.target.value);
    } else {
      setGuest(event.target.value);
      setValue("email", event.target.value);
    }
  };

  const handleAddGuest = (): void => {
    const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const validEmail = guest.match(validRegex);

    if (!validEmail) {
      toast.error("E-mail inválido", {
        duration: 5000,
      });
    } else {
      guests.push(guest);
      setGuests(guests);

      setGuest("");
      reset({
        email: "",
      });
    }
  };

  const handleRemoveGuest = (index: number) => {
    let data = [...guests];

    data.splice(index, 1);
    setGuests(data);
  };

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    setLoading(true);
    const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const validEmail = data.client_email.match(validRegex);

    if (data.client_email && !validEmail) {
      setError("client_email", { message: "Formato de e-mail inválido." });
      setLoading(false);
      return;
    }
    // setDisableButton(true);

    const formData = {
      title: data.title,
      client: {
        name: data.client_name,
        // last_name: data.client_last_name,
        email: data.client_email,
      },
      local: typeMeet !== "remota" ? data.address : null,
      user_id: user.id,
      guests,
      description: "",
      date: schedule.date,
      start_time: schedule.start_time,
      end_time: schedule.end_time,
      link: typeMeet === "remota" ? data.link : null,
    };

    // console.log(formData);

    try {
      const response = await lawApi.post("/schedules/user", formData);
      const data = response;

      if (data.data.error) {
        toastFeedbackMessage(
          "error",
          data.data.message
            ? data.data.message
            : "Ocorreu um erro no agendamento. Talvez esse horário não esteja mais disponível!",
        );
      } else {
        toastFeedbackMessage("success", "Agendamento registrado com sucesso!");
        setUpdated(true);
      }
    } catch (err: any) {
      // console.log(err);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do agendamento. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do agendamento. Mas não se preocupe, não foi culpa sua!",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do agendamento. Talvez esse horário não esteja mais disponível!",
        );
      }
    } finally {
      setLoading(false);
      setSchedule((prevState) => ({
        ...prevState,
        date: "",
        start_time: "",
        end_time: "",
      }));
      setGuest("");
      setGuests([]);
      handleClose();
    }
  };

  const handleGetStartTime = (date: string | null) => {
    setSchedule((prevState) => ({
      ...prevState,
      date: "",
      start_time: "",
      end_time: "",
    }));

    if (!(dayjs(date).format() === "Invalid Date")) {
      setLoadingAvailableHours(true);

      setTimeout(() => {
        void getStartTime(date);
      }, 1000);
    }
  };

  const getStartTime = async (date: any | null) => {
    try {
      const checkValidDate = dayjs(date).format() === "Invalid Date";
      const currentDate = new Date();
      const _date = new Date(date);
      if (dayjs(date).year().toString().length === 4) {
        if (checkValidDate) {
          return toast.error("Data inválida", { duration: 5000 });
        }

        if (
          _date.getDate() < currentDate.getDate() &&
          _date.getMonth() <= currentDate.getMonth() &&
          _date.getFullYear() <= currentDate.getFullYear()
        ) {
          return toast.error("Data informada é menor que a data atual", {
            duration: 5000,
          });
        }

        if (user) {
          const payload = {
            user_id: user.id,
            date: dayjs(date).format(),
          };

          const response = await lawApi.post(
            "/schedules/availableHourUser",
            payload,
          );

          setSchedule((prevState) => ({
            ...prevState,
            date,
          }));

          setOptionsStartTime(response.data);
          setOptionsEndTime([]);
          setSchedule((prevState) => ({
            ...prevState,
            end_time: "",
          }));
        }

        setLoadingAvailableHours(false);
      }
    } catch (error: any) {
      const code = error.response.data.statusCode;
      const message = error.response.data.message;

      if (code === 400) {
        toast.error(message, { duration: 5000 });
      } else if (code === 404) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      }
    }
  };

  const getEndTime = async (startTime: string) => {
    try {
      if (user && schedule.date && !schedule.end_time) {
        const payload = {
          user_id: user.id,
          date: dayjs(schedule.date).format(),
        };

        const response = await lawApi.post(
          "/schedules/availableHourUser",
          payload,
          { params: { start_time: startTime } },
        );

        const selectableHours = response.data.filter(
          (hour: string) => hour > startTime,
        );

        setOptionsEndTime(selectableHours);
      }
    } catch (error: any) {
      const code = error.response.data.statusCode;
      const message = error.response.data.message;

      if (code === 400) {
        toast.error(message, { duration: 5000 });
      } else if (code === 404) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      }
    }
  };

  const getAddress = async (addressId: string) => {
    if (addressId) {
      const response = await lawApi(`addresses/${addressId}`);

      const { street, cep, number, neighborhood, city, state } =
        response.data.data.address;

      setValue(
        "address",
        `${street}, ${number}, ${neighborhood}. ${city} - ${state}. CEP: ${cep}`,
      );
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    setGuest("");
    setGuests([]);
    setSchedule((prevState) => ({
      ...prevState,
      date: "",
      start_time: "",
      end_time: "",
    }));
    reset();
  }, [handleClose, reset]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xl">
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <MDBox display="flex" justifyContent="center">
          <MDTypography
            sx={{
              color: "#0B2F36",
              fontWeight: "bold",
              fontSize: matches ? "20px" : "24px",
            }}
          >
            Agendamento - Adv {user?.fullname}
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>
      <DialogContent
        sx={{ padding: matches ? "30px 30px 0 30px" : "30px 50px 0 50px" }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                noValidate
                component="form"
                my={2}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>
                        Título{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <MDInput
                        error={!(errors.title == null)}
                        {...register("title")}
                        type="text"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                        fullWidth
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.title?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel>
                        Data{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="br"
                      >
                        <DemoContainer components={["DateField"]}>
                          <DateField
                            // value={schedule.date}
                            onChange={(newValue: string | null) => {
                              handleGetStartTime(newValue);
                              setSchedule({
                                ...schedule,
                                date: newValue,
                              });
                            }}
                            fullWidth
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <MDTypography sx={{ fontSize: "12px" }}>
                        {loadingAvailableHours && "Buscando horários..."}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <MDBox display="flex" flexDirection="column" width="100%">
                      <InputLabel sx={{ mb: 1 }}>
                        Início{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <Select
                        value={schedule.start_time}
                        onChange={(e) => {
                          setSchedule((prevState) => ({
                            ...prevState,
                            start_time: e.target.value,
                          }));
                          setOptionsEndTime([]);
                          setSchedule((prevState) => ({
                            ...prevState,
                            end_time: "",
                          }));

                          // void (edit ? getEndTimeEdit() : getEndTime());
                          void getEndTime(e.target.value);
                        }}
                        disabled={!schedule.date}
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          height: "43px",
                          backgroundColor: "transparent !important",

                          boxShadow: "none !important",
                        }}
                      >
                        {optionsStartTime.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <MDTypography sx={{ fontSize: "12px" }}>
                        {!optionsStartTime &&
                          schedule.date &&
                          "Escolha o horário de início da reunião"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <MDBox display="flex" flexDirection="column" width="100%">
                      <InputLabel sx={{ mb: 1 }}>
                        Fim{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <Select
                        disabled={schedule.start_time === ""}
                        value={schedule.end_time}
                        onChange={(e) => {
                          setSchedule((prevState) => ({
                            ...prevState,
                            end_time: e.target.value,
                          }));
                        }}
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          height: "43px",
                          backgroundColor: "transparent !important",

                          boxShadow: "none !important",
                        }}
                      >
                        {optionsEndTime.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <MDTypography sx={{ fontSize: "12px" }}>
                        {!schedule.end_time &&
                          schedule.start_time &&
                          "Escolha o horário em que a reunião deve terminar"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>
                        Tipo de reunião:{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="remota"
                        name="radio-buttons-group"
                        value={typeMeet}
                        onChange={(e) => {
                          setTypeMeet(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="remota"
                          control={<BpRadio />}
                          label="Remota"
                        />
                        <FormControlLabel
                          value="presencial"
                          control={<BpRadio />}
                          label="Presencial"
                        />
                        <FormControlLabel
                          value="other"
                          control={<BpRadio />}
                          label="Presencial - Endereço do escritório"
                        />
                      </RadioGroup>
                    </MDBox>
                  </Grid>
                  {typeMeet !== "remota" ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1 }}>
                          Local{" "}
                          <MDTypography
                            sx={{
                              fontSize: "12px",
                              color: "#f00",
                              display: "inline",
                            }}
                          >
                            *
                          </MDTypography>
                        </InputLabel>
                        <MDInput
                          disabled={typeMeet === "other"}
                          error={!(errors.address == null)}
                          {...register("address")}
                          type="text"
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.address?.message}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1 }}>
                          Link da reunião{" "}
                          <MDTypography
                            sx={{
                              fontSize: "12px",
                              color: "#f00",
                              display: "inline",
                            }}
                          >
                            *
                          </MDTypography>
                        </InputLabel>
                        <MDInput
                          error={!(errors.link == null)}
                          {...register("link")}
                          type="text"
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.link?.message}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>
                        Nome completo do cliente
                      </InputLabel>
                      <MDInput
                        error={!(errors.client_name == null)}
                        {...register("client_name")}
                        type="text"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                        fullWidth
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.client_name?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>E-mail do cliente</InputLabel>
                      <MDInput
                        error={!(errors.client_email == null)}
                        {...register("client_email")}
                        type="email"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                        fullWidth
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.client_email?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                my={2}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      sx={{ width: "100%" }}
                    >
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        sx={{ width: "100%" }}
                      >
                        <InputLabel sx={{ mb: 1, mt: 1 }}>
                          Convidados
                        </InputLabel>
                        <Paper
                          component="form"
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "43px",
                            backgroundColor: "transparent !important",
                            // border: "1px solid #DDCA92",
                            border: !(errors.email == null)
                              ? "1px solid #f00"
                              : "1px solid #DDCA92",
                            boxShadow: "none !important",
                          }}
                        >
                          <InputBase
                            value={guest}
                            onChange={(event) => {
                              handleGuest(event);
                            }}
                            type="email"
                            sx={{
                              ml: 1,
                              flex: 1,
                              "& ::placeholder": { fontSize: "14px", mx: 1 },
                              "&": { fontSize: "14px" },
                            }}
                            placeholder="Convidados"
                            fullWidth
                          />
                        </Paper>
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.email?.message}
                        </MDTypography>
                        <MDInput
                          error={!(errors.email == null)}
                          {...register("email")}
                          type="email"
                          variant="outlined"
                          sx={{
                            display: "none",
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      </MDBox>
                      <MDButton
                        // disabled={blockEmptyMail}
                        onClick={() => {
                          handleAddGuest();
                        }}
                        variant="contained"
                        sx={{
                          height: "43px",
                          width: "43px",
                          backgroundColor: "#DDCA92",
                          ml: 1,
                          "&:hover": {
                            backgroundColor: "#DDCA92",
                          },
                          "&.MuiButtonBase-root.Mui-disabled": {
                            backgroundColor: "#DDCA92",
                          },
                        }}
                      >
                        <AddIcon sx={{ color: "#000" }} />
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      {guests?.map((guestEmail: string, index: number) => (
                        <MDBox
                          key={index}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          mb={0.5}
                        >
                          <MDTypography sx={{ fontSize: "14px" }}>
                            - {guestEmail}
                          </MDTypography>
                          <IconButton
                            onClick={() => {
                              handleRemoveGuest(index);
                            }}
                          >
                            <DeleteIcon
                              sx={{ color: "#f00", fontSize: 10, ml: 1 }}
                            />
                          </IconButton>
                        </MDBox>
                      ))}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDButton
          onClick={handleClose}
          sx={{
            width: "130px",

            padding: "10px",
            textTransform: "capitalize",
            color: "#0B2F36",
            border: "2px solid #DDCA92",
            borderRadius: "10px",
          }}
        >
          Sair
        </MDButton>
        <MDButton
          disabled={
            !schedule.date || !schedule.start_time || !schedule.end_time
          }
          onClick={handleSubmit(onSubmit)}
          sx={{
            width: "130px",

            padding: "10px",
            textTransform: "capitalize",
            color: "#DDCA92",
            background:
              "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

            border: "1px solid #DDCA92",
            borderRadius: "10px",

            "&:hover": {
              background:
                "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
              border: "1px solid #DDCA92",
              color: "#DDCA92",
            },
            "&.active": {
              background:
                "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
              border: "1px solid #DDCA92",
              color: "#DDCA92",
            },
          }}
        >
          {loading ? <CircularProgress color="primary" /> : "Agendar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleForm;
