/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { lawApi } from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/useAuth";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import {
  Icon,
  Dialog,
  InputLabel,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  TextField,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

// Custom components
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";

interface ICustomer {
  [x: string]: any;
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
  active: boolean;
}

interface IProcess {
  active: boolean;
  application_id: string;
  client_id: string;
  created_at: Date | string;
  code: string;
  files: object[] | null;
  notes: object[] | null;
  _id: string;
}

interface ICreateServiceDialog {
  matches: boolean;
  openNewService: boolean;
  processes: readonly IProcess[];
  userData: ICustomer | null;
  fetchServices: () => Promise<void>;
  handleCloseNewServiceDialog: () => void;
  toastFeedbackMessage: (type: string, message: string) => void;
  setOpenUser: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IInputs {
  label: string;
  message: string;
  // code: string;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  label: yup.string().required("Informe o título do atendimento"),
  message: yup.string().default(""),
});

function CreateServiceDialog({
  matches,
  openNewService,
  fetchServices,
  toastFeedbackMessage,
  handleCloseNewServiceDialog,
  processes,
  userData,
  setOpenUser,
}: ICreateServiceDialog) {
  const { user } = useAuth();
  const [selectedProcess, setSelectedProcess] = useState<any>();

  const {
    reset,
    register,
    handleSubmit,

    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   code: uuidv4(),
    // },
  });
  const [code, setCode] = useState(
    uuidv4().substring(0, 8).toLocaleUpperCase(),
  );
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    setDisableButton(true);

    const arrMessages = [];

    // data.number = +data.number;
    const defaultMessage: any = {
      message: "Atendimento criado",
      user_id: user._id,
      created_at: new Date(),
    };

    arrMessages.push(defaultMessage);

    if (data.message) {
      const messageUser: any = {
        message: data.message,
        user_id: user._id,
        created_at: new Date(),
      };

      arrMessages.push(messageUser);
    }

    const formData = {
      ...data,
      client_id: userData?._id,
      process_id: selectedProcess ? selectedProcess._id : null,
      messages: arrMessages,
      code: code,
    };

    // console.log(formData);

    try {
      const response = await lawApi.post("/services", formData);
      const data = response.data;

      if (data) {
        toastFeedbackMessage("success", "Atendimento cadastrado com sucesso.");
      }
      setDisableButton(false);
    } catch (err: any) {
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do atendimento. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do atendimento. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do atendimento. Verifique os dados informados!",
        );
      }

      setDisableButton(false);
    } finally {
      void fetchServices();
      setDisableButton(false);
      handleCloseNewServiceDialog();
      setOpenUser(true);
    }
  };

  const defaultProps = {
    options: processes,
    getOptionLabel: (option: IProcess) => option.code,
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        label: "",
        message: "",
      });
    }
    setSelectedProcess(null);
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    reset({
      label: "",
      message: "",
    });
    setSelectedProcess(null);
  }, [handleCloseNewServiceDialog, reset]);

  return (
    <Dialog
      open={openNewService}
      onClose={() => {
        handleCloseNewServiceDialog();
      }}
      fullWidth={true}
      maxWidth="xl"
      fullScreen={!!matches}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleCloseNewServiceDialog}
      >
        <MDBox display="flex" flexDirection="column" alignItems="center">
          <MDTypography
            sx={{ color: "#0B2F36", fontSize: matches ? "20px" : "24px" }}
          >
            Cadastrar atendimento do cliente
          </MDTypography>
          <MDTypography
            sx={{
              color: "#0B2F36",
              fontSize: matches ? "18px" : "22px",
              fontWeight: 600,
            }}
          >
            {userData?.fullname}
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>

      <DialogContent
        sx={{ padding: matches ? "30px 30px 0 30px" : "30px 50px 0 50px" }}
      >
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <MDTypography
            sx={{ fontWeight: 500, fontSize: "14px", marginBottom: "2rem" }}
          >
            O processo ainda não existe?&nbsp;&nbsp;{" "}
            <MDButton
              component={Link}
              to="/processos"
              variant="gradient"
              size="small"
              color="success"
              sx={{
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
                textTransform: "capitalize",
              }}
            >
              Cadastrar Novo Processo&nbsp;&nbsp;{" "}
              <Icon sx={{ color: "#DDCA92", fontSize: 40 }}>add</Icon>
            </MDButton>
          </MDTypography>
        </Grid>

        <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
          <MDBox
            sx={{
              height: "13px",
              width: "5px",
              backgroundColor: "#0B2F36",
              mr: 1,
            }}
          ></MDBox>
          <MDTypography
            variant="subtitle2"
            sx={{ color: "#0B2F36", fontWeight: 500 }}
          >
            Dados do atendimento
          </MDTypography>
        </DialogContentText>
        <MDBox
          noValidate
          component="form"
          my={2}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Código{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  disabled
                  value={code}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {/* {errors.code?.message} */}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                  Processo{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  ></MDTypography>
                </InputLabel>
                <Autocomplete
                  disablePortal
                  noOptionsText="Nenhum processo cadastrado"
                  size="small"
                  {...defaultProps}
                  value={selectedProcess}
                  onChange={(event: any, newValue: any) => {
                    // setClient(newValue);
                    setSelectedProcess(newValue);
                  }}
                  sx={{
                    "& input": { height: "27px" },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />
              </MDBox>
            </Grid>
            {/* </Grid> */}
            {/* <Grid container spacing={2}> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Título{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.label == null)}
                  {...register("label")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.label?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Descrição do serviço realizado{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  ></MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.message == null)}
                  {...register("message")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.message?.message}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <MDButton
          onClick={() => {
            setOpenUser(true);
            handleCloseNewServiceDialog();
          }}
          variant="outlined"
          sx={{
            width: "150px",

            padding: "10px",
            textTransform: "capitalize",
            border: "2px solid #DDCA92",
            borderRadius: "10px",
            color: "#0B2F36",

            "&:hover": {
              color: "#0B2F36",
              border: "2px solid #DDCA92",
            },
          }}
        >
          Voltar
        </MDButton>
        <MDButton
          disabled={disableButton}
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          color="success"
          size="medium"
          sx={{
            width: "150px",
            textTransform: "capitalize",
            color: "#DDCA92",
            background:
              "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

            border: "1px solid #DDCA92",
            borderRadius: "15px",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#0B2F36",
              boxShadow: "none",
            },
          }}
        >
          Cadastrar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateServiceDialog;
