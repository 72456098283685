import { ThemeProvider } from "@mui/material/styles";

// @mui material components
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

// Custom styles
import { styles } from "./styles/SignUpStepsStyle";
import { theme } from "./styles/CustomTheme";

interface Props {
  activeStep: number;
  steps: string[];
}

function SignUpSteps({ activeStep, steps }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={label} {...stepProps} sx={styles}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </ThemeProvider>
  );
}

export default SignUpSteps;
