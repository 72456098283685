/* eslint-disable no-useless-escape */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import useEffectOnce from "../../../hooks/useEffectOnce";
import { api } from "../../../services/api";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import { CircularProgress, Grid, InputLabel } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

// Signup components
import SignupConfirmDialog from "./components/ConfirmationDialog";
import CreditCard from "./components/CreditCard";
import SubscriptionPlans from "./components/SubscriptionPlans";
import UserDetails from "./components/UserDetails";

// Images
import SignUpSteps from "./components/SignUpSteps";

// Steps data
import toast from "react-hot-toast";
import BusinessDetails from "./components/BusinessDetails";
import { steps } from "./components/SignUpSteps/data/steps";
import dayjs from "dayjs";
import "./index.css"
import MDInput from "../../../components/MDInput";
interface IFormInput {
  name: string;
  surname: string;
  email: string;
  password: string;
  password_confirmation: string;
  cpf: string;
  birth_date: string;
  phone: string;
}

interface IData {
  name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  platform_code: string;
  plan_id: string;
  payday: number;
  cpf: string;
  birth_date: string;
  phone: string;
}

interface IDataApplication {
  cep: string;
  street: string;
  number: string | number;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  name_application: string;
  country: string;
}

interface IPlatform {
  _id: string;
  code: string;
}

interface IFormInputBusiness {
  cep: string;
  country: string;
  street: string;
  number: string | number;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  name_application: string;
}

const schema: yup.ObjectSchema<IFormInput> = yup.object({
  name: yup
    .string()
    .required("Informe o seu primeiro nome.")
    .min(3, "Seu nome deve ter no mínimo 3 caracteres"),
  surname: yup
    .string()
    .required("Informe o seu sobrenome.")
    .min(3, "Seu sobrenome deve ter no mínimo 3 caracteres"),
  cpf: yup.string().required("Informe o seu CPF."),
  birth_date: yup.string().required("Informe sua data de nascimento."),
  phone: yup.string().required("Informe o número do seu celular."),
  email: yup
    .string()
    .required("Informe seu e-mail")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Informe e-mail válido"),
  password: yup
    .string()
    .required("Informe a senha")
    .min(8, "Sua senha deve ter no mínimo 8 caracteres"),
  password_confirmation: yup
    .string()
    .required("Digite sua senha novamente")
    .min(8, "Sua senha deve ter no mínimo 8 caracteres")
    .oneOf([yup.ref("password")], "Senhas não são iguais"),
});

const schemaBusiness: yup.ObjectSchema<IFormInputBusiness> = yup.object({
  cep: yup
    .string()
    .required("Informe o CEP")
    .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP inválido"),
  street: yup.string().required("Informe a rua"),
  country: yup.string().required("Informe o seu país."),
  number: yup
    .string()
    .required("Informe o número da casa ou apt.")
    .min(1, "Número inválido"),
  neighborhood: yup.string().required("Informe o bairro"),
  city: yup.string().required("Informe a cidade"),
  state: yup.string().required("Informe o estado"),
  complement: yup.string().default(""),
  name_application: yup.string().required("Informe o nome da sua empresa"),
});

function Cover() {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [lockButton, setLockButton] = useState<boolean>(true);
  const [openLoading, setOpenLoading] = useState(false);
  const [currentSteps, setCurrentSteps] = useState<string[]>([
    "Informações pessoais",
    "Informações da empresa",
    "Selecionar plano",
    "Pagamento",
  ]);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      country: "Brasil"
    }
  });

  const {
    register: registerBusiness,
    handleSubmit: handleSubmitBusiness,
    setValue: setValueBusiness,

    watch: watchBusiness,
    formState: { errors: errorsBusiness },
  } = useForm<IFormInputBusiness>({
    resolver: yupResolver(schemaBusiness),
  });

  // First step fields - personal info
  const [data, setData] = useState<IData>({
    name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    platform_code: "",
    plan_id: "",
    payday: 0,
    cpf: "",
    birth_date: "",
    phone: "",
  });

  // Second step fields - application info
  const [dataApplication, setDataApplication] = useState<IDataApplication>({
    cep: "",
    street: "",
    number: "",
    neighborhood: "",
    city: "",
    state: "",
    complement: "",
    name_application: "",
    country: "",
  });

  // Second step - subscription plan
  const [amount, setAmount] = useState<number>(0);
  const [planID, setPlanID] = useState<string>("");
  const [lawPlans, setPlans] = useState<object[]>();
  const [subscription, setSubscription] = useState<number>(2);
  const [platforms, setPlatforms] = useState<IPlatform[]>([
    {
      _id: "",
      code: "",
    },
  ]);
  const [platformCode, setPlatformCode] = useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [isFree, setIsFree] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<string>("");
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [invalidCoupon, setInvalidCoupon] = useState<boolean>(false);
  const couponAccept = {
    "OAB10ILIMITADO": {
      type: "percent",
      value: 10
    }
  }
  const navigate = useNavigate();

  const handleNext = (): void => {
    if (activeStep === 2 && !planID) {
      toast.error("Selecione um plano para continuar", {
        duration: 5000,
      });
      return
    }
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleReset = (): void => {
    setActiveStep(0);
  };

  const handleConfirmNextStep = () => {
    handleNext();
    setOpenConfirmDialog(false);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  useEffect(() => {

    const queryString = window.location.search || window.location.href;
    const urlParams = new URLSearchParams(queryString);

    const urlCoupon = urlParams.get("cupom") || ""
    validCoupon(urlCoupon)
    setCoupon(urlCoupon);


  }, []);



  const validCoupon = (coupon: any) => {
    const value = couponAccept[coupon]
    console.log(value, coupon)
    if (value) {
      setCouponMessage(`${value.type === "percent" ? value.value + "%" : `R$${value.value},00`} em qualquer plano`)
      setInvalidCoupon(false)
      return true
    }
    setInvalidCoupon(true)
    return false

  }

  const onSubmit = async () => {
    setOpenLoading(true);
    const formData = {
      ...data,
      ...dataApplication,
      plan_id: planID,
      platform_code: platformCode,
    };
    if (!invalidCoupon) formData.coupon = coupon
    console.log(formData)
    try {
      const response = await api.post("/authentication/sign-up", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        navigate("/account-created");
      }
    } catch (err: any) {
      const code = err.response.data.statusCode;
      const message = err.response.data.message;
      if (code === 400) {
        toast.error(message, {
          duration: 5000,
        });
      } else if (code === 404) {
        toast.error("Ocorreu um erro. Tente novamente mais tarde!", {
          duration: 5000,
        });
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      } else {
        toast.error("Ocorreu um erro. Verifique as credenciais!", {
          duration: 5000,
        });
      }
    } finally {
      setOpenLoading(false);
    }
  };

  const checkUserDetails: SubmitHandler<IFormInput> = async (data) => {
    const isAfterThenToday = dayjs(data.birth_date).isAfter(dayjs());
    const isBeforeThen1900 = dayjs(dayjs(data.birth_date)).isBefore(
      "1900-01-01",
    );

    if (isAfterThenToday || isBeforeThen1900) {
      setError("birth_date", {
        message: "Data inválida",
      });
    } else {
      clearErrors("birth_date");

      const formData = {
        name: data.name,
        last_name: data.surname,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        platform_code: "",
        plan_id: "",
        payday: 10,
        cpf: data.cpf,
        birth_date: data.birth_date,
        phone: data.phone,
      };


      setData(formData);

      handleNext();
    }
  };

  const getPlatformCode = (platformID: string): void => {
    console.log(platformID)
    const currentPlatform = platforms.find(
      (p: IPlatform) => p._id === platformID,
    );

    if (currentPlatform) setPlatformCode(currentPlatform.code);
  };

  useEffectOnce(() => {
    async function fetchData(): Promise<void> {
      try {
        const responsePlans = await api.get("/plans");
        const data = responsePlans.data;
        console.log(responsePlans)
        setPlans(data);

        const responsePlatforms = await api.get(`/platforms/`);
        setPlatforms(responsePlatforms.data);

        const _special = data.find((p: any) => p.name === "Special");
        if (_special) {
          setPlanID(_special._id);
          const currentPlatform = responsePlatforms.data.find(
            (p: IPlatform) => p._id === _special.platform_id,
          );
          if (currentPlatform) setPlatformCode(currentPlatform.code);
        }


      } catch (err: any) {
        console.log(err);
      }
    }
    void fetchData();
  }, []);

  useEffect(() => {
    const currentPlan: any = lawPlans?.find(
      (plans: any) => plans._id === planID,
    );

    if (currentPlan) {
      currentPlan.name === "Gratuito" ? setIsFree(true) : setIsFree(false);
      if (currentPlan.name === "Gratuito") {
        setCurrentSteps([
          "Informações pessoais",
          "Informações da empresa",
          "Selecionar plano",
        ]);
      } else {
        setCurrentSteps([
          "Informações pessoais",
          "Informações da empresa",
          "Selecionar plano",
          "Pagamento",
        ]);
      }
    }

    if (activeStep === steps.length - 1) {
      const formData = {
        ...data,
        platform_code: platformCode,
        plan_id: planID,
      };

      setData(formData);
    }

  }, [activeStep, planID, platformCode]);

  return (
    <BasicLayout>
      <MDBox
        maxWidth="1280px"
        minWidth="80vw"
        height="auto"
        pt={4}
        pb={3}
        my={8}
        px={4}
        sx={{
          borderRadius: "11px",
          boxShadow: 2,
        }}
      >
        <MDBox display="flex" justifyContent="center" mb={4} mt={-1}>
          <SignUpSteps activeStep={activeStep} steps={currentSteps} />

        </MDBox>

        {activeStep === steps.length ? (
          <MDBox>
            <MDTypography sx={{ mt: 2, mb: 1 }}>
              Todos os passos completos - tudo pronto!
            </MDTypography>
            <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <MDBox sx={{ flex: "1 1 auto" }} />
              <MDButton
                onClick={handleReset}
                sx={{
                  backgroundColor: "#0B2F36",
                  color: "#DDCA92",
                  border: "1px solid #DDCA92",
                }}
              >
                Resetar
              </MDButton>
            </MDBox>
          </MDBox>
        ) : (
          <MDBox>
            <MDBox>
              {activeStep === 0 && (
                <UserDetails
                  lockButton={lockButton}
                  setLockButton={setLockButton}
                  errors={errors}
                  register={register}
                />
              )}
              {activeStep === 1 && (
                <BusinessDetails
                  lockButton={lockButton}
                  setLockButton={setLockButton}
                  setData={setDataApplication}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  register={registerBusiness}
                  handleSubmit={handleSubmitBusiness}
                  setValue={setValueBusiness}
                  errors={errorsBusiness}
                  watch={watchBusiness}
                />
              )}
              {activeStep === 2 && (
                <>
                  <Grid
                    container
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexWrap={"wrap"}
                    gap={2}
                  >
                    {lawPlans?.map((item, index) => (
                      <SubscriptionPlans
                        key={index}
                        // @ts-expect-error
                        plan={item}
                        index={index}
                        setSubscription={setSubscription}
                        subscription={subscription}
                        setPlanID={setPlanID}
                        getPlatformCode={getPlatformCode}
                        setAmount={setAmount}
                        setLockButton={setLockButton}
                        coupon={!invalidCoupon ? couponAccept[coupon] : false}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} xl={4} md={6}>

                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>Cupom de desconto</InputLabel>
                      <MDInput
                        type="text"
                        // error={!(errors.coupon == null)}
                        value={coupon}
                        onChange={e => {
                          const val = e.target.value.toUpperCase()

                          validCoupon(val)

                          setCoupon(val)
                        }
                        }
                        sx={{
                          boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                          borderRadius: "8px",
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                        fullWidth
                      />
                      {coupon ? invalidCoupon ?
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          <span className="coupon-text invalid">
                            Seu cupom <b>{coupon}</b> é inválido
                          </span>
                        </MDTypography> :
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          <span className="coupon-text">
                            Aplicando cupom <b>{coupon}</b> de <b>{couponMessage}</b>
                          </span>
                        </MDTypography> : null
                      }
                    </MDBox>
                  </Grid>
                </>
              )}
              {activeStep === 3 && (
                <CreditCard
                  email={data?.email}
                  amount={amount}
                  lockButton={lockButton}
                  dataPessoal={data}
                  dataApplication={dataApplication}
                  handleBack={handleBack}
                />
              )}
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "0 10px",
                pt: 2,
                mb: 2,
              }}
            >
              {activeStep === 0 && (
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mt={4}
                  mb={1}
                >
                  <MDButton
                    component={Link}
                    to="/login"
                    variant="outlined"
                    sx={{
                      width: "130px",

                      padding: "10px",
                      textTransform: "capitalize",
                      color: "#0B2F36",
                      border: "2px solid #DDCA92",
                      borderRadius: "10px",

                      "&:hover": {
                        color: "#0B2F36",
                        border: "2px solid #DDCA92",
                      },
                    }}
                  >
                    Voltar ao login
                  </MDButton>
                </MDBox>
              )}

              {activeStep === 2 && (
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "0 10px",
                    pt: 2,
                    mb: 2,
                  }}
                >
                  {" "}
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mt={4}
                    mb={1}
                  >
                    <MDButton
                      onClick={handleBack}
                      variant="outlined"
                      sx={{
                        width: "130px",

                        padding: "10px",
                        textTransform: "capitalize",
                        color: "#0B2F36",
                        border: "2px solid #DDCA92",
                        borderRadius: "10px",

                        "&:hover": {
                          color: "#0B2F36",
                          border: "2px solid #DDCA92",
                        },
                      }}
                    >
                      Voltar
                    </MDButton>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mt={4}
                    mb={1}
                  >
                    <MDButton
                      variant="None"
                      onClick={() => {
                        if (isFree) {
                          void onSubmit();
                        } else {
                          handleNext();
                        }
                      }}
                      disabled={lockButton}
                      sx={{
                        width: "130px",

                        padding: "10px",
                        textTransform: "capitalize",
                        color: "#DDCA92",
                        background:
                          "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                        border: "1px solid #DDCA92",
                        borderRadius: "10px",
                      }}
                    >
                      {openLoading ? (
                        <CircularProgress color="primary" />
                      ) : isFree ? (
                        "Finalizar"
                      ) : (
                        "Avançar"
                      )}
                    </MDButton>
                  </MDBox>
                </MDBox>
              )}
              {activeStep === 0 ? (
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mt={4}
                  mb={1}
                >
                  <MDButton
                    variant="contained"
                    onClick={handleSubmit(checkUserDetails)}
                    disabled={lockButton}
                    sx={{
                      width: "130px",

                      padding: "10px",
                      textTransform: "capitalize",
                      color: "#DDCA92",
                      background:
                        "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                      border: "1px solid #DDCA92",
                      borderRadius: "10px",

                      "&:hover": {
                        background:
                          "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                        color: "#DDCA92",
                        border: "1px solid #DDCA92",
                      },
                    }}
                  >
                    Avançar
                  </MDButton>
                </MDBox>
              ) : (
                <></>
              )}
            </MDBox>
          </MDBox>
        )}
        <SignupConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleChangeFormStep={handleConfirmNextStep}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
        />
      </MDBox>
    </BasicLayout>
  );
}

export default Cover;
