import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/useAuth";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptBRLocale from "@fullcalendar/core/locales/pt-br";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import { type SelectChangeEvent } from "@mui/material/Select";

import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";

import SchedulingLink from "./components/dialogs/SchedullingLink";

import ScheduleForm from "./components/dialogs/ScheduleForm";
import { lawApi } from "../../services/api";
import { toast } from "react-hot-toast";
import ShowScheduleForm from "./components/dialogs/ShowScheduleForm";
import DeleteScheduleDialog from "./components/dialogs/DeleteSchedule";
import MDTypography from "../../components/MDTypography";

interface LawerOptionType {
  label: string;
  id: number;
  address_id: number;
}

const Scheduling = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [openScheduleForm, setOpenScheduleForm] = useState<boolean>(false);
  const [value, setValue] = useState<LawerOptionType | null>(null);
  const [openEvent, setOpenEvent] = useState<boolean>(false);
  const [lawers, setLawers] = useState<any[]>([]);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [scheduleSelected, setScheduleSelected] = useState("");
  const [scheduleToDelete, setScheduleToDelete] = useState<any>();
  const [openDeleteSchedule, setOpenDeleteSchedule] = useState<boolean>(false);
  const [hasUpdated, setHasUpdated] = useState<boolean>(true);

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const defaultProps = {
    options: lawers,
    getOptionLabel: (option: LawerOptionType) => option.label,
  };

  const handleClickOpenScheduleForm = () => {
    setOpenScheduleForm(true);
  };

  const handleCloseScheduleForm = () => {
    setOpenScheduleForm(false);
  };

  const handleCloseEventForm = () => {
    setOpenEvent(false);
  };

  const handleEventClick = (event: any) => {
    setScheduleSelected(event.event._def.extendedProps.publicId);
    setOpenEvent(true);
  };

  const toastFeedbackMessage = (type: string, message: string): any => {
    if (type === "error") {
      return toast.error(`${message}`, {
        duration: 6000,
      });
    }

    return toast.success(`${message}`, {
      duration: 5000,
    });
  };

  const isInformationCompleted = (subuser: any) => {
    if (subuser.active_schedule) {
      return true;
    }

    toastFeedbackMessage("error", "Ative o agendamento para agendar horários.");

    // return true;
    return false;
  };

  const fetchData = async () => {
    const response = await lawApi.get("/users");

    const arrLawers: any[] = [];

    response.data.data.users.forEach((subuser: any) => {
      if (subuser.active_schedule) {
        const data = {
          label: `${subuser.name} ${subuser.last_name}`,
          fullname: `${subuser.name} ${subuser.last_name}`,
          id: subuser._id,
          address_id: subuser.application.address_id,
        };

        arrLawers.push(data);
      }
    });

    setLawers(arrLawers);
  };

  const handleScheduleToDelete = (schedule: any) => {
    setScheduleToDelete(schedule);

    handleOpenDeleteScheduleDialog();
    handleCloseEventForm();
  };

  const handleOpenDeleteScheduleDialog = () => {
    setOpenDeleteSchedule(true);
  };

  const handleCloseDeleteScheduleDialog = () => {
    setOpenDeleteSchedule(false);
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const fetchDataSchedules = async () => {
    if (value != null) {
      try {
        const response = await lawApi.get(`/schedules/user/${value.id}`);

        const arrSchedule: any[] = [];

        response.data.data.schedules.forEach((schedule: any) => {
          const [hourStart, minuteStart] = schedule.start_time.split(":");
          const [hourEnd, minuteEnd] = schedule.end_time.split(":");
          const data = {
            title: schedule.title,
            date: schedule.date,
            publicId: schedule._id,
            start: new Date(schedule.date),
            end: new Date(schedule.date),
          };

          data.start.setHours(hourStart, minuteStart);
          data.end.setHours(hourEnd, minuteEnd);

          arrSchedule.push(data);
        });

        setSchedules(arrSchedule);
      } catch (error) {}
    }
  };

  useEffect(() => {
    // if (hasUpdated) {
    void fetchDataSchedules();
    setHasUpdated(false);
    // }
  }, [value, hasUpdated, openDeleteSchedule]);

  return (
    <DashboardLayout>
      <DashboardNavbar title="Agendamento" />
      <MDBox display="flex" alignItems="stretch" mb={4} ml={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} mr={3}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>Profissional</InputLabel>
              <Autocomplete
                {...defaultProps}
                value={value}
                onChange={(event: any, newValue: LawerOptionType | null) => {
                  setValue(newValue);
                }}
                noOptionsText="Não há profissionais"
                size="small"
                disablePortal
                renderInput={(params) => <TextField {...params} />}
              />
            </MDBox>
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={3}>
            <MDButton
              onClick={handleClickOpenScheduleForm}
              disabled={value == null}
              variant="gradient"
              size="small"
              color="success"
              sx={{
                backgroundColor: "#0B2F36",
                boxShadow: "none",
                color: "#f1f1f1",
                "&:hover": {
                  backgroundColor: "#0B1F36",
                  boxShadow: "none",
                },
                height: "35px",
                mt: 2.4,
                textTransform: "capitalize",
              }}
            >
              Novo agendamento&nbsp;&nbsp;
              <Icon sx={{ color: "#DDCA92", fontSize: "40px" }}>add</Icon>
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        {value !== null ? (
          <MDBox
            sx={{
              // maxWidth: "1080px",
              display: "flex",
              flexDirection: "column",
              alignCenter: "center",
            }}
          >
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              locale={ptBRLocale}
              eventClick={handleEventClick}
              eventInteractive={true}
              eventColor="#0B2F36"
              titleFormat={{ day: "numeric", year: "numeric", month: "short" }}
              events={schedules}
              headerToolbar={{
                start: "dayGridMonth,timeGridWeek,timeGridDay",
                center: "title",
                end: "prev,next",
              }}
            />
          </MDBox>
        ) : (
          <MDBox
            sx={{
              // maxWidth: "1080px",
              display: "flex",
              flexDirection: "column",
              alignCenter: "center",
              justifyContent: "center",
              marginTop: "10rem",
            }}
            // mt="4"
          >
            <MDTypography
              sx={{
                fontSize: "2rem",
                display: "inline",
                color: "#5b6c79",
                textAlign: "center",
              }}
            >
              Agende reuniões
            </MDTypography>
            <MDTypography
              sx={{
                fontSize: "1rem",
                display: "inline",
                color: "#5b6c79",
                textAlign: "center",
              }}
            >
              Gerencie suas reuniões de maneira fácil, rápida e em um só lugar.
            </MDTypography>
          </MDBox>
        )}
      </MDBox>

      <ScheduleForm
        open={openScheduleForm}
        handleClose={handleCloseScheduleForm}
        user={value}
        edit={false}
        matches={matches}
        toastFeedbackMessage={toastFeedbackMessage}
        setUpdated={setHasUpdated}
      />
      <ShowScheduleForm
        open={openEvent}
        handleClose={handleCloseEventForm}
        scheduleSelected={scheduleSelected}
        toastFeedbackMessage={toastFeedbackMessage}
        edit={true}
        user={value}
        matches={matches}
        handleScheduleToDelete={handleScheduleToDelete}
        setUpdated={setHasUpdated}
      />
      <DeleteScheduleDialog
        openDeleteSchedule={openDeleteSchedule}
        handleCloseDeleteScheduleDialog={handleCloseDeleteScheduleDialog}
        schedule={scheduleToDelete}
        toastFeedbackMessage={toastFeedbackMessage}
      />
    </DashboardLayout>
  );
};

export default Scheduling;
