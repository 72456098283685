/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../components/BasicLayout";
import { api } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { CircularProgress, Icon } from "@mui/material";

const BORDER_GRADIENT =
  "linear-gradient(to top, #DDCA92, rgba(0, 0, 0, 0)) 1 100%";

interface IFormInput {
  password: string;
  password_confirmation: string;
}

function NewPassword(): JSX.Element {
  const navigate = useNavigate();
  const [seePassword, setSeePassword] = useState(false);
  const [seePasswordConfirmation, setSeePasswordConfirmation] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleOpenLoading = () => {
    setOpenLoading(true);
  };

  const handleEmailConfirmation = async (): Promise<void> => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");

    const data = {
      token,
    };

    await api.post("/authentication/password/remember", data);
  };

  useEffect(() => {
    void handleEmailConfirmation();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = useCallback(
    async (data) => {
      handleOpenLoading();
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get("token");

        const response = await api.put("/authentication/password/reset", {
          ...data,
          token,
        });

        navigate("/login");
      } catch (err) {
        console.log(err);
        // setOpen(true);
      } finally {
        handleCloseLoading();
      }
    },
    [navigate],
  );
  return (
    <BasicLayout background="#0B2F36">
      <MDBox
        minWidth="100%"
        height="100vh"
        pt={4}
        pb={3}
        px={4}
        sx={{
          border: "3px solid",
          borderRadius: "11px",
          borderImage: BORDER_GRADIENT,
        }}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          mb={6}
        >
          <MDTypography variant="h3" color="white" sx={{ textAlign: "center" }}>
            Nova senha
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mb={10}>
              <MDTypography
                variant="body2"
                color="white"
                sx={{ textAlign: "center", mt: 10, mb: 4 }}
              >
                Digite a sua nova senha
              </MDTypography>
              <MDBox mb={4} sx={{ position: "relative" }}>
                <MDInput
                  type={seePassword ? "text" : "password"}
                  label="Senha"
                  {...register("password", {
                    required: true,
                  })}
                  fullWidth
                  sx={{
                    background: "#ffffff",
                    borderRadius: "8px",
                    boxShadow: " inset 3px 4px 11px rgba(0, 0, 0, 0.25)",
                    border: "none",
                    outline: "none",
                  }}
                  // @ts-expect-error
                  InputLabelProps={{
                    sx: {
                      color: "gray",
                      "&.Mui-focused": {
                        color: "#7b809a", // nenhuma cor fica boa
                        top: "-1rem",
                      },
                    },
                  }}
                />
                <MDButton
                  variant="text"
                  color="primary"
                  sx={{
                    position: "absolute",
                    top: "0.2rem",
                    right: 0,
                    fontSize: 30,
                  }}
                  onClick={() => {
                    setSeePassword(!seePassword);
                    // setTypeInputPassword(seePassword ? "text" : "password");
                  }}
                >
                  <Icon fontSize="large" sx={{ fontSize: 30 }}>
                    {seePassword ? "visibility_off" : "visibility"}
                  </Icon>
                </MDButton>
              </MDBox>
              <MDBox mb={2} sx={{ position: "relative" }}>
                <MDInput
                  type={seePasswordConfirmation ? "text" : "password"}
                  label="Confirmar senha"
                  {...register("password_confirmation", {
                    required: true,
                  })}
                  fullWidth
                  sx={{
                    background: "#ffffff",
                    borderRadius: "8px",
                    boxShadow: " inset 3px 4px 11px rgba(0, 0, 0, 0.25)",
                    border: "none",
                    outline: "none",
                  }}
                  // @ts-expect-error
                  InputLabelProps={{
                    sx: {
                      color: "gray",
                      "&.Mui-focused": {
                        color: "#7b809a", // nenhuma cor fica boa
                        top: "-1rem",
                      },
                    },
                  }}
                />
                <MDButton
                  variant="text"
                  color="primary"
                  sx={{
                    position: "absolute",
                    top: "0.2rem",
                    right: 0,
                    fontSize: 30,
                  }}
                  onClick={() => {
                    setSeePasswordConfirmation(!seePasswordConfirmation);
                    // setTypeInputPassword(seePassword ? "text" : "password");
                  }}
                >
                  <Icon fontSize="large" sx={{ fontSize: 30 }}>
                    {seePasswordConfirmation ? "visibility_off" : "visibility"}
                  </Icon>
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt={4}
              mb={1}
            >
              <MDButton
                variant="none"
                color="light"
                onClick={handleSubmit(onSubmit)}
                sx={{
                  width: "20em",
                  maxWidth: "100%",
                  padding: 1,
                  mb: 2,
                  textTransform: "capitalize",
                  color: "#DDCA92",
                  background:
                    "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                  border: "1px solid #DDCA92",
                  borderRadius: "10px",
                  boxShadow: "rgba(255, 255, 255, 0.25) -8px 11px 101px",
                }}
              >
                {openLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  "confirmar"
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default NewPassword;
