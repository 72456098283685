/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useNavigate } from "react-router-dom";

import { api } from "../../../../services/api";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";

// Authentication layout components
import BasicLayout from "../../components/BasicLayout";
import { CircularProgress } from "@mui/material";
import { useState } from "react";

const BORDER_GRADIENT =
  "linear-gradient(to top, #DDCA92, rgba(0, 0, 0, 0)) 1 100%";

function EmailConfirmation(): JSX.Element {
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleOpenLoading = () => {
    setOpenLoading(true);
  };

  const handleEmailConfirmation = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    handleOpenLoading();

    const data = {
      token,
    };

    api
      .post("/authentication/confirm-register", data)
      .then((response) => {
        navigate("/email-confirmed");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  return (
    <BasicLayout background="#0B2F36">
      <MDBox
        minWidth="100%"
        height="100vh"
        pt={4}
        pb={3}
        px={4}
        sx={{
          border: "3px solid",
          borderRadius: "11px",
          borderImage: BORDER_GRADIENT,
        }}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          mb={6}
        >
          <MDTypography
            variant="h3"
            color="white"
            sx={{ textAlign: "center", mb: 10 }}
          >
            Estamos quase lá
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mb={10}>
              <MDTypography
                variant="body2"
                color="white"
                sx={{ textAlign: "center" }}
              >
                Clique no botão abaixo para confirmar seu e-mail.
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt={4}
              mb={1}
            >
              <MDButton
                onClick={handleEmailConfirmation}
                sx={{
                  width: "20em",
                  maxWidth: "100%",
                  padding: 1,
                  mb: 2,
                  textTransform: "capitalize",
                  color: "#DDCA92",
                  background:
                    "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                  border: "1px solid #DDCA92",
                  borderRadius: "10px",
                  boxShadow: "rgba(255, 255, 255, 0.25) -8px 11px 101px",
                }}
              >
                {openLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  "  Confirmar"
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default EmailConfirmation;
