import axios from "axios";
import secureLocalStorage from "react-secure-storage";

axios.defaults.headers.post["Content-Type"] = "application/json";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_JUPITER_API_URL}`,
});

const lawApi = axios.create({
  baseURL: `${process.env.REACT_APP_LAW_API_URL}`,
});

lawApi.interceptors.request.use(
  async (config) => {
    // const userToken = JSON.stringify(secureLocalStorage.getItem("token"));

    // if (userToken) {
    //   await api.get("/authentication/check-login/", {
    //     params: {
    //       token: userToken.replaceAll('"', ""),
    //     },
    //   });
    // }

    return config;
  },
  async function (error) {
    if (error.response && error.response.data && error.response.data.message === "Token Inválido") {
      secureLocalStorage.removeItem("token");
      secureLocalStorage.removeItem("user");
    }

    return await Promise.reject(error);
  },
);

lawApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      secureLocalStorage.removeItem("token");
      secureLocalStorage.removeItem("user");
    }
    return Promise.reject(error.message);
  }
);

export { api, lawApi };
