/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from "react";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import { InputLabel } from "@mui/material";

function UserDetails({ lockButton, setLockButton, register, errors }: any) {
  const [checked, setChecked] = useState<boolean>(false);

  const handleTermsAndConditions = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (lockButton === true) {
      localStorage.setItem("@JupiterLaw:termsAndConditions", "true");
      setChecked(true);
      setLockButton(false);
    } else {
      localStorage.setItem("@JupiterLaw:termsAndConditions", "false");
      setChecked(false);
      setLockButton(true);
    }
  };

  useEffect(() => {
    const termsAndConditions = localStorage.getItem(
      "@JupiterLaw:termsAndConditions",
    );

    if (termsAndConditions === "true") {
      setChecked(true);
      setLockButton(false);
    }
  }, [setLockButton]);

  return (
    <MDBox component="form" role="form">
      <MDBox sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>Nome</InputLabel>
              <MDInput
                type="text"
                error={!(errors.name == null)}
                {...register("name")}
                sx={{
                  boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                  borderRadius: "8px",
                  outline: "none",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.name?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>Sobrenome</InputLabel>
              <MDInput
                type="text"
                error={!(errors.surname == null)}
                {...register("surname")}
                sx={{
                  boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.surname?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>E-mail</InputLabel>
              <MDInput
                type="text"
                error={!(errors.email == null)}
                {...register("email")}
                sx={{
                  boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.email?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>Data de nascimento</InputLabel>
              <MDInput
                type="date"
                // mask={"99/99/9999"}
                error={!(errors.birth_date == null)}
                {...register("birth_date")}
                sx={{
                  boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.birth_date?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>CPF</InputLabel>
              <MDInput
                type="text"
                mask={"999.999.999-99"}
                error={!(errors.cpf == null)}
                {...register("cpf")}
                sx={{
                  boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.cpf?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>Celular</InputLabel>
              <MDInput
                type="text"
                mask={"(99) 99999-9999"}
                error={!(errors.phone == null)}
                {...register("phone")}
                sx={{
                  boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.phone?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>Senha</InputLabel>
              <MDInput
                type="password"
                error={!(errors.password == null)}
                {...register("password")}
                sx={{
                  boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.password?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>Confirmação de senha</InputLabel>
              <MDInput
                type="password"
                error={!(errors.password_confirmation == null)}
                {...register("password_confirmation")}
                sx={{
                  boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.password_confirmation?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  handleTermsAndConditions(e);
                }}
                sx={{
                  "&.Mui-checked": {
                    color: "#DDCA92",
                  },
                }}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: 0.5 }}
              >
                &nbsp;&nbsp;Eu concordo com os&nbsp;{" "}
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  Termos e Condições
                </MDTypography>
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default UserDetails;
