/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, type SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import dataUF from "../../../../../brazilian-states.json";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDInput from "../../../../../components/MDInput";
import MDTypography from "../../../../../components/MDTypography";

import secureLocalStorage from "react-secure-storage";
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";
import { lawApi } from "../../../../../services/api";
import FileInput from "../../FileInput";

interface ICreateUserDialog {
  matches: boolean;
  openNewProcessDialog: boolean;
  fetchData: () => Promise<void>;
  customers: readonly ICustomer[];
  handleCloseNewProcessDialog: () => void;
  toastFeedbackMessage: (type: string, message: string) => void;
}

interface IInputs {
  code: string;
  description: string | null;
  status: string;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  code: yup.string().default(""),
  description: yup.string().default(""),
  status: yup.string().default(""),
});

interface ICustomer {
  [x: string]: any;
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
}

const processStatus = [
  { value: "Iniciado", text: "Iniciado", color: "#19953C" },
  { value: "Andamento", text: "Em andamento", color: "#DDCA92" },
  { value: "Finalizado", text: "Finalizado", color: "#6B1111" },
];

function CreateProcessDialog({
  openNewProcessDialog,
  handleCloseNewProcessDialog,
  customers,
  fetchData,
  matches,
  toastFeedbackMessage,
}: ICreateUserDialog) {
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });

  const defaultProps = {
    options: customers,
    getOptionLabel: (option: ICustomer) => `${option.fullname}${option.cpf ? ` - ${option.cpf}` : ""}`,
  };

  const [UF, setUF] = useState<{ nome: string; sigla: string } | null>();

  const defaultPropsUF = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.nome,
  };

  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [idClient, setIdClient] = useState(
    (secureLocalStorage.getItem("client_id_selected") as string) ?? "",
  );
  const [files, setFiles] = useState<any>([]);
  const [client, setClient] = useState<any>({});
  const [errorClient, setErrorClient] = useState<string>("");
  const [errorState, setErrorState] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [errorCNJ, setErrorCNJ] = useState<string>("");
  const [inputFields, setInputFields] = useState<any>([]);

  const [clientWasChose, setClientWasChose] = useState<boolean>(false);
  const [isAllFilesSet, setIsAllFilesSet] = useState<boolean>(false);

  // Pega o cliente que foi escolhido no Dashboard para criar o novo processo
  const getClient = useCallback(() => {
    if (idClient && customers[0].created_at) {
      const _customer = customers.find((c) => c._id === idClient);

      if (_customer !== undefined) {
        return _customer;
      }

      return null;
    }
  }, [customers, idClient]);

  useEffect(() => {
    const _currentClient = getClient();

    setClient(_currentClient);
  }, [getClient]);

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    setDisableButton(true);
    let isError = false;

    if (!client) {
      setErrorClient("Selecione um cliente.");
      isError = true;
    }

    if (!UF) {
      isError = true;
      setErrorState("Selecione um estado.");
    }

    if (!data.status) {
      isError = true;
      setErrorStatus("Selecione o status do processo.");
    }

    if (!data.code) {
      isError = true;
      setErrorCNJ("Deve ter no mínimo 8 caracteres");
    }

    if (isError) return;

    const formData = new FormData();
    const { description, code, status } = data;

    const dataSend = {
      client_id: client._id,
      application_id: client.application_id,
      description,
      code,
      notes: null,
      status,
      state: UF ? UF.nome : null,
    };

    files.map((file: any) => {
      formData.append(`arquivo`, file);
    });

    formData.append("data", JSON.stringify(dataSend));

    try {
      const response = await lawApi.post("/process", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        toastFeedbackMessage("success", "Processo cadastrado com sucesso.");
      }

      void fetchData();
      handleResetInputAndFunction();
    } catch (err: any) {
      // console.log(err);
      const code = err.response.data.statusCode;
      const message = err.response.data.message;

      if (code === 400) {
        toastFeedbackMessage("error", message);
      } else if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do processo. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do processo. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do processo. Verifique os dados informados!",
        );
      }
    } finally {
      setDisableButton(false);
    }
  };

  const handleResetInputAndFunction = (): void => {
    setInputFields([]);
    setFiles([]);
    setUF(null);
    setClient(null);
    setErrorCNJ("");
    setErrorClient("");
    setErrorState("");
    setErrorStatus("");
    setClientWasChose(false);
    setIsAllFilesSet(false);
    reset();
    secureLocalStorage.removeItem("client_id_selected");
    handleCloseNewProcessDialog();
  };

  const handleAddInput = (): void => {
    setInputFields([
      ...inputFields,
      { file: { title: "", description: "", version: "", path: "" } },
    ]);
  };

  const handleRemoveField = (index: number): void => {
    // eslint-disable-next-line prefer-const
    let data = [...inputFields];
    const dataFiles = [...files];

    data.splice(index, 1);
    dataFiles.splice(index, 1);

    setFiles(dataFiles);
    setInputFields(data);
  };

  const handleFormChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    // eslint-disable-next-line prefer-const
    let data = [...inputFields];
    const dataFile = [...files];

    if (event.target.files != null) {
      data[index][event.target.name].title = event.target.files[0].name;
      data[index][event.target.name].path = event.target.files[0].name;
      dataFile[index] = event.target.files[0];
    }
    setFiles(dataFile);
    setInputFields(data);
  };

  useEffect(() => {
    if (client?._id != null) {
      setClientWasChose(true);
    } else {
      setClientWasChose(false);
    }
  }, [client]);

  useEffect(() => {
    if (inputFields.length > 0) {
      const totalFiles = inputFields
        .map((field: any) => {
          if (field.file.title !== "") {
            return field;
          }
        })
        .filter((elem: any) => elem !== undefined);

      if (totalFiles.length === inputFields.length) {
        setIsAllFilesSet(true);
      } else {
        setIsAllFilesSet(false);
      }
    } else {
      setIsAllFilesSet(false);
    }
  }, [inputFields]);

  useEffect(() => {
    if (clientWasChose && isAllFilesSet) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [clientWasChose, isAllFilesSet]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        code: "",
        description: "",

        status: "",
      });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Dialog
      open={openNewProcessDialog}
      onClose={() => {
        handleResetInputAndFunction();
      }}
      fullWidth={true}
      maxWidth="xl"
      fullScreen={!!matches}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          handleResetInputAndFunction();
        }}
      >
        <MDBox display="flex" flexDirection="column" alignItems="center">
          <MDTypography
            sx={{ color: "#0B2F36", fontSize: matches ? "20px" : "24px" }}
          >
            Cadastrar processo
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>
      <DialogContent
        sx={{ padding: matches ? "30px 1rem 0 1rem" : "0 50px 0 50px" }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <MDTypography sx={{ fontWeight: 500, fontSize: "14px" }}>
              Cliente ainda não existe?&nbsp;&nbsp;{" "}
              <MDButton
                component={Link}
                to="/clientes"
                variant="gradient"
                size="small"
                color="success"
                sx={{
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                  textTransform: "capitalize",
                }}
              >
                Cadastrar&nbsp;&nbsp;{" "}
                <Icon sx={{ color: "#DDCA92", fontSize: 40 }}>add</Icon>
              </MDButton>
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <MDBox display="flex" flexDirection="column">
              <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
                <MDBox
                  sx={{
                    height: "13px",
                    width: "5px",
                    backgroundColor: "#DDCA92",
                    mr: 1,
                  }}
                ></MDBox>
                <MDTypography
                  variant="subtitle2"
                  sx={{ color: "#0B2F36", fontWeight: 500 }}
                >
                  Cliente
                </MDTypography>
              </DialogContentText>
              <MDBox
                noValidate
                component="form"
                my={2}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={8}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                        Nome{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <Autocomplete
                        disablePortal
                        noOptionsText="Nenhum cliente cadastrado"
                        size="small"
                        {...defaultProps}
                        value={client ?? null}
                        // defaultValue={client}
                        onChange={(event: any, newValue: any) => {
                          setClient(newValue);
                          // setErrorClient("");
                        }}
                        sx={{
                          "& input": { height: "27px" },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        fullWidth
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errorClient}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
                <MDBox
                  sx={{
                    height: "13px",
                    width: "5px",
                    backgroundColor: "#DDCA92",
                    mr: 1,
                  }}
                ></MDBox>
                <MDTypography
                  variant="subtitle2"
                  sx={{ color: "#0B2F36", fontWeight: 500 }}
                >
                  Dados do processo
                </MDTypography>
              </DialogContentText>
              <MDBox
                noValidate
                component="form"
                my={2}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                        CNJ{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <MDInput
                        error={!(errors.code == null)}
                        {...register("code")}
                        type="text"
                        variant="outlined"
                        mask={"9999999-99.9999.9.99.9999"}
                        // eslint-disable-next-line no-void
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                        fullWidth
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errorCNJ || errors.code?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                        Estado realizado o processo{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <Autocomplete
                        disablePortal
                        noOptionsText="Nenhuma UF cadastrada"
                        size="small"
                        {...defaultPropsUF}
                        value={UF ?? null}
                        // defaultValue={client}
                        onChange={(event: any, newValue: any) => {
                          setUF(newValue);
                          // setErrorState("");
                        }}
                        sx={{
                          "& input": { height: "27px" },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        fullWidth
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errorState}
                      </MDTypography>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                        Status{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <Controller
                        name="status"
                        control={control}
                        defaultValue="iniciado"
                        render={({ field }) => (
                          <Select
                            {...field}
                            onChangeCapture={() => {
                              // setErrorStatus("");
                            }}
                            sx={{
                              height: "45px",
                              "&.MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#DDCA92",
                                },
                              },
                              ".css-1kvvgjv-MuiSvgIcon-root-MuiSelect-icon, .css-ixc2ow-MuiSvgIcon-root-MuiSelect-icon":
                              {
                                display: "initial",
                              },
                            }}
                          >
                            {processStatus.map((status, index) => (
                              <MenuItem
                                key={index}
                                sx={{
                                  color: status.color,
                                }}
                                value={status.value}
                              >
                                {status.text}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errorStatus}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                        Descrição
                      </InputLabel>
                      <MDInput
                        error={!(errors.description == null)}
                        {...register("description")}
                        type="text"
                        variant="outlined"
                        multiline
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },

                          "&": {
                            height: "200px",
                          },
                        }}
                        rows={5}
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.description?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <MDBox display="flex" flexDirection="column">
              <DialogContentText sx={{ marginBottom: "15px" }}>
                <MDBox display="flex" justifyContent="space-between">
                  <MDTypography
                    sx={{
                      color: "#2C2C2C",
                      fontSize: matches ? "18px" : "20px",
                      textTransform: "capitalize",
                    }}
                  >
                    Anexar documentos
                  </MDTypography>
                  <MDButton
                    onClick={handleAddInput}
                    variant="gradient"
                    size="small"
                    color="success"
                    sx={{
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    Anexar&nbsp;{" "}
                    <Icon sx={{ color: "#DDCA92", fontSize: "40px" }}>add</Icon>
                  </MDButton>
                </MDBox>
              </DialogContentText>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDBox display="flex" flexDirection="column" gap="10px 0">
                    {inputFields.map((input: any, index: number) => {
                      return (
                        <FileInput
                          key={index}
                          index={index}
                          handleRemoveField={handleRemoveField}
                          handleFormChange={handleFormChange}
                        />
                      );
                    })}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDButton
          onClick={() => {
            handleCloseNewProcessDialog();
            setClientWasChose(false);
            setIsAllFilesSet(false);
            reset();
            secureLocalStorage.removeItem("client_id_selected");
          }}
          sx={{
            width: "130px",

            padding: "10px",
            textTransform: "capitalize",
            color: "#0B2F36",
            border: "2px solid #DDCA92",
            borderRadius: "10px",
          }}
        >
          Voltar
        </MDButton>
        <MDButton
          onClick={handleSubmit(onSubmit)}
          sx={{
            width: "130px",

            padding: "10px",
            textTransform: "capitalize",
            color: "#DDCA92",
            background:
              "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

            border: "1px solid #DDCA92",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#0B2F36",
            },
          }}
        >
          Cadastrar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateProcessDialog;
