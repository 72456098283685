/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-floating-promises */
import * as React from "react";

import { lawApi } from "../../../../../services/api";

import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useForm, type SubmitHandler } from "react-hook-form";
import * as yup from "yup";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  ptBR,
  type GridColDef,
  type GridValueGetterParams,
} from "@mui/x-data-grid";

// Material Dashboard 2 Components
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDInput from "../../../../../components/MDInput";
import MDTypography from "../../../../../components/MDTypography";

import { Autocomplete, Avatar, Box, TextField } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { useAuth } from "../../../../../hooks/useAuth";
import dataUF from "../../../../../brazilian-states.json";
import { useEffect } from "react";
import cep from "cep-promise";
const ODD_OPACITY = 0.8;
const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35, cpf: "123.123.123-12" },
  {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    age: 42,
    cpf: "123.123.123-12",
  },
  {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    age: 45,
    cpf: "123.123.123-12",
  },
  {
    id: 4,
    lastName: "Stark",
    firstName: "Arya",
    age: 16,
    cpf: "123.123.123-12",
  },
  {
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    age: null,
    cpf: "123.123.123-12",
  },
  {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    age: 150,
    cpf: "123.123.123-12",
  },
  {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    age: 44,
    cpf: "123.123.123-12",
  },
  {
    id: 8,
    lastName: "Frances",
    firstName: "Rossini",
    age: 36,
    cpf: "123.123.123-12",
  },
  {
    id: 9,
    lastName: "Roxie",
    firstName: "Harvey",
    age: 65,
    cpf: "123.123.123-12",
  },
];

type Row = typeof rows[number];

interface ICustomer {
  [x: string]: any;
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
  active: boolean;
}

interface ISubuser {
  _id: string;
  auth_user_id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    role: string;
    permissions: string[];
  };
  birth_date: string;
  cpf: string;
  identity: string;
  issuing_body: string;
  name: string;
  last_name: string;
  office: string;
  occupation: string;
  oab: string;
  social_status: string;
  uf: string;
  created_at: string;
}

interface IInputs {
  email: string;
  phone: string;
  fullname: string;
  cpf: string;
  birth_date: string;
  social_status: string;
  identity: string;
  issuing_body: string;
  uf: string;
  occupation: string;
  cep: string;
  street: string;
  number: number | string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
}

interface IAddress {
  [x: string]: any;
  cep: string;
  city: string;
  complement: string;
  created_at: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  updated_at: string;
  __v: number;
  _id: string;
}

interface IProcess {
  active: boolean;
  application_id: string;
  client_id: string;
  created_at: Date | string;
  code: string;
  files: object[] | null;
  notes: object[] | null;
  _id: string;
}

interface IService {
  active: boolean;
  user_id: string;
  client_id: string;
  created_at: Date | string;
  code: string;
  messages: IChat[];
  label: string;
  _id: string;
}

interface IChat {
  message: string;
  user_id: string;
  created_at: Date | string;
  user: ISubuser;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface IShowUserDialogProps {
  open: boolean;
  matches: boolean;
  userData: ICustomer | null;
  handleCloseUser: () => void;
  userAddress: IAddress | null;
  processes: readonly IProcess[];
  fetchCustomers: () => Promise<void>;
  fetchClientProcesses: () => Promise<void>;
  fetchClientServices: () => Promise<void>;
  toastFeedbackMessage: (type: string, message: string) => void;
  handleOpenDeleteUserDialog: (client: ICustomer | null) => void;
  handleOpenShowProcessDialog: (data: any, customer?: ICustomer[]) => void;
  handleOpenShowServiceDialog: () => void;
  services: IService[];
  allUsers: ISubuser[];
  setIsUpdateClient: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: "#DDCA92",
    backgroundColor: "#0B2F36",
    "&:hover, &.Mui-hovered": {
      color: "#DDCA92",
      // backgroundColor: alpha("#0B2F36", ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Sem dados</Box>
    </StyledGridOverlay>
  );
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  fullname: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .max(100, "No máximo 100 caracteres")
    .required("Informe o nome do cliente"),
  cpf: yup
    .string(),
  birth_date: yup.string(),
  // .required("Data inválida"),
  social_status: yup.string(),
  // .required("Informe estado civil"),
  identity: yup
    .string(),
  // .required("RG não pode ser vazio")
  // .max(20, "No máximo 20 caracteres"),
  issuing_body: yup
    .string(),
  // .min(3, "Deve ter no mínimo 3 caracteres")
  // .required("Informe o órgão expedidor"),
  uf: yup
    .string()
  // .min(2, "Deve ter no mínimo 2 caracteres")
  // .required("Informe o UF")
  ,
  occupation: yup.string().default(""),
  cep: yup
    .string()
  // .required("Informe o CEP")
  // .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP inválido")
  ,
  street: yup.string(),
  number: yup
    .string()
  // .matches(/(\d+)| /g, "Número inválido")
  // .required("Informe o número da casa ou apt.")
  // .min(1, "Número inválido")
  ,
  neighborhood: yup.string(),
  city: yup.string(),
  state: yup.string(),
  complement: yup.string().default(""),
});

export default function ShowUserDialog({
  open,
  matches,
  userData,
  processes,
  services,
  userAddress,
  fetchCustomers,
  handleCloseUser,
  toastFeedbackMessage,
  fetchClientProcesses,
  fetchClientServices,
  handleOpenShowProcessDialog,
  handleOpenDeleteUserDialog,
  handleOpenShowServiceDialog,
  allUsers,
  setIsUpdateClient,
}: IShowUserDialogProps) {
  // const { checkLogin, user } = useAuth();
  const [value, setValue] = React.useState(0);
  const {
    reset,
    register,
    setValue: setInputs,
    watch,
    setError,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });
  const { user } = useAuth();
  const [updatingUser, setUpdatingUser] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isCustomersProcessesFiltered, setIsCustomersProcessesFiltered] =
    React.useState<boolean>(false);
  const [filteredProcess, setFilteredProcess] = React.useState<any>();
  const [isCustomersServicesFiltered, setIsCustomersServicesFiltered] =
    React.useState<boolean>(false);
  const [isNewServices, setIsNewServices] = React.useState<boolean>(false);
  const [idCurrentService, setIdCurrentService] = React.useState<string>("");
  const [filteredService, setFilteredService] = React.useState<any>();
  const [chatService, setChatService] = React.useState<IChat[]>([]);
  const [currentChatService, setCurrentChatService] =
    React.useState<string>("");
  const [UF, setUF] = React.useState<{ nome: string; sigla: string }>();
  const [state, setState] = React.useState<{ nome: string; sigla: string }>();
  const [maritalStatus, setMaritalStatus] =
    React.useState<{ value: string; label: string }>();
  const [isUpdateChatService, setIsUpdateChatService] =
    React.useState<boolean>(false);

  const navigate = useNavigate();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setUpdatingUser(false);
    setValue(newValue);
  };

  const watchCep = watch("cep");

  useEffect(() => {
    void consultCEP();
  }, [watchCep]);

  const consultCEP = async () => {
    try {
      if (watchCep.length === 9) {
        const newCEPFormatted = watchCep.replace("-", "");

        const response = await cep(newCEPFormatted);

        if (response) {
          const { city, neighborhood, state, street } = response;
          const _currentState = dataUF.UF.find((uf) => uf.sigla === state);
          if (_currentState) {
            setState(_currentState);
          }

          setInputs("city", city);
          setInputs("neighborhood", neighborhood);
          setInputs("state", state);
          setInputs("street", street);
        }
      }
    } catch (error) { }
  };

  const defaultPropsMaritalStatus = {
    options: [
      { value: "solteiro", label: "Solteiro" },
      { value: "casado", label: "Casado" },
      { value: "separado", label: "Separado" },
      { value: "divorciado", label: "Divorciado" },
      { value: "viúvo", label: "Viúvo" },
    ],
    getOptionLabel: (option: { value: string; label: string }) => option.label,
  };

  const defaultPropsUF = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.sigla,
  };

  const defaultPropsState = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.nome,
  };

  const getUF = React.useCallback(() => {
    if (userData) {
      const _state = dataUF.UF.find((c: any) => c.sigla === userData.uf);

      if (_state !== undefined) {
        return _state;
      }
    }

    return null;
  }, [userData]);

  React.useEffect(() => {
    const _currentUF = getUF();

    if (_currentUF) setUF(_currentUF);
  }, [getUF]);

  const getState = React.useCallback(() => {
    if (userAddress) {
      const _state = dataUF.UF.find((c: any) => c.nome === userAddress.state);

      if (_state !== undefined) {
        return _state;
      }
    }

    return null;
  }, [userData]);

  React.useEffect(() => {
    const _currentState = getState();
    // console.log(_currentUF);

    if (_currentState) setState(_currentState);
  }, [getState]);

  React.useEffect(() => {
    const queryDiv = document.querySelector(
      ".css-5vji0m .MuiDialogContent-root",
    );

    if (queryDiv && isUpdateChatService) {
      queryDiv.scrollTo(0, queryDiv.scrollHeight); // scroll to bottom
    }

    setIsUpdateChatService(false);
  }, [isUpdateChatService]);

  const getSocialStatus = React.useCallback(() => {
    const options = [
      { value: "solteiro", label: "Solteiro" },
      { value: "casado", label: "Casado" },
      { value: "separado", label: "Separado" },
      { value: "divorciado", label: "Divorciado" },
      { value: "viúvo", label: "Viúvo" },
    ];
    if (userData && userData.social_status) {
      const _socialStatus = options.find(
        (c: { value: string; label: string }) =>
          c.label.toLocaleLowerCase() ===
          userData.social_status.toLocaleLowerCase(),
      );

      if (_socialStatus !== undefined) {
        return _socialStatus;
      }
    }

    return null;
  }, [userData]);

  React.useEffect(() => {
    const _currentSocialStatus = getSocialStatus();

    if (_currentSocialStatus) setMaritalStatus(_currentSocialStatus);
  }, [getSocialStatus]);

  const columnsProcess = React.useMemo<Array<GridColDef<any>>>(
    () => [
      {
        field: "code",
        headerName: "CNJ",
        sortable: true,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 150,
      },
      {
        field: "created_at",
        headerName: "Criado em",
        sortable: true,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
          dayjs(params.row.created_at).format("DD/MM/YYYY"),
      },
      {
        field: "action",
        headerName: "",
        type: "actions",
        sortable: false,
        filterable: false,
        flex: 0.5,
        minWidth: 50,
        getActions: (params) => [
          // eslint-disable-next-line react/jsx-key
          <Tooltip title="Ver Mais">
            <GridActionsCellItem
              icon={
                <VisibilityIcon
                  sx={{
                    color: "#0B2F36",
                  }}
                />
              }
              label="Ver mais"
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
              onClick={() => {
                handleOpenShowProcessDialog(params.row);
              }}
            />
          </Tooltip>,
        ],
      },
    ],
    [handleOpenShowProcessDialog],
  );

  const columnsServices = React.useMemo<Array<GridColDef<any>>>(
    () => [
      {
        field: "label",
        headerName: "Título",
        sortable: true,
        disableColumnMenu: true,
        // flex: 1,
        minWidth: 250,
        // colSpan: 1,
        // width: "max-content",

        resizable: true,
      },
      {
        field: "code",
        headerName: "Código",
        sortable: true,
        disableColumnMenu: true,
        // flex: 1,
        minWidth: 200,
      },
      {
        field: "updated_at",
        headerName: "Atualizado em",
        sortable: true,
        disableColumnMenu: true,
        // flex: 1,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
          dayjs(params.row.created_at).format("DD/MM/YYYY"),
      },

      {
        field: "action",
        headerName: "",
        type: "actions",
        sortable: false,
        filterable: false,
        minWidth: 100,
        getActions: (params) => [
          // eslint-disable-next-line react/jsx-key
          <Tooltip title="Ver Mais">
            <GridActionsCellItem
              icon={
                <VisibilityIcon
                  sx={{
                    color: "#0B2F36",
                  }}
                />
              }
              label="Ver mais"
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
              onClick={() => {
                // handleOpenShowProcessDialog(params.row);
                setIdCurrentService(params.row._id);
                setIsUpdateChatService(true);
                if (params.row.messages) {
                  setChatService([...params.row.messages]);
                }
              }}
            />
          </Tooltip>,
        ],
      },
    ],
    [handleOpenShowProcessDialog],
  );

  const handleSetUpdateForm = () => {
    const userFields = [
      "fullname",
      "cpf",
      "birth_date",
      "social_status",
      "identity",
      "issuing_body",
      "uf",
      "occupation",
      "email",
      "phone",
    ];
    const userAddressFields = [
      "cep",
      "street",
      "number",
      "neighborhood",
      "city",
      "state",
      "complement",
    ];

    userFields.forEach((field: string) => {
      // @ts-expect-error
      setInputs(field, userData[field]);
    });

    userAddressFields.forEach((field: string) => {
      // @ts-expect-error
      setInputs(field, userAddress[field]);
    });

    setUpdatingUser(true);
  };

  const invertDate = (date: string | undefined) => {
    const data = date?.split("-").reverse().join("/");

    return data;
  };

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0] || ""}${name.split(" ")[1][0] || ""}`,
    };
  };

  const updateChat = async () => {
    setIsNewServices(true);
    setIsUpdateChatService(true);
    try {
      if (currentChatService) {
        const payload: any = {
          message: currentChatService,
          user_id: user._id,
          created_at: new Date(),
        };

        const response = await lawApi.put(
          `/services/message/${idCurrentService}`,
          payload,
        );

        payload.user = user;

        const _arr: any = [...chatService];
        _arr.push(payload);

        setCurrentChatService("");
        setChatService(_arr);
      } else {
        toastFeedbackMessage("error", "Mensagem vazia!");
      }
    } catch (err: any) {
      const code = err.response.data.statusCode;
      console.log(err)
      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do atendimento. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do atendimento. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no cadastro do atendimento. Verifique os dados informados!",
        );
      }
    } finally {
      setIsNewServices(false);
    }
  };

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    const isAfterThenToday = dayjs(data.birth_date).isAfter(dayjs());
    const isBeforeThen1900 = dayjs(dayjs(data.birth_date)).isBefore(
      "1900-01-01",
    );

    if (isAfterThenToday || isBeforeThen1900) {
      setError("birth_date", {
        message: "Data inválida",
      });
    } else {
      // setDisableButton(true);
      setIsUpdateClient(true);

      data.number = +data.number;

      const userID = userData?._id;
      const userAddressID = userAddress?._id;

      const addressData = {
        cep: data.cep,
        street: data.street,
        number: data.number,
        neighborhood: data.neighborhood,
        city: data.city,
        state: state?.nome,
        complement: data.complement,
      };

      const userInfo = {
        fullname: data.fullname,
        cpf: data.cpf,
        birth_date: data.birth_date,
        identity: data.identity,
        email: data.email,
        phone: data.phone,
        issuing_body: data.issuing_body,
        social_status: maritalStatus?.label,
        uf: UF?.sigla,
        occupation: data.occupation,
      };

      try {
        const response = await Promise.all([
          lawApi.put(`/clients/${userID}`, userInfo),
          lawApi.put(`/addresses/${userAddressID}`, addressData),
        ]);

        const data = response.map((res) => res.data);

        if (data) {
          toastFeedbackMessage(
            "success",
            "Dados do cliente atualizados com sucesso",
          );
        }
        handleCloseUser();

        setIsUpdateClient(false);
      } catch (err: any) {

        const data = err.response && err.response.data
        const code = data && data.statusCode;

        if (code === 404) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro na atualização do cliente. Tente novamente mais tarde!",
          );
        } else if (code === 500) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro na atualização do cliente. Mas não se preocupe, não foi culpa sua.",
          );
        } else {
          if (data.message) {
            toastFeedbackMessage(
              "error",
              data.message,
            );
          } else {
            toastFeedbackMessage(
              "error",
              "Ocorreu um erro na atualização do cliente. Verifique os dados informados!",
            );

          }
        }
      } finally {
        await fetchCustomers();
      }
    }
  };

  const fetchProcesses = async (event: any) => {
    setIsCustomersProcessesFiltered(true);

    const filter = event.target.value;

    if (filter.length === 0) {
      setIsCustomersProcessesFiltered(false);
      return;
    }

    setLoading(true);
    try {
      const response = await lawApi.get(`/process/client/${userData?._id}`, {
        params: {
          cnj: filter.trim(),
        },
      });

      setFilteredProcess(response.data.data.processes);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const redirectToProcesses = () => {
    const userID = userData?._id;

    secureLocalStorage.setItem("client_id_selected", userID ?? "");
    navigate("/processos");
  };

  const fetchServices = async (event: any) => {
    // setIsServicesFiltered(true);
    setIsCustomersServicesFiltered(true);

    const filter = event.target.value;

    if (filter.length === 0) {
      setIsCustomersServicesFiltered(false);
      // return;
    }

    setLoading(true);
    try {
      let _id = "";

      if (userData) {
        _id = userData._id;
      }

      const response = await lawApi.get(`/services/client/${_id}`, {
        params: {
          filter: {
            code: filter.trim(),
            label: filter.trim(),
          },
        },
      });

      setFilteredService(response.data.data.services);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      setUpdatingUser(false);
      reset();
    }
  }, [isSubmitSuccessful, reset, updatingUser]);

  React.useEffect(() => {
    fetchClientProcesses();
    fetchClientServices();
  }, [userData]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setUpdatingUser(false);
        handleCloseUser();
        setValue(0);
        reset();
      }}
      fullWidth={true}
      maxWidth="xl"
      fullScreen={!!matches}
      sx={{
        overflow: "hidden",
        // padding: matches ? "1rem" : "initial",
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setUpdatingUser(false);
          handleCloseUser();
          setValue(0);
          reset();
        }}
      >
        <MDBox display="flex" flexDirection="column" alignItems="center">
          <MDTypography
            sx={{ color: "#0B2F36", fontSize: matches ? "20px" : "24px" }}
          >
            Visualizar cliente
          </MDTypography>
          <MDTypography
            sx={{
              color: "#0B2F36",
              fontSize: matches ? "18px" : "22px",
              fontWeight: 600,
            }}
          >
            {userData?.fullname}
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>
      <MDBox display="flex" justifyContent="space-between" mx={matches ? 0 : 8}>
        <Tabs
          value={value}
          onChange={handleChange}
          // flexDirection={matches ? "column" : "row"}
          sx={{
            display: matches ? "flex" : "initial",
            flexDirection: "column",
            width: "100%",
            overflowY: "auto",
            padding: "0 0 0 0",

            "& .css-1eudeag": {
              marginLeft: "0 !important",
              marginRight: "0 !important",
            },
          }}
        >
          <Tab
            sx={{
              fontSize: matches ? "12px" : "16px",
              fontWeight: 600,
              color: "#0B2F36",
              textTransform: "capitalize",
            }}
            label="Detalhes"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              fontSize: matches ? "12px" : "16px",
              fontWeight: 600,
              color: "#0B2F36",
              textTransform: "capitalize",
            }}
            label="Processos"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              fontSize: matches ? "12px" : "16px",
              fontWeight: 600,
              color: "#0B2F36",
              textTransform: "capitalize",
            }}
            label="Atendimento"
            {...a11yProps(1)}
            onClick={() => {
              setIsUpdateChatService(true);
            }}
          />
        </Tabs>
      </MDBox>
      <TabPanel value={value} index={0}>
        <DialogContent
          sx={{
            marginTop: "-1.5em",
            padding: matches ? "30px 0px 0 0px" : "30px 50px 0 43px",
          }}
        >
          <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
            <MDBox
              sx={{
                height: "13px",
                width: "5px",
                backgroundColor: "#0B2F36",
                mr: 1,
              }}
            ></MDBox>
            <MDTypography variant="subtitle2" sx={{ color: "#0B2F36" }}>
              Dados pessoais
            </MDTypography>
          </DialogContentText>
          <MDBox
            noValidate
            component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Nome{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.fullname == null)}
                      {...register("fullname")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      type="text"
                      variant="outlined"
                      value={userData?.fullname}
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      fullWidth
                    />
                  )}

                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.fullname?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    CPF{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.cpf == null)}
                      {...register("cpf")}
                      mask={"999.999.999-99"}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userData?.cpf}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.cpf?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <MDBox display="flex" flexDirection="column">
                  {updatingUser ? (
                    <>
                      <InputLabel sx={{ color: "#0B2F36", mb: 1 }}>
                        Data Nascimento{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >

                        </MDTypography>
                      </InputLabel>
                      <MDInput
                        type="date"
                        error={!(errors.birth_date == null)}
                        {...register("birth_date")}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <InputLabel sx={{ color: "#0B2F36", mb: 1 }}>
                        Data Nascimento{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >

                        </MDTypography>
                      </InputLabel>
                      <MDInput
                        disabled
                        type="text"
                        variant="outlined"
                        value={invertDate(userData?.birth_date)}
                        sx={{
                          ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                            { "-webkit-text-fill-color": "#495057 !important" },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                      />
                    </>
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.birth_date?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Estado civil{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      noOptionsText="Sem opções"
                      {...defaultPropsMaritalStatus}
                      value={maritalStatus ?? null}
                      // defaultValue={client}
                      onChange={(event: any, newValue: any) => {
                        setMaritalStatus(newValue);
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userData?.social_status}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.social_status?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    RG{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.identity == null)}
                      {...register("identity")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userData?.identity}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.identity?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Órgão Emissor{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.issuing_body == null)}
                      {...register("issuing_body")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userData?.issuing_body}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.issuing_body?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    UF{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      noOptionsText="Estado inexistente"
                      {...defaultPropsUF}
                      value={UF ?? null}
                      // defaultValue={client}
                      onChange={(event: any, newValue: any) => {
                        setUF(newValue);
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userData?.uf}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.uf?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Profissão{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    ></MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.occupation == null)}
                      {...register("occupation")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userData?.occupation}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.occupation?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
            <MDBox
              sx={{
                height: "13px",
                width: "5px",
                backgroundColor: "#0B2F36",
                mr: 1,
              }}
            ></MDBox>
            <MDTypography variant="subtitle2" sx={{ color: "#0B2F36" }}>
              Contato
            </MDTypography>
          </DialogContentText>
          <MDBox
            noValidate
            component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    E-mail{" "}
                    <MDTypography
                      sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                    ></MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.email == null)}
                      {...register("email")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userData?.email}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}

                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.email?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Celular{" "}
                    <MDTypography
                      sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                    ></MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.phone == null)}
                      {...register("phone")}
                      type="text"
                      mask={"(99) 99999-9999"}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userData?.phone}
                      mask={"(99) 99999-9999"}
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}

                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.phone?.message}
                  </MDTypography>
                </MDBox>
              </Grid>

            </Grid>
          </MDBox>
          <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
            <MDBox
              sx={{
                height: "13px",
                width: "5px",
                backgroundColor: "#0B2F36",
                mr: 1,
              }}
            ></MDBox>
            <MDTypography variant="subtitle2" sx={{ color: "#0B2F36" }}>
              Endereço
            </MDTypography>
          </DialogContentText>
          <MDBox
            noValidate
            component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    CEP{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.cep == null)}
                      {...register("cep")}
                      type="text"
                      mask={"99999-999"}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userAddress?.cep}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.cep?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Rua{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.street == null)}
                      {...register("street")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userAddress?.street}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.street?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Número{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.number == null)}
                      {...register("number")}
                      type="number"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userAddress?.number}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.number?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Bairro{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.neighborhood == null)}
                      {...register("neighborhood")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userAddress?.neighborhood}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.neighborhood?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Cidade{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.city == null)}
                      {...register("city")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userAddress?.city}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.city?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Estado{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >

                    </MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      noOptionsText="Estado inexistente"
                      {...defaultPropsState}
                      value={state ?? null}
                      onChange={(event: any, newValue: any) => {
                        setState(newValue);
                        if (newValue) {
                          setInputs("state", newValue.nome);
                        } else {
                          setInputs("state", "");
                        }
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userAddress?.state}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.state?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                    Complemento{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    ></MDTypography>
                  </InputLabel>
                  {updatingUser ? (
                    <MDInput
                      error={!(errors.complement == null)}
                      {...register("complement")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingUser}
                      value={userAddress?.complement}
                      type="text"
                      variant="outlined"
                      sx={{
                        ".css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                          { "-webkit-text-fill-color": "#495057 !important" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.complement?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            // flexDirection: matches ? "column" : "row",
          }}
        >
          <MDButton
            onClick={() => {
              handleOpenDeleteUserDialog(userData);
            }}
            variant="outlined"
            sx={{
              width: "150px",

              padding: "10px",
              textTransform: "capitalize",
              border: "2px solid #DDCA92",
              borderRadius: "10px",
              color: "#0B2F36",

              "&:hover": {
                color: "#0B2F36",
                border: "2px solid #DDCA92",
              },
            }}
          >
            Excluir
          </MDButton>
          <MDButton
            onClick={
              !updatingUser ? handleSetUpdateForm : handleSubmit(onSubmit)
            }
            color="tertiary"
            variant="contained"
            sx={{
              width: "150px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#DDCA92",
              background:
                "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

              border: "1px solid #DDCA92",
              borderRadius: "10px",
              "&:hover": {
                color: "#DDCA92",
                background:
                  "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
              },
              "&:active": {
                color: "#DDCA92",
                backgroundColor: "#0B2F36",
              },
            }}
          >
            {!updatingUser ? "Editar" : "Confirmar edição"}
          </MDButton>
        </DialogActions>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DialogContent
          sx={{
            marginTop: "-1.5em",
            overflow: "hidden",
            padding: matches ? "30px 0px 0 0px" : "30px 50px 0 43px",
          }}
        >
          <MDBox sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
            <MDBox
              display="flex"
              flexDirection={matches ? "column" : "row"}
              justifyContent="space-between"
              mb={4}
              gap={matches ? "8px 0" : "0 8px"}
            >
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: matches ? "100%" : "35ch",
                  height: "40px",
                  backgroundColor: "transparent !important",
                  border: "1px solid #DDCA92",
                  boxShadow: "none !important",
                }}
              >
                <InputBase
                  onChange={(e) => {
                    void fetchProcesses(e);
                  }}
                  sx={{
                    ml: 1,
                    flex: 1,
                    "& ::placeholder": { fontSize: "14px", mx: 1 },
                    "&": { fontSize: "14px" },
                  }}
                  placeholder="Buscar por CNJ"
                  inputProps={{ "aria-label": "buscar" }}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <MDBox
                  sx={{
                    backgroundColor: "#0B2F36",
                    borderRadius: 1,
                    height: "40px",
                  }}
                  mx={-0.55}
                >
                  <IconButton sx={{ px: "7px" }}>
                    <SearchIcon sx={{ color: "#DDCA92" }} />
                  </IconButton>
                </MDBox>
              </Paper>
              <MDButton
                // component={Link}
                // to="/processos"
                onClick={redirectToProcesses}
                variant="gradient"
                size="medium"
                sx={{
                  backgroundColor: "#0B2F36",
                  color: "#f1f1f1",
                  height: "40px",
                  "&:hover": {
                    backgroundColor: "#0B1F36",
                  },
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                Cadastrar novo processo&nbsp;&nbsp;{" "}
                <Icon sx={{ color: "#DDCA92", fontSize: 50 }}>add</Icon>
              </MDButton>
            </MDBox>
            <MDBox sx={{ height: 400, width: "100%" }}>
              <StripedDataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={
                  isCustomersProcessesFiltered && filteredProcess != null
                    ? filteredProcess
                    : processes
                }
                columns={columnsProcess}
                paginationMode="client"
                pageSizeOptions={[5, 10, 25]}
                disableRowSelectionOnClick
                sortingMode="client"
                filterMode="client"
                autoPageSize
                getRowId={(row) => row?._id}
                // getRowClassName={(params) =>
                //   params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                // }
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                loading={loading}
                sx={{
                  // color: "#0B2F36",
                  fontWeight: 500,
                  boxShadow: 2,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  "& .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-cellContent": {
                    overflowWrap: "anywhere !important",
                  },

                  "& .MuiDataGrid-cell": {
                    overflow: "initial !important",
                    whiteSpace: "normal !important",
                  },
                }}
              />
            </MDBox>
          </MDBox>
        </DialogContent>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DialogContent
          sx={{
            marginTop: "-1.5em",
            overflow: "hidden",
            padding: matches ? "0px 0px 0 0px" : "30px 50px 0 43px",
            // height: 400,
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              height: "100%",
            }}
          >
            {idCurrentService ? (
              <>
                <DialogContent
                  sx={{
                    height: "100%",
                    scrollBehavior: "smooth",
                  }}
                >
                  <MDBox
                    display="flex"
                    flexDirection={"column"}
                    justifyContent="space-between"
                    mb={4}
                    // mt={4}
                    gap={matches ? "1rem 0" : "1rem 0px"}
                    id="chat-services"
                    sx={{
                      height: 200,
                      ".css-1vam7s3-MuiGrid-root:last-child": {
                        paddingBottom: "1rem !important",
                      },
                    }}
                  >
                    {chatService.map((value, index) => {
                      if (value.user_id === user._id) {
                        return (
                          <Grid container alignItems={"center"} key={index}>
                            <Grid item xs={11} md={11} lg={11}>
                              <MDBox
                                sx={{
                                  backgroundColor: "#FFF",
                                  borderRadius: "16px 16px 0px 16px",
                                  padding: matches ? "1rem 2rem" : "1rem",
                                  boxShadow:
                                    "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
                                  borderRight: "1px solid #000",
                                }}
                                display="flex"
                              >
                                <MDBox display="flex" flexDirection="column">
                                  <MDTypography
                                    sx={{
                                      color: "#0B2F36",
                                      fontSize: "12px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Dr(a). {value.user.name}{" "}
                                    {value.user.last_name} -{" "}
                                    {dayjs(value.created_at).format(
                                      "DD/MM/YY HH:mm",
                                    )}
                                  </MDTypography>
                                  <MDTypography
                                    sx={{
                                      color: "#0B2F36",
                                      fontSize: "16px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {value.message}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} alignItems={"end"}>
                              <MDBox
                                display="flex"
                                justifyContent="end"
                                alignSelf="end"
                              >
                                <Avatar
                                  alt=""
                                  {...stringAvatar(
                                    `${value.user.name} ${value.user.last_name}`,
                                  )}
                                  sx={{ width: 46, height: 46 }}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        );
                      }

                      return (
                        <Grid container alignItems={"end"} key={index}>
                          <Grid item xs={1} md={1} lg={1} alignItems={"end"}>
                            <MDBox
                              display="flex"
                              justifyContent="start"
                              alignSelf="end"
                            >
                              <Avatar
                                alt=""
                                {...stringAvatar(
                                  `${value.user.name} ${value.user.last_name}`,
                                )}
                                sx={{ width: 46, height: 46 }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={11} md={11} lg={11}>
                            <MDBox
                              sx={{
                                backgroundColor: "#FFF",
                                borderRadius: "16px 16px 16px 0px",
                                padding: matches ? "1rem 2rem" : "1rem",
                                boxShadow:
                                  "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
                                borderLeft: "1px solid #000",
                              }}
                              display="flex"
                            >
                              <MDBox
                                display="flex"
                                flexDirection="column"
                              // alignItems="center"
                              >
                                <MDTypography
                                  sx={{
                                    color: "#0B2F36",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                  }}
                                >
                                  Dr(a). {value.user.name}{" "}
                                  {value.user.last_name}
                                  {""}
                                  {dayjs(value.created_at).format(
                                    "DD/MM/YY HH:mm",
                                  )}
                                </MDTypography>
                                <MDTypography
                                  sx={{
                                    color: "#0B2F36",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {value.message}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </MDBox>
                </DialogContent>
                <DialogActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <MDBox sx={{ width: "100%" }}>
                    <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                      Nova mensagem
                    </InputLabel>
                    <InputBase
                      rows={10}
                      fullWidth
                      type="text"
                      value={currentChatService}
                      // variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            border: "1px solid #DDCA92",
                          },
                        },
                        ".css-11sdo48-MuiInputBase-root": {
                          border: "1px solid #DDCA92",
                        },
                        border: "1px solid #DDCA92",
                        borderRadius: "5px",
                        padding: "10px",
                        fontSize: "16px",
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                        return setCurrentChatService(event.target.value);
                      }}
                    />
                  </MDBox>
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <MDButton
                      onClick={() => {
                        setIdCurrentService("");
                      }}
                      variant="outlined"
                      sx={{
                        width: "150px",

                        padding: "10px",
                        textTransform: "capitalize",
                        border: "2px solid #DDCA92",
                        borderRadius: "10px",
                        color: "#0B2F36",

                        "&:hover": {
                          color: "#0B2F36",
                          border: "2px solid #DDCA92",
                        },
                      }}
                    >
                      Voltar
                    </MDButton>
                    <MDButton
                      // eslint-disable-next-line @typescript-eslint/promise-function-async
                      onClick={() => updateChat()}
                      color="tertiary"
                      variant="contained"
                      sx={{
                        width: "150px",

                        padding: "10px",
                        textTransform: "capitalize",
                        color: "#DDCA92",
                        background:
                          "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                        border: "1px solid #DDCA92",
                        borderRadius: "10px",
                        "&:hover": {
                          color: "#DDCA92",
                          background:
                            "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                        },
                        "&:active": {
                          color: "#DDCA92",
                          backgroundColor: "#0B2F36",
                        },
                      }}
                    >
                      Enviar
                    </MDButton>
                  </MDBox>
                </DialogActions>
              </>
            ) : (
              <>
                <MDBox
                  display="flex"
                  flexDirection={matches ? "column" : "row"}
                  justifyContent="space-between"
                  mb={4}
                  gap={matches ? "8px 0" : "0 8px"}
                >
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: matches ? "100%" : "35ch",
                      height: "40px",
                      backgroundColor: "transparent !important",
                      border: "1px solid #DDCA92",
                      boxShadow: "none !important",
                      overflow: "hidden !important",
                      // marginTop: "2rem",
                    }}
                  >
                    <InputBase
                      onChange={(e) => {
                        void fetchServices(e);
                      }}
                      sx={{
                        ml: 1,
                        flex: 1,
                        "& ::placeholder": { fontSize: "14px", mx: 1 },
                        "&": { fontSize: "14px" },
                      }}
                      placeholder="Buscar por código ou título"
                      inputProps={{ "aria-label": "buscar" }}
                    />
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <MDBox
                      sx={{
                        backgroundColor: "#0B2F36",
                        borderRadius: 1,
                        height: "40px",
                      }}
                      mx={-0.55}
                    >
                      <IconButton sx={{ px: "7px" }}>
                        <SearchIcon sx={{ color: "#DDCA92" }} />
                      </IconButton>
                    </MDBox>
                  </Paper>
                  <MDButton
                    // component={Link}
                    // to="/processos"
                    onClick={() => {
                      // setIsNewServices(true);
                      handleOpenShowServiceDialog();
                      handleCloseUser();
                    }}
                    variant="gradient"
                    size="medium"
                    sx={{
                      backgroundColor: "#0B2F36",
                      color: "#f1f1f1",
                      height: "40px",
                      "&:hover": {
                        backgroundColor: "#0B1F36",
                      },
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    Novo atendimento&nbsp;&nbsp;{" "}
                    <Icon sx={{ color: "#DDCA92", fontSize: 50 }}>add</Icon>
                  </MDButton>
                </MDBox>
                <MDBox sx={{ height: 400, width: "100%" }}>
                  <StripedDataGrid
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={
                      isCustomersServicesFiltered && filteredService != null
                        ? filteredService
                        : services
                    }
                    columns={columnsServices}
                    paginationMode="client"
                    pageSizeOptions={[5, 10, 25]}
                    disableRowSelectionOnClick
                    sortingMode="client"
                    filterMode="client"
                    autoPageSize
                    getRowId={(row) => row?._id}
                    // getRowClassName={(params) =>
                    //   params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    // }
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    loading={loading}
                    sx={{
                      // color: "#0B2F36",
                      fontWeight: 500,
                      boxShadow: 2,
                      backgroundColor: "#fff",
                      borderRadius: 2,
                      "& .MuiDataGrid-cell:focus": {
                        outline: "none",
                      },
                      "& .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                      },
                      "& .MuiDataGrid-cellContent": {
                        overflowWrap: "anywhere !important",
                      },

                      "& .MuiDataGrid-cell": {
                        overflow: "initial !important",
                        whiteSpace: "normal !important",
                      },
                    }}
                  />
                </MDBox>
              </>
            )}
          </MDBox>
        </DialogContent>
      </TabPanel>
    </Dialog>
  );
}
