import * as React from "react";

import { lawApi, api } from "../../../../../services/api";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";

import { Link, useNavigate } from "react-router-dom";

interface IDeactivateDialog {
  openDeleteRecordDialog: boolean;
  applicationCode: string;
  handleCloseDeactivateAccountDialog: () => void;
  toastFeedbackMessage: (type: string, message: string) => any;
}

export default function DeactivateDialog({
  applicationCode,
  toastFeedbackMessage,
  openDeleteRecordDialog,
  handleCloseDeactivateAccountDialog,
}: IDeactivateDialog) {
  const navigate = useNavigate();
  const handleDeleteProcess = async () => {
    const formData = {
      check_terms: true,
      application_code: applicationCode,
    };
    try {
      const response = await api.post("payments/cancel/platform", formData);
      const data = response.data;

      if (data) {
        toastFeedbackMessage("success", "Conta desativada com sucesso!");
        navigate("/logout");
      }
    } catch (err: any) {
      console.log(err);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na desativação da conta. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na desativação da conta. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na desativação da conta. Verifique os dados informados!",
        );
      }
    } finally {
      handleCloseDeactivateAccountDialog();
    }
  };

  return (
    <Dialog
      open={openDeleteRecordDialog}
      onClose={handleCloseDeactivateAccountDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ padding: "40px 50px 30px 50px", backgroundColor: "#0B2F36" }}
      >
        <DialogContentText sx={{ textAlign: "center" }}>
          <MDTypography sx={{ color: "#fff" }}>
            Tem certeza que deseja desativar sua conta?
          </MDTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseDeactivateAccountDialog}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Não
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleDeleteProcess}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Sim
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
