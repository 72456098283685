import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

interface ISignupConfirmDialog {
  openConfirmDialog: boolean;
  handleCloseConfirmDialog: () => void;
  handleChangeFormStep: (newStep: number) => void;
}

export default function SignupConfirmDialog({
  openConfirmDialog,
  handleChangeFormStep,
  handleCloseConfirmDialog,
}: ISignupConfirmDialog) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
    >
      <DialogContent
        sx={{ padding: "40px 55px 30px 55px", backgroundColor: "#0B2F36" }}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="10px 0"
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="0 10px"
          >
            <MDTypography
              sx={{ fontWeight: 500, color: "#fff", fontSize: "20px" }}
            >
              Confirmar informações?
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="0 10px"
          >
            <MDTypography
              sx={{ fontWeight: 500, color: "#fff", fontSize: "15px" }}
            >
              Ao continuar, não será possível retornar às etapas anteriores.
            </MDTypography>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseConfirmDialog}
          sx={{
            width: "150px",
            borderColor: "#DDCA92",
            borderRadius: "15px",
          }}
        >
          Revisar
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleChangeFormStep}
          sx={{
            color: "#0B2F36",
            width: "150px",
            backgroundColor: "#DDCA92",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "#DDCA92",
            },
          }}
        >
          Continuar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
