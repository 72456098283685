/* eslint-disable array-callback-return */
import * as React from "react";

import { lawApi } from "../../../../services/api";

// @mui material components
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { v4 as uuidv4 } from "uuid";
import {
  DataGrid,
  GridActionsCellItem,
  ptBR,
  type GridColDef,
  type GridRenderCellParams,
} from "@mui/x-data-grid";

import { styled } from "@mui/material/styles";

// Material Dashboard 2 Components
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";

interface IActiveProcess {
  [x: string]: any;
  _id: string;
  active: boolean;
  application_id: string;
  client_id: string;
  code: string;
  created_at: string;
  deleted_at: string;
  description: string;
  files: object[];
  notes: object[];
  clientCpf: string;
  clientName: string;
}

interface IProcessTable {
  handleOpenNewProcessDialog: () => void;
  handleOpenShowProcessDialog: (data: any, customer?: ICustomer[]) => void;
  activeProcesses: IActiveProcess;
  customers: readonly ICustomer[];
  matches: boolean;
  loading: boolean;
}

interface ICustomer {
  [x: string]: any;
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Sem dados</Box>
    </StyledGridOverlay>
  );
}

export default function ProcessTable({
  handleOpenNewProcessDialog,
  handleOpenShowProcessDialog,
  activeProcesses,
  customers,
  matches,
  loading,
}: IProcessTable) {
  const [loadingFiltered, setLoadingFiltered] = React.useState<boolean>(false);
  const [isProcessFiltered, setIsProcessFiltered] =
    React.useState<boolean>(false);
  const [filteredProcess, setFilteredProcess] = React.useState<
    IActiveProcess | any
  >({
    _id: "",
    active: false,
    application_id: "",
    client_id: "",
    code: "",
    created_at: "",
    deleted_at: "",
    description: "",
    files: [],
    notes: [],
    clientCpf: "",
    clientName: "",
  });

  const columns = React.useMemo<Array<GridColDef<IActiveProcess>>>(
    () => [
      {
        field: "code",
        headerName: "CNJ",
        description: "Essa coluna não é ordenável.",
        sortable: false,
        disableColumnMenu: true,
        flex: !matches ? 1 : 0,
        minWidth: 200,
      },
      {
        field: "clientName",
        headerName: "Cliente",
        description: "Essa coluna não é ordenável.",
        sortable: false,
        disableColumnMenu: true,
        flex: !matches ? 1 : 0,
        minWidth: 200,
      },
      {
        field: "clientCpf",
        headerName: "CPF",
        disableColumnMenu: true,
        flex: !matches ? 1 : 0,
        minWidth: 200,
      },
      {
        field: "status",
        headerName: "Status",
        flex: !matches ? 1 : 0,
        minWidth: 50,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <MDBox
            display="flex"
            justifyContent="center"
            sx={{
              backgroundColor:
                params.row.status === "Iniciado"
                  ? "#19953C"
                  : params.row.status === "Andamento"
                    ? "#0B2F36"
                    : "#6b1111",
              borderRadius: "50px",
              width: "100%",
              maxWidth: "200px",
            }}
          >
            <MDTypography
              variant="text"
              sx={{
                color: "#fff",
                fontSize: "12px",
              }}
            >
              {params.row.status.charAt(0).toUpperCase()}
              {params.row.status.slice(1)}
            </MDTypography>
          </MDBox>
        ),
      },
      {
        field: "action",
        headerName: "",
        type: "actions",
        sortable: false,
        filterable: false,
        flex: !matches ? 1 : 0,
        minWidth: 200,
        getActions: (params) => [
          // eslint-disable-next-line react/jsx-key
          <Tooltip title="Ver Mais">
            <GridActionsCellItem
              icon={<VisibilityIcon sx={{ color: "#0B2F36" }} />}
              label="Delete"
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
              onClick={() => {
                handleOpenShowProcessDialog(params.row);
              }}
            />
          </Tooltip>,
        ],
      },
    ],
    [handleOpenShowProcessDialog],
  );

  const handleFilterProcessData = async (event: any): Promise<void> => {
    setIsProcessFiltered(true);

    const filter = event.target.value;

    if (filter.length === 0) {
      setIsProcessFiltered(false);
      // return;
    }

    setLoadingFiltered(true);

    try {
      const response = await lawApi.get("/process/", {
        params: {
          filter: filter,
        },
      });

      setFilteredProcess(response.data.data.processes);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoadingFiltered(false);
    }
  };

  return (
    <MDBox>
      <MDBox my={2}>
        <MDTypography variant="h5" sx={{ color: "#0B2F36" }}>
          Processos
        </MDTypography>
      </MDBox>
      <MDBox
        display="flex"
        flexDirection={matches ? "column" : "row"}
        justifyContent="space-between"
        mb={4}
        gap={matches && "15px 0"}
      >
        <MDBox display="flex" flexDirection="column">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: matches ? "100%" : "25ch",
              height: "40px",
              backgroundColor: "transparent !important",
              border: "1px solid #DDCA92",
              boxShadow: "none !important",
            }}
          >
            <InputBase
              onChange={(e) => {
                void handleFilterProcessData(e);
              }}
              sx={{
                ml: 1,
                flex: 1,
                "& ::placeholder": { fontSize: "14px", mx: 1 },
                "&": { fontSize: "14px" },
              }}
              placeholder="Buscar por CNJ, cliente ou CPF"
              inputProps={{ "aria-label": "buscar" }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <MDBox
              sx={{
                backgroundColor: "#0B2F36",
                borderRadius: 1,
                height: "40px",
              }}
              mx={-0.55}
            >
              <IconButton sx={{ px: "7px" }}>
                <SearchIcon sx={{ color: "#DDCA92" }} />
              </IconButton>
            </MDBox>
          </Paper>
        </MDBox>
        <MDButton
          onClick={handleOpenNewProcessDialog}
          variant="gradient"
          color="success"
          size="medium"
          sx={{
            backgroundColor: "#0B2F36",
            boxShadow: "none",
            color: "#f1f1f1",
            "&:hover": {
              backgroundColor: "#0B1F36",
              boxShadow: "none",
            },
            height: "40px",
            textTransform: "capitalize",
          }}
        >
          Cadastrar&nbsp;{" "}
          <Icon sx={{ color: "#DDCA92", fontSize: 40 }}>add</Icon>
        </MDButton>
      </MDBox>
      <MDBox sx={{ height: 400, width: "100%" }}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={
            isProcessFiltered && filteredProcess != null
              ? filteredProcess
              : activeProcesses
          }
          columns={columns}
          paginationMode="client"
          pageSizeOptions={[5, 10, 25]}
          disableRowSelectionOnClick
          sortingMode="client"
          filterMode="client"
          // autoHeight
          autoPageSize
          getRowId={(row) => row?._id}
          loading={isProcessFiltered ? loadingFiltered : loading}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          sx={{
            boxShadow: 2,
            backgroundColor: "#fff",
            borderRadius: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "#333",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          }}
        />
      </MDBox>
    </MDBox>
  );
}
