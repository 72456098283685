import * as React from "react";

import { lawApi } from "../../../../services/api";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import toast from "react-hot-toast";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import MDBox from "../../../../components/MDBox";

interface IScheduleData {
  client: {
    name: string;
    last_name: string;
    phone: string;
    email: string;
  };
  title: string | null;
  description: string;
  user_id: string;
  date: string;
  location: string;
  start_time: string;
}

interface IConfirmScheduleDialog {
  scheduleData: IScheduleData;
  openConfirmSchedule: boolean;
  handleIsSubmitSuccessfull: () => void;
  handleCloseConfirmSchedule: () => void;
  handleChangeFormStep: (newStep: number) => void;
}

export default function ScheduleConfirmDialog({
  scheduleData,
  openConfirmSchedule,
  handleChangeFormStep,
  handleIsSubmitSuccessfull,
  handleCloseConfirmSchedule,
}: IConfirmScheduleDialog) {
  const handleConfirmSchedule = async () => {
    try {
      const response = await lawApi.post("/schedules/client", scheduleData);
      const data = response.data;
      // console.log(scheduleData, data);
      handleChangeFormStep(2);
      handleIsSubmitSuccessfull();
    } catch (err: any) {
      // console.log(err.response.data, scheduleData);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toast.error(
          "Ocorreu um erro no cadastro do agendamento. Tente novamente mais tarde!",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro no cadastro do agendamento. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      } else {
        toast.error(
          "Ocorreu um erro no cadastro do agendamento. Talvez esse horário não esteja mais disponível.",
          {
            duration: 5000,
          },
        );
      }
    } finally {
      handleCloseConfirmSchedule();
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={openConfirmSchedule}
      onClose={handleCloseConfirmSchedule}
    >
      <DialogContent
        sx={{ padding: "40px 55px 30px 55px", backgroundColor: "#0B2F36" }}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="10px 0"
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="0 10px"
          >
            <CalendarTodayIcon fontSize="medium" sx={{ color: "#fff" }} />
            <MDTypography
              sx={{ fontWeight: 500, color: "#fff", fontSize: "20px" }}
            >
              {dayjs(scheduleData?.date).locale("pt-br").format("dddd, DD")},{" "}
              {scheduleData?.start_time}
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="0 10px"
          >
            <RoomOutlinedIcon fontSize="medium" sx={{ color: "#fff" }} />
            <MDTypography
              sx={{ fontWeight: 500, color: "#fff", fontSize: "15px" }}
            >
              {scheduleData?.location}
            </MDTypography>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseConfirmSchedule}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Cancelar
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleConfirmSchedule}
          sx={{
            width: "150px",
            backgroundColor: "#DDCA92",
            borderRadius: "15px",
          }}
        >
          Agendar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
