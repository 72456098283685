/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import "./App.css";

import { useEffect, useState } from "react";

import secureLocalStorage from "react-secure-storage";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 React example components
import Sidenav from "./examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "./assets/theme";

// Material Dashboard 2 React Dark Mode themes

// Material Dashboard 2 React routes
import routes from "./routes";

// Material Dashboard 2 React contexts
import { setMiniSidenav, useMaterialUIController } from "./context";

// Images
import brand from "./assets/images/logos/law_color_primary.svg";

import type { FixMeLater } from "./any";
import { useAuth } from "./hooks/useAuth";
import ScreenLoading from "./components/ScreenLoading";

export default function App(): JSX.Element {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [currentRoute, setCurrentRoute] = useState<any>([]);
  const [redirectPath, setRedirectPath] = useState("");
  const [dataUser, setDataUser] = useState<any>();
  const { user, loadingPage } = useAuth();

  // const [permission, setPermission] = useState<string[]>([]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = (): void => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = (): void => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement!.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const informationUser = secureLocalStorage.getItem("user") as string;
    if (informationUser != null) {
      const data = JSON.parse(informationUser);

      const arrPermission: string[] = [];

      // Administrador tem acesso a todas as rotas e é redirecionado ao cliente
      if (data.applications[0].role === "admin") {
        arrPermission.push("ALL");

        setCurrentRoute(routes);
        setRedirectPath(routes[0].route);
      } else {
        // Usuário tem acesso a permissões cadastradas e é redirecionado a primeira rota que contém permissão
        data.applications[0].permissions.forEach((permission: string) => {
          const currentPermission = permission
            .split("_")
            .slice(-1)[0]
            .toUpperCase();

          if (!arrPermission.includes(currentPermission)) {
            arrPermission.push(currentPermission);
          }
        });

        const arrayRoute: any[] = [];

        routes.forEach((route: FixMeLater) => {
          if (!route.permission || arrPermission.includes(route?.permission)) {
            arrayRoute.push(route);
          }
        });
        setCurrentRoute(arrayRoute);
        setRedirectPath(arrayRoute[0].route);
      }
    }
  }, [user]);

  const getRoutes = (allRoutes: FixMeLater) =>
    allRoutes.map((route: FixMeLater) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loadingPage ? <ScreenLoading /> :
        <>
          {user && layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                routes={currentRoute.length > 0 ? currentRoute : routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          {/* <AppProvider> */}
          <Routes>
            {getRoutes(currentRoute.length > 0 ? currentRoute : routes)}

            {/* Verifica se tem usuário, se tiver vai para a primeira rota disponível (cliente, usuário) depende das permissoões */}
            <Route
              path="*"
              element={
                currentRoute.length > 0
                  ? currentRoute[0].component
                  : routes[0].component
              }
            />
          </Routes>
        </>
      }
      {/* </AppProvider> */}
    </ThemeProvider>
  );
}
