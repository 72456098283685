/* eslint-disable no-useless-escape */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import dayjs from "dayjs";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputLabel,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

import { lawApi } from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/useAuth";
import toast from "react-hot-toast";
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";

const today = dayjs().format("DD/MM/YYYY");

interface ICreateSubuserDialog {
  matches: boolean;
  openNewUser: boolean;
  handleCloseNewUserDialog: () => void;
  fetchSubusers: () => Promise<void>;
}

interface IInputs {
  name: string;
  last_name: string;
  email: string;
  office: string;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  name: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .required("Informe o nome do usuário"),
  last_name: yup.string().required("Informe o sobrenome do usuário"),
  email: yup
    .string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Informe e-mail válido")
    .required("Informe o e-mail do usuário"),
  office: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .required("Informe o cargo do usuário"),
});

function CreateSubuserDialog({
  matches,
  openNewUser,
  handleCloseNewUserDialog,
  fetchSubusers,
}: ICreateSubuserDialog) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });
  const { user } = useAuth();

  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [permissions, setPermission] = useState<any>([]);

  const handleChange = (checkedPermission: string) => {
    const hasPermission = permissions.includes(checkedPermission);

    if (hasPermission) {
      const filteredPermissions = permissions.filter((permission: any) => {
        return permission !== checkedPermission;
      });

      setPermission(filteredPermissions);
    } else {
      setPermission([...permissions, checkedPermission]);
    }
  };

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    setDisableButton(true);

    const formData = {
      ...data,
      application_id: user.applications[0].application_id,
      permissions,
      active_schedule: true,
    };

    // console.log(formData);

    try {
      const response = await lawApi.post("/subusers", formData);
      const data = response.data;

      if (data.error) {
        const message =
          data.message ??
          "Ocorreu um erro ao criar o subusuário. Mas não se preocupe, não foi culpa sua.";
        // console.log(err.message);
        // console.log(err);
        toast.error(message, {
          duration: 5000,
        });
      } else {
        const message = data.message ?? "Usuário registrado com sucesso.";
        toast.success(message, {
          duration: 5000,
        });
      }
    } catch (err: any) {
      const message =
        err.message ??
        "Ocorreu um erro ao criar o subusuário. Mas não se preocupe, não foi culpa sua.";
      // console.log(err.message);
      // console.log(err);
      toast.error(message, {
        duration: 5000,
      });
    } finally {
      void fetchSubusers();
      setPermission([]);
      setDisableButton(false);
      handleCloseNewUserDialog();
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      // handleOpenSnackbar();
      reset({
        name: "",
        last_name: "",
        email: "",
        office: "",
      });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Dialog
      open={openNewUser}
      onClose={() => {
        handleCloseNewUserDialog();
        setPermission([]);
        reset();
      }}
      fullWidth={true}
      maxWidth="xl"
      fullScreen={!!matches}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          handleCloseNewUserDialog();
          setPermission([]);
          reset();
        }}
      >
        <MDBox display="flex" flexDirection="column" alignItems="center">
          <MDTypography
            sx={{ color: "#0B2F36", fontSize: matches ? "20px" : "24px" }}
          >
            Cadastrar sub usuário
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>
      <DialogContent sx={{ padding: "0px 50px 0 50px" }}>
        <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
          <MDBox
            sx={{
              height: "13px",
              width: "5px",
              backgroundColor: "#0B2F36",
              mr: 1,
            }}
          ></MDBox>
          <MDTypography
            variant="subtitle2"
            sx={{ color: "#0B2F36", fontWeight: 500 }}
          >
            Dados pessoais
          </MDTypography>
        </DialogContentText>
        <MDBox
          noValidate
          component="form"
          my={2}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Nome{" "}
                  <MDTypography
                    sx={{
                      fontSize: "12px",
                      color: "#f00",
                      display: "inline",
                    }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.name == null)}
                  {...register("name")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.name?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Sobrenome{" "}
                  <MDTypography
                    sx={{
                      fontSize: "12px",
                      color: "#f00",
                      display: "inline",
                    }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.last_name == null)}
                  {...register("last_name")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.last_name?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  E-mail{" "}
                  <MDTypography
                    sx={{
                      fontSize: "12px",
                      color: "#f00",
                      display: "inline",
                    }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.email == null)}
                  {...register("email")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.email?.message}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
          <MDBox
            sx={{
              height: "13px",
              width: "5px",
              backgroundColor: "#0B2F36",
              mr: 1,
            }}
          ></MDBox>
          <MDTypography
            variant="subtitle2"
            sx={{ color: "#0B2F36", fontWeight: 500 }}
          >
            Sobre
          </MDTypography>
        </DialogContentText>
        <MDBox
          noValidate
          component="form"
          my={2}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>
                  Cargo{" "}
                  <MDTypography
                    sx={{
                      fontSize: "12px",
                      color: "#f00",
                      display: "inline",
                    }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.office == null)}
                  {...register("office")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.office?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Cadastrado desde</InputLabel>
                <MDInput
                  disabled
                  // error={!(errors.created_at == null)}
                  // {...register("created_at", {
                  //   required: true,
                  // })}
                  value={today}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
              </MDBox>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Permissões</InputLabel>
                <MDBox display="flex">
                  <Grid container>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              handleChange("CLIENTS");
                            }}
                            sx={{
                              color: "#DDCA92",
                              "&.Mui-checked": {
                                color: "#DDCA92",
                              },
                            }}
                          />
                        }
                        label={
                          <MDTypography
                            sx={{
                              fontSize: "14px",
                              color: "#0B2F36",
                              fontWeight: 700,
                            }}
                          >
                            Clientes
                          </MDTypography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              handleChange("PROCESSES");
                            }}
                            sx={{
                              color: "#DDCA92",
                              "&.Mui-checked": {
                                color: "#DDCA92",
                              },
                            }}
                          />
                        }
                        label={
                          <MDTypography
                            sx={{
                              fontSize: "14px",
                              color: "#0B2F36",
                              fontWeight: 700,
                            }}
                          >
                            Processos
                          </MDTypography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              handleChange("SCHEDULES");
                            }}
                            sx={{
                              color: "#DDCA92",
                              "&.Mui-checked": {
                                color: "#DDCA92",
                              },
                            }}
                          />
                        }
                        label={
                          <MDTypography
                            sx={{
                              fontSize: "14px",
                              color: "#0B2F36",
                              fontWeight: 700,
                            }}
                          >
                            Agendamento
                          </MDTypography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              handleChange("SUBUSER");
                            }}
                            sx={{
                              color: "#DDCA92",
                              "&.Mui-checked": {
                                color: "#DDCA92",
                              },
                            }}
                          />
                        }
                        label={
                          <MDTypography
                            sx={{
                              fontSize: "14px",
                              color: "#0B2F36",
                              fontWeight: 700,
                            }}
                          >
                            Sub usuários
                          </MDTypography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              handleChange("FINANCIAL");
                            }}
                            sx={{
                              color: "#DDCA92",
                              "&.Mui-checked": {
                                color: "#DDCA92",
                              },
                            }}
                          />
                        }
                        label={
                          <MDTypography
                            sx={{
                              fontSize: "14px",
                              color: "#0B2F36",
                              fontWeight: 700,
                            }}
                          >
                            Fluxo de caixa
                          </MDTypography>
                        }
                      />
                    </Grid>
                    {/* <Grid item xs={8} sm={8} md={2} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#DDCA92",
                              "&.Mui-checked": {
                                color: "#DDCA92",
                              },
                            }}
                          />
                        }
                        label="Assistente jurídico"
                      />
                    </Grid> */}
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <MDButton
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          color="success"
          sx={{
            width: "130px",

            padding: "10px",
            textTransform: "capitalize",
            color: "#DDCA92",
            background:
              "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

            border: "1px solid #DDCA92",
            borderRadius: "10px",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#0B2F36",
              boxShadow: "none",
            },
          }}
        >
          Cadastrar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateSubuserDialog;
