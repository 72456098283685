/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from "react";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import { Autocomplete, InputLabel, TextField } from "@mui/material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { type SubmitHandler, useForm } from "react-hook-form";
import dataUF from "../../../../../brazilian-states.json";
import cep from "cep-promise";
import MDButton from "../../../../../components/MDButton";

interface IFormInputBusiness {
  cep: string;
  country: string;
  street: string;
  number: string | number;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  name_application: string;
}

function BusinessDetails({
  setData,
  handleNext,
  handleBack,
  register,
  handleSubmit,
  setValue,
  errors,
  watch,
}: any) {
  const [UF, setUF] = useState<{ nome: string; sigla: string }>();
  const defaultPropsUF = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.sigla,
  };

  const watchCep = watch("cep");

  useEffect(() => {
    void consultCEP();
  }, [watchCep]);

  const consultCEP = async () => {
    try {
      if (watchCep.length === 9) {
        const newCEPFormatted = watchCep.replace("-", "");

        const response = await cep(newCEPFormatted);

        if (response) {
          const { city, neighborhood, state, street } = response;
          setValue("city", city);
          setValue("neighborhood", neighborhood);
          const _uf = dataUF.UF.find((value) => value.sigla === state);
          setUF(_uf);

          setValue("state", state);
          setValue("street", street);
        }
      }
    } catch (error) { }
  };

  const checkBusinessDetails: SubmitHandler<IFormInputBusiness> = async (
    data,
  ) => {
    setData({ ...data });
    handleNext();
  };

  return (
    <MDBox component="form" role="form">
      <MDBox sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Nome do escritório{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.name_application == null)}
                {...register("name_application")}
                // mask={"99999-999"}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.name_application?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                País{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >

                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.country == null)}
                {...register("country")}
                value="Brasil"
                // mask={"99999-999"}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.country?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                CEP{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.cep == null)}
                {...register("cep")}
                mask={"99999-999"}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.cep?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Rua{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.street == null)}
                {...register("street")}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.street?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Número{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.number == null)}
                {...register("number")}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.number?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Bairro{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.neighborhood == null)}
                {...register("neighborhood")}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.neighborhood?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Cidade{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.city == null)}
                {...register("city")}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.city?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Estado{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>

              <Autocomplete
                disablePortal
                noOptionsText="Estado inexistente"
                size="small"
                {...defaultPropsUF}
                value={UF ?? null}
                onChange={(event: any, newValue: any) => {
                  setUF(newValue);

                  if (newValue) {
                    setValue("state", newValue.nome);
                  } else {
                    setValue("state", "");
                  }
                }}
                sx={{
                  "& input": { height: "27px" },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.state?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Complemento{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                ></MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.complement == null)}
                {...register("complement")}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.complement?.message}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "0 10px",
          pt: 2,
          mb: 2,
        }}
      >
        {" "}
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={4}
          mb={1}
        >
          <MDButton
            onClick={handleBack}
            variant="outlined"
            sx={{
              width: "130px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#0B2F36",
              border: "2px solid #DDCA92",
              borderRadius: "10px",

              "&:hover": {
                color: "#0B2F36",
                border: "2px solid #DDCA92",
              },
            }}
          >
            Voltar
          </MDButton>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={4}
          mb={1}
        >
          <MDButton
            variant="contained"
            onClick={handleSubmit(checkBusinessDetails)}
            // disabled={lockButton}
            sx={{
              width: "130px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#DDCA92",
              background:
                "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

              border: "1px solid #DDCA92",
              borderRadius: "10px",

              "&:hover": {
                background:
                  "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                color: "#DDCA92",
                border: "1px solid #DDCA92",
              },
            }}
          >
            Avançar
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default BusinessDetails;
