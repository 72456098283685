import React, { useState, useEffect } from "react";

import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import { Link, useNavigate, createSearchParams } from "react-router-dom";

// import { useAuth } from "../../../../../hooks/useAuth";
import useEffectOnce from "../../../../../hooks/useEffectOnce";

import { Grid, Icon } from "@mui/material";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import textHelp from "../../../../../utils/textHelp";
import toast from "react-hot-toast";

// import crypto from "crypto";

interface IUser {
  _id: string;
  auth_user_id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    permission: [] | null;
    role: string;
  };
  email: string;
  name: string;
  last_name: string;
  oab: string | null;
  office: string | null;
}

interface IApplication {
  name: string;
  plan_id: string;
  payday: number;
}

interface IPlan {
  active: boolean;
  benefits: object[] | null;
  code: string;
  name: string;
  price: number;
}

interface IAdminHeader {
  plan: IPlan;
  user: IUser;
  matches: boolean;
  application: IApplication;
  updatingProcess: boolean;
  handleUpdateProcess: () => void;
}

export default function AdminHeader({
  matches,
  user,
  application,
  plan,
  updatingProcess,
  handleUpdateProcess,
}: IAdminHeader) {
  const navigate = useNavigate();
  const [encryptedUserID, setEncryptedUserID] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  const encryptUserID = () => {
    const parse = CryptoJS.enc.Utf8.parse(`${user._id}+${user.name}`);
    const encrypted = CryptoJS.enc.Base64url.stringify(parse);

    setEncryptedUserID(encrypted);

    const _url = process.env.REACT_APP_LAW_URL;
    setUrl(_url ?? "");
  };

  const navigateExternal = () => {
    navigate({
      pathname: "/agendamento",
      search: createSearchParams({
        id: encryptedUserID,
      }).toString(),
    });

    // secureLocalStorage.setItem("id_professional", user._id);
  };

  useEffect(() => {
    encryptUserID();
  }, [user]);

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={12} md={4} lg={4}>
        {matches ? (
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-end"
            mt={matches ? 2 : 0}
            mb={matches ? 2 : 0}
          >
            <MDTypography
              sx={{ fontSize: "20px", color: "#fff", fontWeight: 700 }}
            >
              {application.name}
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <MDTypography
              sx={{
                fontSize: "20px",
                color: "#fff",
                fontWeight: 700,
                mb: "auto",
              }}
            >
              {application.name}
            </MDTypography>
            <MDBox sx={{ height: "20px" }}></MDBox>
          </MDBox>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems={matches ? "center" : ""}
          gap="5px 0"
        >
          <MDTypography
            sx={{ color: "#fff", fontWeight: 500, fontSize: "14px" }}
          >
            Link de agendamento
            <Icon fontSize="small" sx={{ fontSize: 15, marginLeft: 2 }} style={{ cursor: "pointer" }} onClick={() => {
              const isCopied = textHelp.copyText(`${window.location.protocol}//${window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname}/agendamento?id=${encryptedUserID}`)
              if (isCopied) {
                toast.success(`Link copiado com sucesso`, {
                  duration: 5000,
                });
                return
              }

              toast.error(`Erro ao copiar link`, {
                duration: 5000,
              });

            }}>
              content_copy
            </Icon>
          </MDTypography>
          <MDBox display="center">
            {user.active_schedule ? (
              <a href={`${window.location.protocol}//${window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname}/agendamento?id=${encryptedUserID}`} target="_blank" rel="noopener noreferrer">
                <MDTypography
                  // component={Link}
                  // to={`/agendamento?professional_id=${encryptedUserID}`}
                  // onClick={navigateExternal}
                  sx={{
                    color: "#DDCA92",
                    fontWeight: "regular",
                    fontSize: "14px",
                    // opacity: "50%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: 'center',
                    wordBreak: "break-all",
                  }}
                >
                  {`${window.location.protocol}//${window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname}/agendamento?id=${encryptedUserID}`}
                  <Icon fontSize="small" sx={{ fontSize: 15, marginLeft: 2 }}>
                    open_in_new
                  </Icon>
                </MDTypography>
              </a>
            ) : (
              <MDTypography
                sx={{
                  color: "#DDCA92",
                  fontWeight: "regular",
                  fontSize: "14px",
                  // opacity: "50%",
                  cursor: "pointer",
                }}
              >
                Link indisponível. Preencha seus dados!
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
      </Grid>

      <Grid item lg={12} mt="40px">
        <MDBox></MDBox>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems={matches ? "center" : ""}
          justifyContent="flex-start"
          gap="6px 0"
          sx={{
            mt: matches ? "16px" : "0px",
            mb: matches ? "16px" : "0px",
          }}
        >
          <MDBox display="flex" gap="0 5px">
            <MDTypography
              sx={{ color: "#fff", fontWeight: "regular", fontSize: "14px" }}
            >
              Tipo de usuário:
            </MDTypography>
            <MDTypography
              sx={{ color: "#fff", fontWeight: 700, fontSize: "14px" }}
            >
              {user.application.role === "admin" ? "Administrador" : "Usuário"}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <MDTypography
              sx={{
                color: "#FFFFFF",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {user.active_schedule
                ? "Agendamento ativo"
                : "Agendamento inativo"}
            </MDTypography>
            <MDBox
              sx={{
                width: 10,
                height: 10,
                borderRadius: 50,
                backgroundColor: user.active_schedule ? "#27FF23" : "#6B1111",
                ml: 1,
              }}
            ></MDBox>
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems={matches ? "center" : ""}
        >
          <MDTypography
            sx={{ color: "#fff", fontWeight: 500, fontSize: "14px" }}
          >
            Plano atual
          </MDTypography>
          <MDTypography
            sx={{
              color: "#DDCA92",
              fontWeight: 500,
              fontSize: "14px",
              opacity: "50%",
            }}
          >
            {plan.name}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems={matches ? "center" : ""}
        // sx={{ mt: !matches ? "15px" : "0" }}
        >
          <MDTypography
            sx={{ color: "#fff", fontWeight: 500, fontSize: "14px" }}
          >
            Vencimento do plano:
          </MDTypography>
          <MDTypography
            sx={{
              color: "#DDCA92",
              fontWeight: 500,
              fontSize: "14px",
              opacity: "50%",
            }}
          >
            {application.payday}/{dayjs().month() + 1}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <MDBox
          display="flex"
          justifyContent={matches ? "center" : ""}
          mt={matches ? 2 : 0}
        >
          <MDButton
            onClick={handleUpdateProcess}
            variant="contained"
            sx={{
              color: "#000",
              textTransform: "capitalize",
              backgroundColor: updatingProcess ? "#f00" : "#fff",
              "&:hover": {
                backgroundColor: updatingProcess ? "#f00" : "#fff",
                color: updatingProcess ? "#fff" : "#000",
              },
              "&:active": {
                backgroundColor: updatingProcess ? "#f00" : "#fff",
              },
            }}
          >
            {updatingProcess ? "Cancelar Edição" : "Editar Perfil"}
          </MDButton>
        </MDBox>
      </Grid>
    </Grid >
  );
}
