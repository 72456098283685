/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from "react";

import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";

import { Link } from "react-router-dom";

// @mui material components
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 Components
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

import { api, lawApi } from "../../../../../services/api";
import { Autocomplete, TextField } from "@mui/material";
import cep from "cep-promise";
import dataUF from "../../../../../brazilian-states.json";
import toast from "react-hot-toast";
import CancelPlanDialog from "../../dialogs/ConfirmCancelPlan";

interface IUser {
  // [x: string]: string;
  _id: string;
  auth_user_id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    permission: [] | null;
    role: string;
  };
  cpf: string;
  birth_date: string;
  social_status: string;
  identity: string;
  email: string;
  name: string;
  last_name: string;
  oab: string | null;
  office: string | null;
}

interface IAddress {
  cep: string;
  city: string;
  complement: string;
  created_at: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  updated_at: string;
  _id: string;
}

interface IApplication {
  _id: string;
  name: string;
  plan_id: string;
  code: string;
  address_id: string;
  assinatura_pagBank_id: string | null;
}

interface IPlan {
  active: boolean;
  benefits: object[] | null;
  code: string;
  name: string;
  price: number;
}

interface IInputs {
  // active_schedule: boolean;
  email: string;
  name: string;
  last_name: string;
  oab: string;
  office: string;
  cpf: string;
  birth_date: string;
  social_status: string;
  identity: string;
  cep: string;
  city: string;
  complement: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  // plan: string;
  // valor: number;
  password: string | null;
  c_password: string | null;
}

interface IInputsApplication {
  cep: string;
  city: string;
  complement: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  email: yup
    .string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "E-mail inválido")
    .required("Informe o e-mail do cliente"),
  name: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .max(50, "No máximo 50 caracteres")
    .required("Informe o nome do cliente"),
  last_name: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .max(50, "No máximo 50 caracteres")
    .required("Informe o nome do cliente"),
  cpf: yup.string().required("CPF não pode ser vazio"),
  // .matches(
  //   /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
  //   "Informe o CPF no formato 123.456.789-00",
  // ),
  birth_date: yup.string().nullable().required("Data inválida"),
  social_status: yup.string(),
  identity: yup
    .string()
    .max(20, "No máximo 20 caracteres"),
  cep: yup.string(),
  // .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP inválido"),
  street: yup.string(),
  number: yup
    .string(),
  neighborhood: yup.string(),
  city: yup.string(),
  state: yup.string(),
  oab: yup.string().nullable().default(""),
  office: yup.string().nullable().default(""),
  complement: yup.string().default(""),
  password: yup.string().nullable().default(""),
  // .required("Informe a senha")
  // .min(8, "Sua senha deve ter no mínimo 8 caracteres"),
  c_password: yup
    .string()
    .nullable()
    .default("")
    // .required("Digite sua senha novamente")
    // .min(8, "Sua senha deve ter no mínimo 8 caracteres")
    .oneOf([yup.ref("password")], "Senhas não são iguais"),
});

const schemaApplication: yup.ObjectSchema<IInputsApplication> = yup.object({
  cep: yup.string().required("Informe o CEP"),
  // .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP inválido"),
  street: yup.string().required("Informe a rua"),
  number: yup
    .string()
    .matches(/(\d+)| /g, "Número inválido")
    .required("Informe o número da casa ou apt.")
    .min(1, "Número inválido"),
  neighborhood: yup.string().required("Informe o bairro"),
  city: yup.string().required("Informe a cidade"),
  state: yup.string().required("Informe o estado"),
  complement: yup.string().default(""),
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IAdminForm {
  matches: boolean;
  plan: IPlan;
  user: IUser;
  address: IAddress | null;
  addressApplication: IAddress | null;
  updatingProcess: boolean;
  handleUpdate: () => void;
  application: IApplication;
  fetchPersonalData: () => Promise<void>;
  toastFeedbackMessage: (type: string, message: string) => any;
  handleDeactivateAccount: (code: string) => void;
}

export default function AdminForm({
  plan,
  user,
  matches,
  address,
  addressApplication,
  updatingProcess,
  fetchPersonalData,
  handleUpdate,
  application,
  toastFeedbackMessage,
  handleDeactivateAccount,
}: IAdminForm) {
  // const { checkLogin } = useAuth();
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });

  const {
    reset: resetApplication,
    register: registerApplication,
    handleSubmit: handleSubmitApplication,
    setValue: setValueApplication,
    watch: watchApplication,
    formState: {
      errors: errorsApplication,
      isSubmitSuccessful: isSubmitSuccessfulApplication,
    },
  } = useForm<IInputsApplication>({
    resolver: yupResolver(schemaApplication),
  });

  const defaultPropsMaritalStatus = {
    options: [
      { value: "solteiro", label: "Solteiro" },
      { value: "casado", label: "Casado" },
      { value: "separado", label: "Separado" },
      { value: "divorciado", label: "Divorciado" },
      { value: "viúvo", label: "Viúvo" },
    ],
    getOptionLabel: (option: { value: string; label: string }) => option.label,
  };
  const defaultPropsUF = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.sigla,
  };
  const [maritalStatus, setMaritalStatus] =
    useState<{ value: string; label: string }>();
  const [value, setTabValue] = useState(0);
  const [activeSchedule, setActiveSchedule] = useState<boolean>(false);
  // const [updatingProcess, setUpdatingProcess] = useState<boolean>(false);
  const [state, setState] = useState<{ nome: string; sigla: string }>();
  const [stateApplication, setStateApplication] =
    useState<{ nome: string; sigla: string }>();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const watchCep = watch("cep");
  const watchCepApplication = watchApplication("cep");

  const cancelPlan = async () => {
    try {
      await api.put(`/payments/cancel`);

      const formData = {
        plan_id: "661ac466bc72ab4fcd13515c",
      };

      const response = await api.put("/payments/", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        toast.success("Plano cancelado com sucesso!", {
          duration: 5000,
        });
        void fetchPersonalData();
        setOpenModal(false);
        // navigate("/updated-p");
      }
    } catch (err: any) {
      const code = err.response.data.statusCode;
      const message = err.response.data.message;
      if (code === 400) {
        toast.error(message, {
          duration: 5000,
        });
      } else if (code === 404) {
        toast.error("Ocorreu um erro ao logar. Tente novamente mais tarde!", {
          duration: 5000,
        });
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro ao logar. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      } else {
        toast.error("Ocorreu um erro ao logar. Verifique as credenciais!", {
          duration: 5000,
        });
      }
    }
  };

  useEffect(() => {
    void consultCEP();
  }, [watchCep]);

  useEffect(() => {
    void consultCEPApplication();
  }, [watchCepApplication]);

  const consultCEP = async () => {
    try {
      if (watchCep.length === 9) {
        const newCEPFormatted = watchCep.replace("-", "");

        const response = await cep(newCEPFormatted);

        if (response) {
          const { city, neighborhood, state, street } = response;
          setValue("city", city);
          setValue("neighborhood", neighborhood);
          setValue("state", state);
          setValue("street", street);
        }
      }
    } catch (error) { }
  };

  const consultCEPApplication = async () => {
    try {
      if (watchCepApplication.length === 9) {
        const newCEPFormatted = watchCep.replace("-", "");

        const response = await cep(newCEPFormatted);

        if (response) {
          const { city, neighborhood, state, street } = response;
          setValueApplication("city", city);
          setValueApplication("neighborhood", neighborhood);
          setValueApplication("state", state);
          setValueApplication("street", street);
        }
      }
    } catch (error) { }
  };
  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    // setDisableButton(true);

    let userFormData: any;

    if (
      data.password != null ||
      data.password !== "" ||
      data.password !== undefined
    ) {
      userFormData = {
        name: data.name,
        last_name: data.last_name,
        cpf: data.cpf,
        birth_date: data.birth_date,
        social_status: maritalStatus?.label,
        identity: data.identity,
        oab: data.oab,
        office: data.office,
        active_schedule: activeSchedule,
      };
    } else {
      userFormData = {
        name: data.name,
        last_name: data.last_name,
        cpf: data.cpf,
        birth_date: data.birth_date,
        social_status: maritalStatus?.label,
        identity: data.identity,
        oab: data.oab,
        office: data.office,
        password: data.password,
        active_schedule: activeSchedule,
      };
    }


    const addressFormData = {
      cep: data.cep,
      street: data.street,
      number: `${data.number}`,
      neighborhood: data.neighborhood,
      city: data.city,
      state: state?.sigla,
      complement: data.complement ?? "",
    };
    console.log(userFormData)
    console.log(addressFormData)
    // console.log(data);

    try {
      if (user?.address_id != null) {
        const [firstResponse, secondResponse] = await Promise.all([
          lawApi.put(`/subusers/${user?._id}`, userFormData),
          lawApi.put(`/addresses/${user?.address_id}`, addressFormData),
        ]);
        if (firstResponse && secondResponse) {
          toastFeedbackMessage("succes", "Dados atualizados com sucesso.");
        }
        console.log(firstResponse, secondResponse)
      } else {
        userFormData = {
          ...userFormData,

        };
        if (data.cep) {
          const addressResponse = await lawApi.post(
            `/addresses`,
            addressFormData,
          );

          userFormData.address_id = addressResponse.data._id
          console.log(123, { addressFormData })
          if (!addressFormData) {
            toastFeedbackMessage("error", "Falha ao atualizar endereço. Contate o suporte");
            return
          }

        }


        const userResponse = await lawApi.put(
          `/subusers/${user?._id}`,
          userFormData,
        );
        console.log(123, { userResponse })

        if (userResponse) {
          toastFeedbackMessage("succes", "Dados atualizados com sucesso.");
          fetchPersonalData()
        }
      }
    } catch (err: any) {
      const code = err.response.data.statusCode;
      console.log(err)
      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Verifique os dados informados!",
        );
      }
    } finally {
      void fetchPersonalData();
      handleUpdate();
    }
  };

  const onSubmitAddressApplication: SubmitHandler<IInputsApplication> = async (
    data,
  ) => {
    // setDisableButton(true);

    const addressFormData = {
      cep: data.cep,
      street: data.street,
      number: `${data.number}`,
      neighborhood: data.neighborhood,
      city: data.city,
      state: stateApplication?.sigla,
      complement: data.complement ?? "",
    };

    // console.log(data);

    try {
      const [firstResponse] = await Promise.all([
        lawApi.put(`/addresses/${application?.address_id}`, addressFormData),
      ]);
      if (firstResponse) {
        toastFeedbackMessage("succes", "Dados atualizados com sucesso.");
      }
    } catch (err: any) {
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Verifique os dados informados!",
        );
      }
    } finally {
      void fetchPersonalData();
      handleUpdate();
    }
  };

  const handleChangeActivateSchedule = () => {
    setActiveSchedule(!activeSchedule);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setTabValue(newValue);
  };

  const handleUpdateProcess = () => {
    const userFields = [
      "cpf",
      "birth_date",
      "social_status",
      "identity",
      "email",
      "name",
      "last_name",
      "oab",
      "office",
      "cep",
      "complement",
      "neighborhood",
      "number",
      "state",
      "street",
    ];

    const addressFields = [
      "cep",
      "city",
      "complement",
      "neighborhood",
      "number",
      "state",
      "street",
    ];

    userFields.forEach((field: string) => {
      if (field === "birth_date" && user[field] !== undefined) {
        const date = dayjs(user[field])
          .format("DD/MM/YYYY")
          .split("/")
          .reverse()
          .join("-");
        setValue(field, date);
      } else if (field === "social_status" && user) {
        const optionsSocialStatus = [
          { value: "solteiro", label: "Solteiro" },
          { value: "casado", label: "Casado" },
          { value: "separado", label: "Separado" },
          { value: "divorciado", label: "Divorciado" },
          { value: "viúvo", label: "Viúvo" },
        ];

        const _marital = optionsSocialStatus.find(
          (value) => value.value === user[field],
        );

        if (_marital) {
          setMaritalStatus(_marital);
        }
      } else {
        // @ts-expect-error
        setValue(field, user[field]);
      }
    });

    addressFields.forEach((field: string) => {
      // @ts-expect-error
      setValue(field, address[field]);
      // @ts-expect-error
      setValueApplication(field, addressApplication[field]);

      if (field === "state" && address) {
        const _state = dataUF.UF.find(
          (value) => value.sigla === address[field],
        );

        if (_state) {
          setState(_state);
        }
      }

      if (field === "state" && addressApplication) {
        const _state = dataUF.UF.find(
          (value) => value.sigla === addressApplication[field],
        );

        if (_state) {
          setStateApplication(_state);
        }
      }
    });

    setActiveSchedule(user?.active_schedule);
  };

  useEffect(() => {
    if (updatingProcess) {
      handleUpdateProcess();
    } else {
      reset();
    }
  }, [updatingProcess, reset]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email: "",
        name: "",
        last_name: "",
        oab: "",
        office: "",
        cpf: "",
        birth_date: "",
        social_status: "",
        identity: "",
        cep: "",
        city: "",
        complement: "",
        neighborhood: "",
        number: "",
        state: "",
        street: "",
        // plan: "",
        // valor: 0,
        password: "",
        c_password: "",
      });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <MDBox
      sx={{
        border: "2px solid #0B2F36",
        borderRadius: "10px",
        backgroundColor: "#F6F6F6",
      }}
    >
      <MDBox display="flex" justifyContent="space-between" ml={6} mt={3} mb={1}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Dados pessoais"
            {...a11yProps(0)}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#0B2F36",
              textTransform: "capitalize",
            }}
          />
          <Tab
            label="Dados do escritório"
            {...a11yProps(1)}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#0B2F36",
              textTransform: "capitalize",
            }}
          />
          <Tab
            label="Conta"
            {...a11yProps(2)}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#0B2F36",
              textTransform: "capitalize",
            }}
          />
        </Tabs>
      </MDBox>
      <TabPanel value={value} index={0}>
        <MDBox mx={3}>
          {/* <DialogContentText>Dados pessoais</DialogContentText> */}
          <MDBox
            noValidate
            // component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Nome</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.name == null)}
                      {...register("name")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={user?.name}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.name?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Sobrenome</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.last_name == null)}
                      {...register("last_name")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={user?.last_name}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.last_name?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>CPF</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.cpf == null)}
                      {...register("cpf")}
                      mask={"999.999.999-99"}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={user?.cpf}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.cpf?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Data nascimento</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      type="date"
                      error={!(errors.birth_date == null)}
                      {...register("birth_date")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={dayjs(user?.birth_date).format("DD/MM/YYYY")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.birth_date?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Status social</InputLabel>
                  {updatingProcess ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      noOptionsText="Sem opções"
                      {...defaultPropsMaritalStatus}
                      value={maritalStatus ?? null}
                      // defaultValue={client}
                      onChange={(event: any, newValue: any) => {
                        setMaritalStatus(newValue);

                        newValue
                          ? setValue("social_status", newValue.label)
                          : setValue("social_status", "");
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={user?.social_status}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.social_status?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Identidade</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.identity == null)}
                      {...register("identity")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={user?.identity}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.identity?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>OAB</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.oab == null)}
                      {...register("oab")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={user?.oab}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.oab?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Cargo</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.office == null)}
                      {...register("office")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={user?.office}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.office?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <DialogContentText>Endereço</DialogContentText>
          <MDBox
            noValidate
            component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>CEP</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.cep == null)}
                      {...register("cep")}
                      mask={"99999-999"}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={address?.cep}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.cep?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Rua</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.street == null)}
                      {...register("street")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={address?.street}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.street?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Número</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.number == null)}
                      {...register("number")}
                      type="number"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={address?.number}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.number?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Bairro</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.neighborhood == null)}
                      {...register("neighborhood")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={address?.neighborhood}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.neighborhood?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Cidade</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.city == null)}
                      {...register("city")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={address?.city}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.city?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Estado</InputLabel>
                  {updatingProcess ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      {...defaultPropsUF}
                      value={state ?? null}
                      noOptionsText="Estado inexistente"
                      // defaultValue={client}
                      onChange={(event: any, newValue: any) => {
                        setState(newValue);
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={address?.state}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.state?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Complemento</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.complement == null)}
                      {...register("complement")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={address?.complement}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.complement?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="center" m={2} mb={3}>
          {updatingProcess && (
            <MDButton
              onClick={handleSubmit(onSubmit)}
              sx={{
                color: "#000",
                textTransform: "capitalize",
              }}
            >
              Confirmar edição
            </MDButton>
          )}
        </MDBox>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MDBox mx={3}>
          {/* <DialogContentText>Dados pessoais</DialogContentText> */}
          <MDBox
            noValidate
            // component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}></Grid>
          </MDBox>
          <DialogContentText>Endereço</DialogContentText>
          <MDBox
            noValidate
            component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>CEP</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errorsApplication.cep == null)}
                      {...registerApplication("cep")}
                      mask={"99999-999"}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={addressApplication?.cep}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errorsApplication.cep?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Rua</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errorsApplication.street == null)}
                      {...registerApplication("street")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={addressApplication?.street}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errorsApplication.street?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Número</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errorsApplication.number == null)}
                      {...registerApplication("number")}
                      type="number"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={addressApplication?.number}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errorsApplication.number?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Bairro</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errorsApplication.neighborhood == null)}
                      {...registerApplication("neighborhood")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={addressApplication?.neighborhood}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errorsApplication.neighborhood?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Cidade</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errorsApplication.city == null)}
                      {...registerApplication("city")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={addressApplication?.city}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errorsApplication.city?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Estado</InputLabel>
                  {updatingProcess ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      {...defaultPropsUF}
                      value={state ?? null}
                      noOptionsText="Estado inexistente"
                      // defaultValue={client}
                      onChange={(event: any, newValue: any) => {
                        setState(newValue);
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={addressApplication?.state}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errorsApplication.state?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Complemento</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errorsApplication.complement == null)}
                      {...registerApplication("complement")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      placeholder={addressApplication?.complement}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errorsApplication.complement?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>

        <MDBox display="flex" justifyContent="center" m={2} mb={3}>
          {updatingProcess && (
            <MDButton
              onClick={handleSubmitApplication(onSubmitAddressApplication)}
              sx={{
                color: "#000",
                textTransform: "capitalize",
              }}
            >
              Confirmar edição
            </MDButton>
          )}
        </MDBox>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MDBox mx={3}>
          <MDBox
            noValidate
            // component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDBox display="flex" alignItems="flex-end">
                  <MDBox display="flex" flexDirection="column">
                    <InputLabel sx={{ mb: 1 }}>Plano</InputLabel>
                    <MDInput
                      disabled
                      placeholder={plan?.name}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  </MDBox>
                  {!updatingProcess ? (
                    <>
                      <MDButton
                        variant="text"
                        color="info"
                        component={Link}
                        target="_blank"
                        to={`/plan-update/${application?._id}`}
                      >
                        Realize o upgrade
                      </MDButton>
                      {application.assinatura_pagBank_id && (
                        <MDButton
                          variant="text"
                          color="warning"
                          component={Link}
                          target="_blank"
                          to={`/card-update`}
                        >
                          Atualizar dados do cartão
                        </MDButton>
                      )}
                      {application.assinatura_pagBank_id && (
                        <MDButton
                          variant="text"
                          color="error"
                          onClick={() => {
                            setOpenModal(true);
                          }}
                        >
                          Ir para o plano Gratuito
                        </MDButton>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Valor</InputLabel>
                  <MDInput
                    disabled
                    placeholder={`R$ ${plan?.price},00`}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                </MDBox>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Cobrança</InputLabel>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "43px",
                      backgroundColor: "transparent !important",
                      border: "1px solid #DDCA92",
                      boxShadow: "none !important",
                    }}
                  >
                    <InputBase
                      sx={{
                        ml: 1,
                        flex: 1,
                        "& ::placeholder": { fontSize: "14px", mx: 1 },
                        "&": { fontSize: "14px" },
                      }}
                      disabled
                      placeholder="Número"
                      // value={userAddress?.number}
                      fullWidth
                    />
                  </Paper>
                </MDBox>
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Senha atual</InputLabel>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "43px",
                      backgroundColor: "transparent !important",
                      border: "1px solid #DDCA92",
                      boxShadow: "none !important",
                    }}
                  >
                    <InputBase
                      sx={{
                        ml: 1,
                        flex: 1,
                        "& ::placeholder": { fontSize: "14px", mx: 1 },
                        "&": { fontSize: "14px" },
                      }}
                      disabled
                      placeholder="Bairro"
                      // value={userAddress?.neighborhood}
                      fullWidth
                    />
                  </Paper>
                </MDBox>
              </Grid> */}
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Nova senha</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.password == null)}
                      {...register("password")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Confirmar senha</InputLabel>
                  {updatingProcess ? (
                    <MDInput
                      error={!(errors.c_password == null)}
                      {...register("c_password")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>

        <MDBox display="flex" justifyContent="center" m={2} mb={3}>
          {updatingProcess && (
            <MDButton
              onClick={handleSubmit(onSubmit)}
              sx={{
                color: "#000",
                textTransform: "capitalize",
              }}
            >
              Confirmar edição
            </MDButton>
          )}
        </MDBox>
      </TabPanel>
      <MDBox
        display="flex"
        flexDirection={matches ? "column" : "row"}
        justifyContent={matches ? "" : "space-between"}
        alignItems={matches ? "flex-start" : ""}
        mx={6}
        mb={3}
      >
        {updatingProcess ? (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeActivateSchedule}
                checked={activeSchedule}
                sx={{
                  color: "#DDCA92",
                  "&.Mui-checked": {
                    color: "#DDCA92",
                  },
                }}
              />
            }
            label={
              <MDTypography sx={{ fontSize: matches ? "12px" : "14px" }}>
                Profissional (agendamento)
              </MDTypography>
            }
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                disabled
                checked={user?.active_schedule}
                sx={{
                  color: "#DDCA92",
                  "&.Mui-checked": {
                    color: "#DDCA92",
                  },
                }}
              />
            }
            label={
              <MDTypography sx={{ fontSize: matches ? "12px" : "14px" }}>
                Profissional (agendamento)
              </MDTypography>
            }
          />
        )}
        <MDButton
          disabled={updatingProcess}
          onClick={() => {
            handleDeactivateAccount(application?.code);
          }}
          variant="text"
          sx={{ color: "#f00", "&:hover": { color: "#f00" } }}
        >
          Desativar conta
        </MDButton>
      </MDBox>
      <CancelPlanDialog
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        handleCancelPlan={cancelPlan}
        handleCloseModalDialog={closeModal}
        openModal={openModal}
      />
    </MDBox>
  );
}
