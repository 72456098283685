import { useCallback, useEffect, useState } from "react";

// @mui material components
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

// Dynamic styles
import { styles } from "./styles/styles";

//
interface IListFeature {
  plan: {
    _id: string;
    benefits: object[];
    code: string;
    description: string;
    name: string;
    number_users: number;
    plan_pagBank_id: string;
    platform_id: string;
    price: number;
    created_at: string;
    update_at: string;
    active: boolean;
    __v: number;
  };
  index: number;
  setSubscription: React.Dispatch<React.SetStateAction<number>>;
  subscription: number;
  setPlanID: React.Dispatch<React.SetStateAction<string>>;
  // setPlatformID: React.Dispatch<React.SetStateAction<string>>;
  getPlatformCode: GetPlatformCode;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setLockButton: React.Dispatch<React.SetStateAction<boolean>>;
  coupon: {
    type: "percent" | "value",
    value: number
  }
}

type GetPlatformCode = (id: string) => void;

interface IStyles {
  backgroundChip: string;
  backgroundCard: string;
  chipTextColor: string;
  selected: string;
  textColor: string;
}

function SubscriptionPlans({
  plan,
  index,
  setSubscription,
  subscription,
  setPlanID,
  // setPlatformID,
  getPlatformCode,
  setAmount,
  setLockButton,
  coupon
}: IListFeature) {
  const [theme, setTheme] = useState<IStyles>();

  const handleTheme = useCallback(
    (styles: IStyles[]) => {
      if (subscription === index) {
        setTheme(styles[0]);
      } else {
        setTheme(styles[1]);
      }
    },
    [index, subscription],
  );

  const benefitText = (benefit: Array<string | number>) => {
    switch (benefit[0]) {
      case "client": {
        const text = `${benefit[1] != null ? benefit[1] : "Ilimitados"
          } clientes`;
        return (
          <ListItemText
            disableTypography
            primary={
              <MDTypography variant="body2" sx={{ color: theme?.textColor }}>
                <Icon
                  sx={{
                    color: theme?.textColor,
                    mr: 1,
                    fontSize: "8px",
                  }}
                >
                  check_icon
                </Icon>
                {text}
              </MDTypography>
            }
          />
        );
      }
      case "process": {
        const text = `${benefit[1] != null ? benefit[1] : "Ilimitados"
          } processos`;
        return (
          <ListItemText
            disableTypography
            primary={
              <MDTypography variant="body2" sx={{ color: theme?.textColor }}>
                <Icon
                  sx={{
                    color: theme?.textColor,
                    mr: 1,
                    fontSize: "8px",
                  }}
                >
                  check_icon
                </Icon>
                {text}
              </MDTypography>
            }
          />
        );
      }
      case "schedules": {
        const text = `${benefit[1] != null ? benefit[1] : "Ilimitados"
          } agendamentos`;
        return (
          <ListItemText
            disableTypography
            primary={
              <MDTypography variant="body2" sx={{ color: theme?.textColor }}>
                <Icon
                  sx={{
                    color: theme?.textColor,
                    mr: 1,
                    fontSize: "8px",
                  }}
                >
                  check_icon
                </Icon>
                {text}
              </MDTypography>
            }
          />
        );
      }
      case "financial_record": {
        const text = `${benefit[1] != null ? benefit[1] : "Ilimitados"
          } registros financeiros`;
        return (
          <ListItemText
            disableTypography
            primary={
              <MDTypography variant="body2" sx={{ color: theme?.textColor }}>
                <Icon
                  sx={{
                    color: theme?.textColor,
                    mr: 1,
                    fontSize: "8px",
                  }}
                >
                  check_icon
                </Icon>
                {text}
              </MDTypography>
            }
          />
        );
      }
      case "maximum_users": {
        const text = `${benefit[1] != null ? benefit[1] : "Ilimitados"
          } usuário(s)`;
        return (
          <ListItemText
            disableTypography
            primary={
              <MDTypography variant="body2" sx={{ color: theme?.textColor }}>
                <Icon
                  sx={{
                    color: theme?.textColor,
                    mr: 1,
                    fontSize: "8px",
                  }}
                >
                  check_icon
                </Icon>
                {text}
              </MDTypography>
            }
          />
        );
      }
      default:
        // console.log("Índice inválido");
        break;
    }
  };

  useEffect(() => {
    handleTheme(styles);
  }, [handleTheme]);

  return (
    <Grid
      item
      // xs={12}
      // sm={12}
      // md={6}
      // lg={4}
      sx={{
        // marginBottom: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        onClick={() => {
          setSubscription(index);
          setPlanID(plan._id);
          setAmount(plan.price);
          getPlatformCode(plan.platform_id);
          setLockButton(false);
        }}
        elevation={3}
        sx={{
          borderRadius: "20px",
          height: "303px",
          // width: "100%",
          width: "266px",
          cursor: "pointer",
          backgroundColor: theme?.backgroundCard,
          mb: 2,
          boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <MDBox>
          <MDBox display="flex" justifyContent="center">
            <Chip
              label={plan?.name}
              size="medium"
              sx={{
                backgroundColor: theme?.backgroundChip,
                borderRadius: "20px",
                color: theme?.chipTextColor,
                marginTop: "0.3em",
                width: "95%",
                height: "45px",
                fontSize: "25px",
              }}
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            py={2}
          >
            {console.log(coupon)}
            {
              coupon ?
                <>
                  <MDTypography
                    variant="span"
                    sx={{ color: theme?.textColor, fontSize: "12px" }}
                  >
                    De R$ {plan?.price}/mês
                  </MDTypography>

                  <MDTypography
                    variant="h2"
                    sx={{ color: theme?.textColor, fontSize: "30px" }}
                  >
                    Por R$ {plan?.price - (plan?.price * (coupon.value / 100))}/
                    <MDTypography
                      variant="span"
                      sx={{ color: theme?.textColor, fontSize: "15px" }}
                    >
                      mês
                    </MDTypography>
                  </MDTypography>
                </>
                : <MDTypography
                  variant="h2"
                  sx={{ color: theme?.textColor, fontSize: "30px" }}
                >
                  Por R$ {plan?.price}/
                  <MDTypography
                    variant="span"
                    sx={{ color: theme?.textColor, fontSize: "15px" }}
                  >
                    mês
                  </MDTypography>
                </MDTypography>
            }
            <MDTypography
              variant="body1"
              sx={{
                fontSize: "12px",
                // textAlign: "center",
                mt: 1,
                color: theme?.textColor,
                maxWidth: "90%",
              }}
            >
              {plan?.description}
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            height="100%"
            maxHeight="100%"
            mb={5}
            ml={2}
          >
            <List>
              {Object.entries(plan?.benefits[0]).map((item, index) => (
                <ListItem key={index}>{benefitText(item)}</ListItem>
              ))}
            </List>
          </MDBox>
        </MDBox>
      </Paper>
    </Grid>
  );
}

export default SubscriptionPlans;
