import React from "react";
import "./index.css"
import { CircularProgress } from "@mui/material";
import logo from "../../assets/images/logo_law_profile_2.svg";
const ScreenLoading = () => {
  return (
    <div
      className="container-loading"
    >

      <img src={logo} alt="logo do LAW" />
      <br />
      <CircularProgress color="primary" />


    </div>
  );
};

export default ScreenLoading;
