import * as React from "react";

import { lawApi } from "../../../../services/api";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";

interface IDeleteUserDialog {
  openDeleteRecordDialog: boolean;
  recordToDelete: any;
  handleCloseDeleteRecordDialog: () => void;
  handleCloseForms: () => void;
  fetchRecords: () => Promise<void>;
  toastFeedbackMessage: (type: string, message: string) => any;
}

export default function DeleteRecordDialog({
  openDeleteRecordDialog,
  recordToDelete,
  handleCloseDeleteRecordDialog,
  handleCloseForms,
  fetchRecords,
  toastFeedbackMessage,
}: IDeleteUserDialog) {
  const handleDeleteProcess = async () => {
    try {
      const response = await lawApi.delete(`/financial/${recordToDelete?._id}`);
      const data = response.data;

      // console.log(data);
      if (data) {
        toastFeedbackMessage("success", "Registro deletado com sucesso!");
      }
    } catch (err: any) {
      // console.log(err.message);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do registro. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do registro. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do registro. Verifique os dados informados!",
        );
      }
    } finally {
      void fetchRecords();
      handleCloseDeleteRecordDialog();
      handleCloseForms();
    }
  };

  return (
    <Dialog
      open={openDeleteRecordDialog}
      onClose={handleCloseDeleteRecordDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ padding: "40px 50px 30px 50px", backgroundColor: "#0B2F36" }}
      >
        <DialogContentText sx={{ textAlign: "center" }}>
          <MDTypography sx={{ color: "#fff" }}>
            Tem certeza que deseja excluir a{" "}
            {recordToDelete?.type.toUpperCase()} no valor de R$
            {recordToDelete?.value}?
          </MDTypography>
          {/* <MDTypography sx={{ fontWeight: 700, color: "#fff" }}>
            {recordToDelete?._id}
          </MDTypography> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseDeleteRecordDialog}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Não
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleDeleteProcess}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Confirmar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
