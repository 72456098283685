/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Layouts
import Subusers from "./layouts/subusers";
import Process from "./layouts/processos";
import Dashboard from "./layouts/dashboard";
import Scheduling from "./layouts/scheduling";
import ExternalSchedule from "./layouts/external";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";
import AccountCreated from "./layouts/authentication/account-created";
import Request from "./layouts/authentication/reset-password/request";
import NewPassword from "./layouts/authentication/reset-password/new-password";
import VerifyEmail from "./layouts/authentication/reset-password/verify-email";
import EmailConfirmed from "./layouts/authentication/account-confirmation/email-confirmed/";
import EmailConfirmation from "./layouts/authentication/account-confirmation/email-confirmation";
import Profile from "./layouts/profile";
import Financial from "./layouts/financial";
import ScheduleConfirmed from "./layouts/external/components/ScheduleConfirmed";
import PlanUpdate from "./layouts/planUpdate";

import {
  ProtectedRoute,
  UserAlreadyLoggedIn,
  LogoutUser,
} from "./hooks/useAuth/components/ProtectedRoute";

// icons
import UsersIcon from "./assets/images/icons/usersIcon.svg";
import clientsIcon from "./assets/images/icons/clientsIcon.svg";
import cashflowIcon from "./assets/images/icons/cashflowIcon.svg";
import lawsuitIcon from "./assets/images/icons/lawsuitIcon.svg";
import scheduling from "./assets/images/icons/scheduling.svg";
import SupportForm from "./layouts/support";
import NotAllowed from "./layouts/authentication/notAllowed";
import UpdatedPlan from "./layouts/authentication/updated-plan";
import CardUpdate from "./layouts/cardUpdate";

const routes = [
  // Rotas/links do sidenav
  {
    type: "collapse",
    name: "Clientes",
    permission: "CLIENTS",
    key: "clientes",
    icon: <img src={clientsIcon} alt="icon" style={{ width: "24px" }} />,
    route: "/clientes",
    component: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Processos",
    permission: "PROCESSES",
    key: "processos",
    icon: <img src={lawsuitIcon} alt="icon" style={{ width: "24px" }} />,
    route: "/processos",
    component: (
      <ProtectedRoute>
        <Process />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Agendamento",
    key: "agendamento_interno",
    permission: "SCHEDULES",
    icon: <img src={scheduling} alt="icon" style={{ width: "24px" }} />,
    route: "/agendamento_interno",
    component: (
      <ProtectedRoute>
        <Scheduling />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Fluxo de caixa",
    key: "caixa",
    permission: "FINANCIAL",
    icon: <img src={cashflowIcon} alt="icon" style={{ width: "24px" }} />,
    route: "/caixa",
    component: (
      <ProtectedRoute>
        <Financial />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    name: "Usuários",
    key: "subusers",
    permission: "SUBUSER",
    icon: <img src={UsersIcon} alt="icon" style={{ width: "24px" }} />,
    route: "/subusers",
    component: (
      <ProtectedRoute>
        <Subusers />
      </ProtectedRoute>
    ),
  },
  // Rotas/links individuais
  {
    invisible: true,
    key: "plan-update",
    route: "/plan-update/:id",
    component: (
      <ProtectedRoute>
        <PlanUpdate />
      </ProtectedRoute>
    ),
  },
  {
    invisible: true,
    key: "card-update",
    route: "/card-update/",
    component: (
      <ProtectedRoute>
        <CardUpdate />
      </ProtectedRoute>
    ),
  },
  {
    invisible: true,
    key: "sign-in",
    route: "/login",
    component: (
      <UserAlreadyLoggedIn>
        <SignIn />
      </UserAlreadyLoggedIn>
    ),
  },
  {
    invisible: true,
    key: "sign-up",
    route: "/signup",
    component: (
      <UserAlreadyLoggedIn>
        <SignUp />
      </UserAlreadyLoggedIn>
    ),
  },
  {
    invisible: true,
    key: "logout",
    route: "/logout",
    component: (
      <LogoutUser>
        <SignIn />
      </LogoutUser>
    ),
  },
  {
    invisible: true,
    key: "request",
    route: "/request-password",
    component: <Request />,
  },
  {
    invisible: true,
    key: "reset-password",
    route: "/reset-password",
    component: <NewPassword />,
  },
  {
    invisible: true,
    key: "verify-email",
    route: "/verify-email",
    component: <VerifyEmail />,
  },
  {
    invisible: true,
    key: "email-confirmation",
    route: "/confirm-email",
    component: <EmailConfirmation />,
  },
  {
    invisible: true,
    key: "email-confirmed",
    route: "/email-confirmed",
    component: <EmailConfirmed />,
  },
  {
    invisible: true,
    key: "account-created",
    route: "/account-created",
    component: <AccountCreated />,
  },
  {
    invisible: true,
    key: "updated-plan",
    route: "/updated-plan",
    component: <UpdatedPlan />,
  },

  {
    invisible: true,
    key: "agendamento",
    route: "/agendamento",
    component: <ExternalSchedule />,
  },
  {
    invisible: true,
    key: "schedule-confirmed",
    route: "/schedule-confirmed",
    component: <ScheduleConfirmed />,
  },
  {
    invisible: true,
    key: "not-allowed",
    route: "/conta-bloqueada",
    component: <NotAllowed />,
  },
  {
    invisible: true,
    key: "profile",
    route: "/profile",
    component: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    invisible: true,
    key: "support",
    route: "/suporte",
    component: (
      <ProtectedRoute>
        <SupportForm />
      </ProtectedRoute>
    ),
  },
];

export default routes;
