/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */

import { Link, useNavigate } from "react-router-dom";

import MDBox from "../../../../components/MDBox";
import { api } from "../../../../services/api";
import * as yup from "yup";
import { type SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CircularProgress,
  DialogContentText,
  Grid,
  InputLabel,
} from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import { encryptCardPagSeguro } from "../../../../utils/pagBank";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../../../hooks/useAuth";

interface IData {
  name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  platform_code: string;
  plan_id: string;
  cpf: string;
  birth_date: string;
  phone: string;
  payday: number;
}

interface IDataApplication {
  _id: string;
  active: boolean;
  code: string;
  name: string;
  owner_id: string;
  payday: number;
  plan_id: string;
  platform_id: string;
  assinatura_pagBank_id: string;
  customer_pagBank_id: string;
}

interface ICreditCard {
  email: string;
  amount: number;
  // setCardTokenID: React.Dispatch<React.SetStateAction<string>>;
  lockButton: boolean;
  planId: string;
  isFree: boolean;
  // handleBack: () => void;
  application: IDataApplication;
}

interface IFormInput {
  name_card: string;
  number_card: string;
  exp: string;
  cvv: string;
}

const schema: yup.ObjectSchema<IFormInput> = yup.object({
  name_card: yup.string().required("Informe o nome"),
  number_card: yup.string().required("Informe o número"),
  exp: yup.string().required("Informe a data de validade."),
  cvv: yup.string().required("Informe o cvv"),
});

function CreditCard() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { user } = useAuth();
  const [openLoading, setOpenLoading] = useState(false);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setOpenLoading(true);
    const [expMonth, expYear] = data.exp.split("/");

    const card = encryptCardPagSeguro({
      expMonth,
      expYear,
      holder: data.name_card,
      number: data.number_card.replaceAll(" ", ""),
      securityCode: data.cvv,
    });

    const formData = [
      {
        type: "CREDIT_CARD",
        card: {
          encrypted: card.encryptedCard,
          holder: {
            name: data.name_card,
          },
          security_code: data.cvv,
        },
      },
    ];

    if (!card.hasErrors) {
      try {
        const response = await api.put("/payments/update-card", formData);

        console.log(response);

        if (response.data) {
          toast.success("Dados atualizado com sucesso", {
            duration: 5000,
          });

          setTimeout(() => {
            navigate("/profile");
          }, 1000);
        }
      } catch (err: any) {
        const code = err.response.data.statusCode;
        const message = err.response.data.message;
        if (code === 400) {
          toast.error(message, {
            duration: 5000,
          });
        } else if (code === 404) {
          toast.error("Ocorreu um erro. Tente novamente mais tarde!", {
            duration: 5000,
          });
        } else if (code === 500) {
          toast.error(
            "Ocorreu um erro. Mas não se preocupe, não foi culpa sua.",
            {
              duration: 5000,
            },
          );
        } else {
          toast.error("Ocorreu um erro. Verifique as credenciais!", {
            duration: 5000,
          });
        }
      } finally {
        setOpenLoading(false);
      }
    } else {
      toast.error("Verifique as informações do seu cartão.", {
        duration: 5000,
      });
      setOpenLoading(false);
    }
  };

  return (
    <MDBox component="form" role="form">
      <MDBox sx={{ flexGrow: 1 }}>
        <DialogContentText color={"#0B2F36"} mb={2}>
          Dados do cartão
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Nome impresso no cartão{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.name_card == null)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register("name_card")}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.name_card?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Número do Cartão{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.number_card == null)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register("number_card")}
                mask={"9999 9999 9999 9999"}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.number_card?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                Validade{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.exp == null)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register("exp")}
                mask={"99/9999"}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.exp?.message}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox display="flex" flexDirection="column">
              <InputLabel sx={{ mb: 1 }}>
                CVV{" "}
                <MDTypography
                  sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                >
                  *
                </MDTypography>
              </InputLabel>
              <MDInput
                error={!(errors.cvv == null)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register("cvv")}
                mask={"999"}
                type="text"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                }}
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.cvv?.message}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>

        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "0 10px",
            pt: 2,
            mb: 2,
          }}
        >
          {" "}
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={4}
            mb={1}
          >
            <MDButton
              component={Link}
              to="/profile"
              variant="none"
              sx={{
                width: "130px",

                padding: "10px",
                textTransform: "capitalize",
                color: "#0B2F36",
                border: "2px solid #DDCA92",
                borderRadius: "10px",
              }}
            >
              Voltar ao perfil
            </MDButton>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={4}
            mb={1}
          >
            <MDButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: "130px",

                padding: "10px",
                textTransform: "capitalize",
                color: "#DDCA92",
                background:
                  "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                border: "1px solid #DDCA92",
                borderRadius: "10px",

                "&:hover": {
                  background:
                    "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                  color: "#DDCA92",
                  border: "1px solid #DDCA92",
                },
              }}
            >
              {openLoading ? <CircularProgress color="primary" /> : "Finalizar"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default CreditCard;
