/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Chip, Paper, hslToRgb } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

interface ICashFlowStatisticsCard {
  color: string;
  title: string;
  count: number | string;
  percentage: number;
  icon: string;
  iconColor: string;
  chipColor: string;
}

function CashFlowStatisticsCard({
  color,
  title,
  count,
  percentage,
  icon,
  iconColor,
  chipColor,
}: ICashFlowStatisticsCard) {
  return (
    <Card
      sx={{
        backgroundColor: "#0B2F36",
        borderRadius: "20px",
        boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
        width: "100%",
      }}
    >
      <MDBox
        display="flex"
        // justifyContent="space-between"
        py={4}
        px={2}
        mb={1}
        sx={{ width: "100%", gap: "2rem" }}
      >
        <MDBox
          variant="gradient"
          bgColor="white"
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="3.5rem"
          height="3rem"
        >
          <Icon fontSize="medium" sx={{ color: iconColor }}>
            {icon}
          </Icon>
        </MDBox>

        <MDBox display="flex" flexDirection="column" justifyContent="center">
          <MDTypography
            variant="button"
            fontWeight="regular"
            sx={{ color: "#DDCA92" }}
          >
            {title}
          </MDTypography>
          <MDTypography variant="h4" mb={-1} sx={{ color: "#FFFFFF" }}>
            R${count}
          </MDTypography>
        </MDBox>

        {/* <MDBox display="flex" alignItems="flex-end">
          <Chip
            label={`+${percentage}%`}
            sx={{
              backgroundColor: chipColor,
              color: "#fff",
              boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
            }}
          />
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

export default CashFlowStatisticsCard;
