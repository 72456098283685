/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-floating-promises */
import * as React from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
// import { ErrorMessage } from "@hookform/error-message";
import { useForm, type SubmitHandler } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 Components
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

import { useAuth } from "../../../../../hooks/useAuth";
import { lawApi } from "../../../../../services/api";
import cep from "cep-promise";
import dataUF from "../../../../../brazilian-states.json";
import { Autocomplete, TextField } from "@mui/material";

interface IUser {
  _id: string;
  auth_user_id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    permission: [] | null;
    role: string;
  };
  cpf: string;
  birth_date: string;
  social_status: string;
  identity: string;
  email: string;
  name: string;
  last_name: string;
  oab: string | null;
  office: string | null;
}

interface IAddress {
  cep: string;
  city: string;
  complement: string;
  created_at: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  updated_at: string;
  _id: string;
}

interface IInputs {
  email: string;
  name: string;
  last_name: string;
  oab: string;
  // office: string;
  cpf: string;
  birth_date: string;
  social_status: string;
  identity: string;
  cep: string;
  city: string;
  complement: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  password: string | null;
  c_password: string | null;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  email: yup
    .string()
    .required("Informe o email do cliente")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email inválido"),
  oab: yup.string().required("Informe o OAB"),
  name: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .max(50, "No máximo 50 caracteres")
    .required("Informe o nome do cliente"),
  last_name: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .max(50, "No máximo 50 caracteres")
    .required("Informe o nome do cliente"),
  cpf: yup.string().required("CPF não pode ser vazio"),
  birth_date: yup.string().nullable().required("Data inválida"),
  social_status: yup.string().required("Informe estado civil"),
  identity: yup.string().required("RG não pode ser vazio"),
  cep: yup.string().required("Informe o CEP"),
  street: yup.string().required("Informe a rua"),
  number: yup
    .string()
    .matches(/(\d+)| /g, "Número inválido")
    .required("Informe o número da casa ou apt.")
    .min(1, "Número inválido"),
  neighborhood: yup.string().required("Informe o bairro"),
  city: yup.string().required("Informe a cidade"),
  state: yup.string().required("Informe o estado"),
  complement: yup.string().default(""),
  password: yup
    .string()
    // .required("Informe a senha")
    // .min(8, "Sua senha deve ter no mínimo 8 caracteres")
    .nullable()
    .default(""),
  c_password: yup
    .string()
    // .required("Digite sua senha novamente")
    // .min(8, "Sua senha deve ter no mínimo 8 caracteres")
    .oneOf([yup.ref("password")], "Senhas não são iguais")
    .nullable()
    .default(""),
});

interface ISubuserForm {
  matches: boolean;
  user: IUser;
  address: IAddress | null;
  updatingProcess: boolean;
  handleUpdateProcess: () => void;
  fetchPersonalData: () => Promise<void>;
  toastFeedbackMessage: (type: string, message: string) => any;
  handleDeactivateAccount: (code: string) => void;
  application: any;
}

export default function SubuserForm({
  user,
  matches,
  address,
  updatingProcess,
  fetchPersonalData,
  handleUpdateProcess,
  toastFeedbackMessage,
  application,
  handleDeactivateAccount,
}: ISubuserForm) {
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });
  const defaultPropsMaritalStatus = {
    options: [
      { value: "solteiro", label: "Solteiro" },
      { value: "casado", label: "Casado" },
      { value: "separado", label: "Separado" },
      { value: "divorciado", label: "Divorciado" },
      { value: "viúvo", label: "Viúvo" },
    ],
    getOptionLabel: (option: { value: string; label: string }) => option.label,
  };
  const defaultPropsUF = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.sigla,
  };
  const [maritalStatus, setMaritalStatus] =
    React.useState<{ value: string; label: string }>();
  const [activeSchedule, setActiveSchedule] = React.useState<boolean>(false);
  const [state, setState] = React.useState<{ nome: string; sigla: string }>();

  const watchCep = watch("cep");

  React.useEffect(() => {
    void consultCEP();
  }, [watchCep]);

  const consultCEP = async () => {
    try {
      if (watchCep.length === 9) {
        const newCEPFormatted = watchCep.replace("-", "");

        const response = await cep(newCEPFormatted);

        if (response) {
          const { city, neighborhood, state, street } = response;
          setValue("city", city);
          setValue("neighborhood", neighborhood);
          setValue("state", state);
          setValue("street", street);
        }
      }
    } catch (error) {}
  };
  const handleChangeActivateSchedule = () => {
    setActiveSchedule(!activeSchedule);
  };

  const handleUpdateProcessInit = () => {
    const userFields = [
      "cpf",
      "birth_date",
      "social_status",
      "identity",
      "email",
      "name",
      "last_name",
      "cep",
      "complement",
      "neighborhood",
      "number",
      "state",
      "street",
    ];

    const addressFields = [
      "cep",
      "city",
      "complement",
      "neighborhood",
      "number",
      "state",
      "street",
    ];

    userFields.forEach((field: string) => {
      if (field === "birth_date" && user[field] !== undefined) {
        const date = dayjs(user[field])
          .format("DD/MM/YYYY")
          .split("/")
          .reverse()
          .join("-");
        setValue(field, date);
      } else if (field === "social_status" && user) {
        const optionsSocialStatus = [
          { value: "solteiro", label: "Solteiro" },
          { value: "casado", label: "Casado" },
          { value: "separado", label: "Separado" },
          { value: "divorciado", label: "Divorciado" },
          { value: "viúvo", label: "Viúvo" },
        ];

        const _marital = optionsSocialStatus.find(
          (value) => value.value === user[field],
        );

        if (_marital) {
          setMaritalStatus(_marital);
        }
      } else {
        // @ts-expect-error
        setValue(field, user[field]);
      }
    });

    addressFields.forEach((field: string) => {
      // @ts-expect-error
      setValue(field, address[field]);

      if (field === "state" && address) {
        const _state = dataUF.UF.find(
          (value) => value.sigla === address[field],
        );

        if (_state) {
          setState(_state);
        }
      }
    });

    setActiveSchedule(user?.active_schedule);
  };

  React.useEffect(() => {
    if (updatingProcess) {
      handleUpdateProcessInit();
    } else {
      reset();
    }
  }, [updatingProcess, reset]);

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    // setDisableButton(true);

    let userFormData: object;
    // console.log(data);

    if (
      data.password != null ||
      data.password !== "" ||
      data.password !== undefined
    ) {
      userFormData = {
        email: data.email,
        name: data.name,
        last_name: data.last_name,
        cpf: data.cpf,
        birth_date: data.birth_date,
        social_status: maritalStatus?.label,
        identity: data.identity,
        oab: data.oab,
        office: user.office,
        active_schedule: activeSchedule,
      };
    } else {
      userFormData = {
        email: data.email,
        name: data.name,
        last_name: data.last_name,
        cpf: data.cpf,
        birth_date: data.birth_date,
        social_status: maritalStatus?.label,
        identity: data.identity,
        oab: data.oab,
        office: user.office,
        password: data.password,
        active_schedule: activeSchedule,
      };
    }

    const addressFormData = {
      cep: data.cep,
      street: data.street,
      number: `${data.number}`,
      neighborhood: data.neighborhood,
      city: data.city,
      state: state?.sigla,
      complement: data.complement ?? "",
    };

    // console.log(userFormData);

    try {
      if (user?.address_id != null) {
        const [firstResponse, secondResponse] = await Promise.all([
          lawApi.put(`/subusers/${user?._id}`, userFormData),
          lawApi.put(`/addresses/${user?.address_id}`, addressFormData),
        ]);
        // console.log(firstResponse, secondResponse);
        if (firstResponse && secondResponse) {
          toastFeedbackMessage("succes", "Dados atualizados com sucesso.");
        }
      } else {
        const addressResponse = await lawApi.post(
          `/addresses`,
          addressFormData,
        );

        userFormData = {
          ...userFormData,
          address_id: addressResponse.data._id,
        };

        const userResponse = await lawApi.put(
          `/subusers/${user?._id}`,
          userFormData,
        );
        // console.log(userResponse);
        if (addressResponse && userResponse) {
          toastFeedbackMessage("succes", "Dados atualizados com sucesso.");
        }
      }
    } catch (err: any) {
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização dos dados. Verifique os dados informados!",
        );
      }
    } finally {
      void fetchPersonalData();
      handleUpdateProcess();
    }
  };

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email: "",
        name: "",
        last_name: "",
        cpf: "",
        birth_date: "",
        social_status: "",
        identity: "",
        cep: "",
        city: "",
        complement: "",
        neighborhood: "",
        number: "",
        state: "",
        street: "",
        password: "",
        c_password: "",
      });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <MDBox
      sx={{
        border: "2px solid #0B2F36",
        borderRadius: "10px",
        backgroundColor: "#F6F6F6",
      }}
    >
      <MDBox mx={5} my={5}>
        <MDBox sx={{ display: "flex", alignItems: "center" }}>
          <MDBox
            sx={{
              height: "13px",
              width: "5px",
              backgroundColor: "#0B2F36",
              mr: 1,
            }}
          ></MDBox>
          <MDTypography
            variant="subtitle2"
            sx={{ color: "#0B2F36", fontWeight: 500 }}
          >
            Dados pessoais
          </MDTypography>
        </MDBox>
        <MDBox
          noValidate
          component="form"
          my={2}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Nome</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.name == null)}
                    {...register("name")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={user?.name}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.name?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Sobrenome</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.last_name == null)}
                    {...register("last_name")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={user?.last_name}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.last_name?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Email</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.email == null)}
                    {...register("email")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={user?.email}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.email?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>CPF</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.cpf == null)}
                    {...register("cpf")}
                    mask={"999.999.999-99"}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={user?.cpf}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.cpf?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Data nascimento</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    type="date"
                    error={!(errors.birth_date == null)}
                    {...register("birth_date")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={
                      user?.birth_date
                        ? user?.birth_date.split("-").reverse().join("/")
                        : ""
                    }
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.birth_date?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Status social</InputLabel>
                {updatingProcess ? (
                  <Autocomplete
                    disablePortal
                    size="small"
                    {...defaultPropsMaritalStatus}
                    value={maritalStatus ?? null}
                    noOptionsText="Sem opções"
                    // defaultValue={client}
                    onChange={(event: any, newValue: any) => {
                      setMaritalStatus(newValue);
                    }}
                    sx={{
                      "& input": { height: "27px" },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    fullWidth
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={user?.social_status}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.social_status?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Identidade</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.identity == null)}
                    {...register("identity")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={user?.identity}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.identity?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>OAB</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.oab == null)}
                    {...register("oab")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={user?.oab}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Cargo</InputLabel>
                <MDInput
                  disabled
                  placeholder={user?.office}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox sx={{ display: "flex", alignItems: "center" }}>
          <MDBox
            sx={{
              height: "13px",
              width: "5px",
              backgroundColor: "#0B2F36",
              mr: 1,
            }}
          ></MDBox>
          <MDTypography
            variant="subtitle2"
            sx={{ color: "#0B2F36", fontWeight: 500 }}
          >
            Endereço
          </MDTypography>
        </MDBox>
        <MDBox
          noValidate
          component="form"
          my={2}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>CEP</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.cep == null)}
                    {...register("cep")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={address?.cep}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.cep?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Rua</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.street == null)}
                    {...register("street")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={address?.street}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.street?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Número</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.number == null)}
                    {...register("number")}
                    type="number"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={address?.number}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.number?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Bairro</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.neighborhood == null)}
                    {...register("neighborhood")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={address?.neighborhood}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.neighborhood?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Cidade</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.city == null)}
                    {...register("city")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={address?.city}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.city?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Estado</InputLabel>
                {updatingProcess ? (
                  <Autocomplete
                    disablePortal
                    size="small"
                    {...defaultPropsUF}
                    value={state ?? null}
                    noOptionsText="Estado inexistente"
                    // defaultValue={client}
                    onChange={(event: any, newValue: any) => {
                      setState(newValue);
                    }}
                    sx={{
                      "& input": { height: "27px" },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    fullWidth
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={address?.state}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.state?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Complemento</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.complement == null)}
                    {...register("complement")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    placeholder={address?.complement}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.complement?.message}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox
        display="flex"
        flexDirection={matches ? "column" : "row"}
        justifyContent={matches ? "" : "space-between"}
        alignItems={matches ? "flex-start" : ""}
        mx={5}
        mb={3}
      >
        {updatingProcess ? (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeActivateSchedule}
                checked={activeSchedule}
                sx={{
                  color: "#DDCA92",
                  "&.Mui-checked": {
                    color: "#DDCA92",
                  },
                }}
              />
            }
            label={
              <MDTypography sx={{ fontSize: matches ? "12px" : "14px" }}>
                Profissional (agendamento)
              </MDTypography>
            }
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                disabled
                checked={user?.active_schedule}
                sx={{
                  color: "#DDCA92",
                  "&.Mui-checked": {
                    color: "#DDCA92",
                  },
                }}
              />
            }
            label={
              <MDTypography sx={{ fontSize: matches ? "12px" : "14px" }}>
                Profissional (agendamento)
              </MDTypography>
            }
          />
        )}
        <MDButton
          disabled={updatingProcess}
          onClick={() => {
            handleDeactivateAccount(application?.code);
          }}
          variant="text"
          sx={{ color: "#f00", "&:hover": { color: "#f00" } }}
        >
          Desativar conta
        </MDButton>
      </MDBox>
      <MDBox
        noValidate
        component="form"
        mx={5}
        mb={5}
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        {updatingProcess && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Nova senha</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.password == null)}
                    {...register("password")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.password?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1 }}>Confirmação de senha</InputLabel>
                {updatingProcess ? (
                  <MDInput
                    error={!(errors.c_password == null)}
                    {...register("c_password")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                ) : (
                  <MDInput
                    disabled
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                )}
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.c_password?.message}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
      <MDBox display="flex" justifyContent="center" m={2} mb={3}>
        {updatingProcess && (
          <MDButton
            onClick={handleSubmit(onSubmit)}
            sx={{
              color: "#000",
              textTransform: "capitalize",
            }}
          >
            Confirmar edição
          </MDButton>
        )}
      </MDBox>
    </MDBox>
  );
}
