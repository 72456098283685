/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-props-no-spreading */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import secureLocalStorage from "react-secure-storage";
import * as yup from "yup";

import { useAuth } from "../../../hooks/useAuth";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import { CircularProgress, Icon, InputLabel } from "@mui/material";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

// Images
import logo from "../../../assets/images/logos/law_color_primary.svg";
import useEffectOnce from "../../../hooks/useEffectOnce";

const BORDER_GRADIENT =
  "linear-gradient(to top, #DDCA92, rgba(0, 0, 0, 0)) 1 100%";

interface IFormInput {
  email: string;
  password: string;
}

const schema: yup.ObjectSchema<IFormInput> = yup.object({
  email: yup
    .string()
    .required("Informe o email")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Informe email válido"),
  password: yup.string().required("Informe a senha"),
});

function Basic(): JSX.Element {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = useState(false);
  const handleCloseLoading = (): void => {
    setOpenLoading(false);
  };
  const handleOpenLoading = (): void => {
    setOpenLoading(true);
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const [rememberMe, setRememberMe] = useState(true);
  const [seePassword, setSeePassword] = useState(false);

  const handleSetRememberMe = (): void => {
    setRememberMe(!rememberMe);
  };

  const toastFeedbackMessage = (type: string, message: string): any => {
    if (type === "error") {
      return toast.error(`${message}`, {
        duration: 5000,
      });
    }

    return toast.success(`${message}`, {
      duration: 5000,
    });
  };

  const onSubmit: SubmitHandler<IFormInput> = useCallback(
    async (data) => {
      handleOpenLoading();
      try {
        await signIn({ email: data.email, password: data.password });

        navigate("/clientes");
      } catch (err: any) {
        const code = err.response.data.statusCode;
        const message = err.response.data.message;
        if (code === 400) {
          toastFeedbackMessage("error", message);
        } else if (code === 404) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro ao logar. Tente novamente mais tarde!",
          );
        } else if (code === 500) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro ao logar. Mas não se preocupe, não foi culpa sua.",
          );
        } else {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro ao logar. Verifique as credenciais!",
          );
        }
      } finally {
        if (rememberMe) {
          secureLocalStorage.setItem("email", data.email);
          secureLocalStorage.setItem("password", data.password);
          secureLocalStorage.setItem("rememberMe", rememberMe);
        } else {
          secureLocalStorage.removeItem("email");
          secureLocalStorage.removeItem("password");
        }

        handleCloseLoading();
      }
    },
    [navigate, rememberMe, signIn],
  );

  useEffectOnce(() => {
    const credentials: { email: string; password: string } = {
      email: secureLocalStorage.getItem("email") as string,
      password: secureLocalStorage.getItem("password") as string,
    };
    const fields = ["email", "password"];

    if (credentials.email != null && credentials.password != null) {
      fields.forEach((field: string) => {
        // @ts-expect-error
        setValue(field, credentials[field]);
      });
    }
  }, []);

  useEffectOnce(() => {
    const remember = secureLocalStorage.getItem("rememberMe");

    if (remember != null) {
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  }, []);

  useEffect(() => {
    secureLocalStorage.setItem("rememberMe", rememberMe);

    if (!rememberMe) {
      // console.log("remover ados");
      secureLocalStorage.removeItem("email");
      secureLocalStorage.removeItem("password");
    }
  }, [rememberMe]);

  return (
    <BasicLayout background="#0B2F36">
      <MDBox
        minWidth="100%"
        height="100%"
        pt={4}
        pb={3}
        px={4}
        sx={{
          border: "3px solid",
          borderRadius: "11px",
          borderImage: BORDER_GRADIENT,
        }}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={6}
          height="100%"
        >
          <MDBox display="flex" justifyContent="center" mb={6}>
            <img src={logo} alt="logo do LAW" style={{ maxWidth: "50%" }} />
          </MDBox>
          <MDBox component="form" role="form">
            <MDBox display="flex" flexDirection="column" mb={2}>
              <InputLabel sx={{ mb: 1 }}>Endereço de email</InputLabel>
              <MDInput
                error={!(errors.email == null)}
                type="email"
                {...register("email")}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                  borderRadius: "8px",
                  boxShadow: " inset 3px 4px 11px rgba(0, 0, 0, 0.25)",
                  backgroundColor: "#fff",
                }}
                fullWidth
              />
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.email?.message}
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              mb={2}
              sx={{ position: "relative" }}
            >
              <InputLabel sx={{ mb: 1 }}>Senha</InputLabel>
              <MDInput
                error={!(errors.password == null)}
                type={seePassword ? "text" : "password"}
                {...register("password")}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DDCA92",
                    },
                  },
                  borderRadius: "8px",
                  boxShadow: "inset 3px 4px 11px rgba(0, 0, 0, 0.25)",
                  backgroundColor: "#fff",
                }}
              />
              {/* <MDBox mr={1}> */}
              <MDButton
                variant="text"
                color="primary"
                sx={{
                  position: "absolute",
                  top: "1.5rem",
                  right: 0,
                  fontSize: 30,
                }}
                onClick={() => {
                  setSeePassword(!seePassword);
                  // setTypeInputPassword(seePassword ? "text" : "password");
                }}
              >
                <Icon fontSize="large" sx={{ fontSize: 30 }}>
                  {seePassword ? "visibility_off" : "visibility"}
                </Icon>
              </MDButton>
              {/* </MDBox> */}
              <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                {errors.password?.message}
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              ml={-1}
              flexWrap="wrap"
            >
              <MDBox>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                    ml: -1,
                    color: "#F3F3F3",
                  }}
                >
                  &nbsp;&nbsp;Lembrar-me
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography
                  component={Link}
                  to="/request-password"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                    ml: -1,
                    color: "#F3F3F3",
                  }}
                >
                  &nbsp;&nbsp;Esqueci a senha
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt={4}
              mb={1}
            >
              <MDButton
                onClick={handleSubmit(onSubmit)}
                variant="outlined"
                fullWidth
                sx={{
                  width: "20em",
                  maxWidth: "100%",
                  padding: 1,
                  mb: 2,
                  textTransform: "uppercase",
                  color: "#DDCA92",
                  background:
                    "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                  border: "1px solid #DDCA92",
                  borderRadius: "10px",
                  boxShadow: "rgba(255, 255, 255, 0.25) -8px 11px 101px",
                }}
              >
                {openLoading ? <CircularProgress color="primary" /> : "entrar"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" sx={{ color: "#F3F3F3" }}>
                Não tem uma conta? <br />
                <MDTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  fontWeight="medium"
                  sx={{ color: "#DDCA92" }}
                >
                  Registre-se
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleCloseLoading}
      >
        <MDBox
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: 200,
              height: 200,
            },
          }}
        >
          <Paper elevation={1} sx={{ background: "#0B2F36" }}>
            <CircularProgress color="primary" />
            <MDTypography
              // component={Link}
              // to="/signup"
              // variant="button"
              fontWeight="small"
              sx={{ color: "#DDCA92" }}
            >
              Carregando
            </MDTypography>
          </Paper>
        </MDBox>
      </Backdrop> */}
    </BasicLayout>
  );
}

export default Basic;
