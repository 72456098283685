import { Navigate } from "react-router-dom";
import { useAuth } from "../../index";
import ScreenLoading from "../../../../components/ScreenLoading";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  console.log(user);
  // user is not authenticated
  if (user == null || user === undefined) {
    return <Navigate replace to="/login" />;
  }

  return children;
};

export const UserAlreadyLoggedIn = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { user } = useAuth();
  console.log("UserAlreadyLoggedIn");

  if (user) {
    return <Navigate to="/clientes" />;
  }

  return children;
};

export const LogoutUser = ({ children }: { children: JSX.Element }) => {
  const { user, signOut } = useAuth();
  console.log("Loggout");
  if (user) {
    signOut();
    return <Navigate to="/login" />;
  }

  return children;
};
