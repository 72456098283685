/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React example components
import PageLayout from "../../../../examples/LayoutContainers/PageLayout";

interface Props {
  background?: string;
  children: React.ReactNode;
}

function Layout({ children, background = "#F1F1F1" }: Props) {
  return (
    <PageLayout background={background}>
      <Grid container justifyContent="center" minHeight="100vh" height="100%">
        <Grid item minHeight="100%" xs={10} sm={10} md={6} lg={5} xl={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Layout;
