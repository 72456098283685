/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import toast from "react-hot-toast";

import { lawApi } from "../../services/api";

// @mui material components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

// Subusers components
import SubusersTable from "./components/SubusersTable";
import CreateSubuserDialog from "./components/dialogs/CreateSubuser";
import DeleteSubuserDialog from "./components/dialogs/DeleteSubuser";
import ShowSubuserDialog from "./components/dialogs/ShowSubuser";

interface ISubuser {
  _id: string;
  auth_user_id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    role: string;
    permissions: string[];
  };
  birth_date: string;
  cpf: string;
  identity: string;
  issuing_body: string;
  name: string;
  last_name: string;
  office: string;
  occupation: string;
  oab: string;
  social_status: string;
  uf: string;
  created_at: string;
}

interface IAddress {
  [x: string]: any;
  cep: string;
  city: string;
  complement: string;
  created_at: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  updated_at: string;
  __v: number;
  _id: string;
}

interface IAnalytics {
  user_created: string;
  user_active: string;
}

function Subusers() {
  const theme = useTheme();
  const [subusers, setSubusers] = useState<ISubuser>({
    _id: "",
    auth_user_id: "",
    active: false,
    active_schedule: false,
    address_id: "",
    application: {
      id: "",
      role: "",
      permissions: [],
    },
    birth_date: "",
    cpf: "",
    identity: "",
    issuing_body: "",
    name: "",
    last_name: "",
    office: "",
    occupation: "",
    oab: "",
    social_status: "",
    uf: "",
    created_at: "",
  });

  const [filter, setFilter] = useState<string>("");
  const [isActiveUser, setIsActiveUser] = useState("true");
  const [subuser, setSubuser] = useState<ISubuser>({
    _id: "",
    auth_user_id: "",
    active: false,
    active_schedule: false,
    address_id: "",
    application: {
      id: "",
      role: "",
      permissions: [],
    },
    birth_date: "",
    cpf: "",
    identity: "",
    issuing_body: "",
    name: "",
    last_name: "",
    office: "",
    occupation: "",
    oab: "",
    social_status: "",
    uf: "",
    created_at: "",
  });
  const [subuserToDelete, setSubuserToDelete] = useState<ISubuser>({
    _id: "",
    auth_user_id: "",
    active: false,
    active_schedule: false,
    address_id: "",
    application: {
      id: "",
      role: "",
      permissions: [],
    },
    birth_date: "",
    cpf: "",
    identity: "",
    issuing_body: "",
    name: "",
    last_name: "",
    office: "",
    occupation: "",
    oab: "",
    social_status: "",
    uf: "",
    created_at: "",
  });
  const [subuserAddress, setSubuserAddress] = useState<IAddress>({
    cep: "",
    city: "",
    complement: "",
    created_at: "",
    neighborhood: "",
    number: "",
    state: "",
    street: "",
    updated_at: "",
    __v: 0,
    _id: "",
  });

  const [subuserPermissions, setSubuserPermissions] = useState<string[]>();
  const [analyticsSubusers, setAnalyticsSubusers] = useState<IAnalytics>({
    user_created: "0",
    user_active: "0",
  });
  const [subusersFetched, setSubusersFetched] = useState<boolean>(false);
  const [refreshUsers, setRefreshUsers] = useState<boolean>(false);
  const [openNewUser, setOpenNewUser] = useState<boolean>(false);
  const [openSubuser, setOpenSubuser] = useState<boolean>(false);
  const [openDeleteSubuserDialog, setOpenDeleteSubuserDialog] =
    useState<boolean>(false);
  const [loadingDataGrid, setLoadingDataGrid] = useState<boolean>(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenSubuser = async (data: ISubuser): Promise<void> => {
    const addressID = data.address_id;
    const permissions = data.application.permissions;

    try {
      if (addressID) {
        const response = await lawApi.get(`/addresses/${addressID}`);

        const { address } = response.data.data;
        setSubuserAddress(address);
      }

      if (permissions) {
        const permissions: string[] = data.application.permissions.map(
          (permission: string) => {
            return permission.split("_").slice(-1)[0].toLowerCase();
          },
        );
        const uniquePermissions = Array.from(new Set(permissions));
        setSubuserPermissions(uniquePermissions);
      } else {
        setSubuserPermissions(["ALL"]);
      }

      setSubuser(data);
      setOpenSubuser(true);
    } catch (err: any) {
      // const code = err.response.data.statusCode;
      console.log(err);

      // if (code === 404) {
      //   toast.error(
      //     "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
      //     {
      //       duration: 5000,
      //     },
      //   );
      // } else if (code === 500) {
      //   toast.error(
      //     "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
      //     {
      //       duration: 5000,
      //     },
      //   );
      // }
    }
  };

  const handleCloseSubuser = (): void => {
    setOpenSubuser(false);
  };

  const handleOpenNewUserDialog = (): void => {
    setOpenNewUser(true);
  };

  const handleCloseNewUserDialog = (): void => {
    setOpenNewUser(false);
  };

  const toastFeedbackMessage = (type: string, message: string): any => {
    if (type === "error") {
      return toast.error(`${message}`, {
        duration: 5000,
      });
    }

    return toast.success(`${message}`, {
      duration: 5000,
    });
  };

  const fetchData = async (): Promise<void> => {
    setLoadingDataGrid(true);
    try {
      const response = await lawApi.get("/subusers", {
        params: {
          // name: filter,
          active: isActiveUser,
        },
      });
      const responseAnalytics = await lawApi.get("/subusers/analytics");
      const { subusers } = response.data.data;

      // eslint-disable-next-line array-callback-return
      const filteredSubuser = subusers.filter((value: any) => {
        if (value.name.toLowerCase().includes(filter.toLowerCase())) {
          return value;
        }
      });
      const analyticsSubuser = responseAnalytics.data.data.analytics_subuser;

      setAnalyticsSubusers({
        user_created: analyticsSubuser.total_subuser_created,
        user_active: analyticsSubuser.total_subuser_active,
      });

      setSubusers(filteredSubuser);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoadingDataGrid(false);
    }
  };

  const handleCloseDeleteSubuserDialog = (): void => {
    setOpenDeleteSubuserDialog(false);
  };

  const handleOpenDeleteSubuserDialog = async (
    subuser: ISubuser,
  ): Promise<void> => {
    setOpenSubuser(false);
    setOpenDeleteSubuserDialog(true);
    setSubuserToDelete(subuser);

    try {
      const response = await lawApi.delete(`/subuser/${subuser._id}`);
      setRefreshUsers(true);
    } catch (error) {}
  };

  useEffect(() => {
    if (!subusersFetched) {
      setSubusersFetched(true);
    }

    void fetchData();
    setRefreshUsers(false);
  }, [subusersFetched, filter, refreshUsers, isActiveUser]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3} sx={{ maxWidth: 271 }}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="people_alt_icon"
                title="Sub usuários cadastrados"
                count={analyticsSubusers.user_created}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ maxWidth: 271 }}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="person_add"
                title="Sub usuários ativos"
                count={analyticsSubusers.user_active}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <SubusersTable
                loadingDataGrid={loadingDataGrid}
                setFilter={setFilter}
                subusers={subusers}
                handleOpenSubuser={handleOpenSubuser}
                handleOpenNewUserDialog={handleOpenNewUserDialog}
                matches={matches}
                setRefresh={setRefreshUsers}
                isActiveUser={isActiveUser}
                setIsActiveUser={setIsActiveUser}
              />
            </Grid>
          </Grid>
        </MDBox>
        {/* Cadastrar novo subusuário */}
        <CreateSubuserDialog
          matches={matches}
          openNewUser={openNewUser}
          fetchSubusers={fetchData}
          handleCloseNewUserDialog={handleCloseNewUserDialog}
        />
        {/* Mostrar dados do subusuário */}
        <ShowSubuserDialog
          matches={matches}
          open={openSubuser}
          fetchData={fetchData}
          subuserData={subuser}
          subuserAddress={subuserAddress}
          permissions={subuserPermissions}
          handleCloseSubuser={handleCloseSubuser}
          handleOpenDeleteSubuserDialog={handleOpenDeleteSubuserDialog}
          toastFeedbackMessage={toastFeedbackMessage}
        />
        {/* Deletar sub usuário */}
        <DeleteSubuserDialog
          openDeleteSubuserDialog={openDeleteSubuserDialog}
          subuserToDelete={subuserToDelete}
          handleCloseDeleteSubuserDialog={handleCloseDeleteSubuserDialog}
          fetchSubusers={fetchData}
          toastFeedbackMessage={toastFeedbackMessage}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Subusers;
