import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IDeleteUserDialog {
  openPopUp: boolean;
  handleClosePopUp: React.Dispatch<React.SetStateAction<boolean>>;
  statusPayment: string;
}

export default function PopUpBlockedAccount({
  openPopUp,
  handleClosePopUp,
  statusPayment,
}: IDeleteUserDialog) {
  const [textTitle, setTextTitle] = useState("");
  const [textDescription, setTextDescription] = useState("");
  const [textButton, setTextButton] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const hasAlteredPlan =
      statusPayment === "CANCELED" || statusPayment === "SUSPENDED";
    const hasOverdue = statusPayment === "OVERDUE";

    if (hasAlteredPlan) {
      setTextTitle("Alteração no plano");
      setTextDescription(
        "A assinatura foi cancelada por problemas ou falta de pagamento. Portanto, seu plano foi atualizado para o gratuito, atualize para um plano com mais benefícios no perfil do usuário.",
      );
      setTextButton("Atualizar o plano");
    } else if (hasOverdue) {
      setTextTitle("Atualize seu pagamento");
      setTextDescription(
        "Não foi possível processar seu último pagamento, verifique as informações da sua conta para mais detalhes.",
      );
      setTextButton("Verifcar informações");
    }
  }, [statusPayment]);

  return (
    <Dialog
      open={openPopUp}
      onClose={() => {
        handleClosePopUp(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ padding: "40px 50px 30px 50px", backgroundColor: "#0B2F36" }}
      >
        <DialogContentText sx={{ textAlign: "center" }}>
          <MDTypography sx={{ fontWeight: 700, color: "#fff" }}>
            {textTitle}
          </MDTypography>
          <MDTypography sx={{ color: "#fff" }}>{textDescription}</MDTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0B2F36",
          paddingBottom: "40px",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={() => {
            handleClosePopUp(false);
          }}
          sx={{
            width: "150px",
            borderColor: "#DDCA92",
            borderRadius: "15px",
            // color: "#0B2F36",
          }}
        >
          Sair
        </MDButton>
        <MDButton
          variant="outlined"
          onClick={() => {
            navigate("/profile");
          }}
          // sx={{ width: "200px", borderColor: "#DDCA92", borderRadius: "15px" }}
          sx={{
            width: "250px",
            // color: "#DDCA92",
            borderColor: "#DDCA92",
            borderRadius: "15px",
            backgroundColor: "#DDCA92",
            // color: "#0B2F36",

            "&:hover": {
              borderColor: "#DDCA92",
              borderRadius: "15px",
              backgroundColor: "#DDCA92",
            },
          }}
        >
          {textButton}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
