export const styles = [
  {
    backgroundChip: "#f1f1f1",
    backgroundCard: "#0B2F36",
    chipTextColor: "#0B2F36",
    selected: "#DDCA92",
    textColor: "#f1f1f1",
  },
  {
    backgroundChip: "#0B2F36",
    backgroundCard: "#f1f1f1",
    chipTextColor: "#f1f1f1",
    selected: "#DDCA92",
    textColor: "#0B2F36",
  },
];
