/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import cep from "cep-promise";
import { useForm, type SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import { useAuth } from "../../../../../hooks/useAuth";
import { lawApi } from "../../../../../services/api";

// react-router-dom components

// @mui material components
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDInput from "../../../../../components/MDInput";
import MDTypography from "../../../../../components/MDTypography";

// Custom components
import dayjs from "dayjs";
import dataUF from "../../../../../brazilian-states.json";
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";

interface ICreateUserDialog {
  matches: boolean;
  openNewUser: boolean;
  fetchCustomers: () => Promise<void>;
  handleCloseNewUserDialog: () => void;
  toastFeedbackMessage: (type: string, message: string) => void;
  setHasAddClient: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IInputs {
  fullname: string;
  cpf: string;
  birth_date: Date | string | null;
  identity: string;
  issuing_body: string;
  occupation: string;
  cep: string;
  street: string;
  number: string | number;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  fullname: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .max(100, "No máximo 100 caracteres")
    .required("Informe o nome do cliente"),
  cpf: yup.string(),
  birth_date: yup.string(),
  phone: yup.string(),
  email: yup.lazy(value => !value ? yup.string() : yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Informe e-mail válido")),
  identity: yup
    .string()
    // .required("RG não pode ser vazio")
    .max(20, "No máximo 20 caracteres"),
  issuing_body: yup
    .string()
  // .min(1, "Deve ter no mínimo 1 caracteres")
  // .required("Informe o órgão expedidor")
  ,
  occupation: yup.string().default(""),
  cep: yup
    .string()
  // .required("Informe o CEP")
  // .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP inválido")
  ,
  street: yup.string(),
  number: yup
    .string()
  // .matches(/(\d+)| /g, "Número inválido")
  // .required("Informe o número da casa ou apt.")
  // .min(1, "Número inválido"),
  ,
  neighborhood: yup.string()
  // .required("Informe o bairro")
  ,
  city: yup.string()
  // .required("Informe a cidade")
  ,
  state: yup.string()
  // .required("Informe o estado")
  ,
  complement: yup.string().default(""),
});

function CreateUserDialog({
  matches,
  openNewUser,
  fetchCustomers,
  toastFeedbackMessage,
  handleCloseNewUserDialog,
  setHasAddClient,
}: ICreateUserDialog) {
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    reset,

    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const defaultPropsMaritalStatus = {
    options: [
      { value: "solteiro", label: "Solteiro" },
      { value: "casado", label: "Casado" },
      { value: "separado", label: "Separado" },
      { value: "divorciado", label: "Divorciado" },
      { value: "viúvo", label: "Viúvo" },
    ],
    getOptionLabel: (option: { value: string; label: string }) => option.label,
  };

  const defaultPropsUF = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.sigla,
  };

  const defaultPropsState = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.nome,
  };

  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [maritalStatus, setMaritalStatus] =
    useState<{ value: string; label: string }>();
  const [UF, setUF] = useState<{ nome: string; sigla: string }>();
  const [state, setState] = useState<{ nome: string; sigla: string }>();

  const watchCep = watch("cep");

  useEffect(() => {
    void consultCEP();
  }, [watchCep]);

  const consultCEP = async () => {
    try {
      if (watchCep.length === 9) {
        const newCEPFormatted = watchCep.replace("-", "");

        const response = await cep(newCEPFormatted);

        if (response) {
          const { city, neighborhood, state, street } = response;
          const _currentState = dataUF.UF.find((uf) => uf.sigla === state);
          if (_currentState) {
            setState(_currentState);
          }

          setValue("city", city);
          setValue("neighborhood", neighborhood);
          setValue("state", state);
          setValue("street", street);
        }
      }
    } catch (error) { }
  };

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    const isAfterThenToday = dayjs(data.birth_date).isAfter(dayjs());
    const isBeforeThen1900 = dayjs(dayjs(data.birth_date)).isBefore(
      "1900-01-01",
    );

    if (isAfterThenToday || isBeforeThen1900) {
      setError("birth_date", {
        message: "Data inválida",
      });
    } else {
      setDisableButton(true);

      data.number = +data.number;

      const formData = {
        ...data,
        application_id: user.applications[0].application_id,
        social_status: maritalStatus?.label,
        uf: UF?.sigla,
        state: state?.nome,
      };

      try {
        const response = await lawApi.post("/clients", formData);
        const data = response.data;

        if (data) {
          toastFeedbackMessage("success", "Cliente cadastrado com sucesso.");
        }

        handleCloseNewUserDialog();
      } catch (err: any) {
        const code = err.response.data.statusCode;
        const message = err.response.data.message;
        console.log(err);

        if (code === 400) {
          toastFeedbackMessage("error", message);
        } else if (code === 404) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro no cadastro do cliente. Tente novamente mais tarde!",
          );
        } else if (code === 500) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro no cadastro do cliente. Mas não se preocupe, não foi culpa sua.",
          );
        } else {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro no cadastro do cliente. Verifique os dados informados!",
          );
        }
      } finally {
        await fetchCustomers();
        setDisableButton(false);
        setHasAddClient(true);
      }
    }
  };

  return (
    <Dialog
      open={openNewUser}
      onClose={() => {
        handleCloseNewUserDialog();
      }}
      fullWidth={true}
      maxWidth="xl"
      fullScreen={!!matches}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleCloseNewUserDialog}
      >
        <MDBox display="flex" justifyContent="center">
          <MDTypography
            sx={{ color: "#0B2F36", fontSize: matches ? "20px" : "24px" }}
          >
            Cadastrar cliente
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>
      <DialogContent
        sx={{ padding: matches ? "30px 30px 0 30px" : "30px 50px 0 50px" }}
      >
        <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
          <MDBox
            sx={{
              height: "13px",
              width: "5px",
              backgroundColor: "#0B2F36",
              mr: 1,
            }}
          ></MDBox>
          <MDTypography
            variant="subtitle2"
            sx={{ color: "#0B2F36", fontWeight: 500 }}
          >
            Dados pessoais
          </MDTypography>
        </DialogContentText>
        <MDBox
          noValidate
          component="form"
          my={2}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Nome{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.fullname == null)}
                  {...register("fullname")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.fullname?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  CPF{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.cpf == null)}
                  {...register("cpf")}
                  type="text"
                  mask={"999.999.999-99"}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.cpf?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ color: "#0B2F36", mb: 1 }}>
                  Data Nascimento{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  type="date"
                  error={!(errors.birth_date == null)}
                  {...register("birth_date")}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.birth_date?.message}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Estado civil{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  noOptionsText="Estado civil indisponível"
                  {...defaultPropsMaritalStatus}
                  value={maritalStatus ?? null}
                  // defaultValue={client}
                  onChange={(event: any, newValue: any) => {
                    setMaritalStatus(newValue);
                  }}
                  sx={{
                    "& input": { height: "27px" },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  RG{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.identity == null)}
                  {...register("identity")}
                  type="text"
                  variant="outlined"
                  // mask={"99.999.999-9"}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.identity?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Órgão Expedidor{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.issuing_body == null)}
                  {...register("issuing_body")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.issuing_body?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  UF{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>

                <Autocomplete
                  disablePortal
                  size="small"
                  noOptionsText="Estado inexistente"
                  {...defaultPropsUF}
                  value={UF ?? null}
                  // defaultValue={client}
                  onChange={(event: any, newValue: any) => {
                    setUF(newValue);
                  }}
                  sx={{
                    "& input": { height: "27px" },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Profissão{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  ></MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.occupation == null)}
                  {...register("occupation")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.occupation?.message}
                </MDTypography>
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>
        <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
          <MDBox
            sx={{
              height: "13px",
              width: "5px",
              backgroundColor: "#0B2F36",
              mr: 1,
            }}
          ></MDBox>
          <MDTypography
            variant="subtitle2"
            sx={{ color: "#0B2F36", fontWeight: 500 }}
          >
            Contato
          </MDTypography>
        </DialogContentText>
        <MDBox
          noValidate
          component="form"
          my={2}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  E-mail{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  ></MDTypography>
                </InputLabel>
                <MDInput
                  type="text"
                  error={!(errors.email == null)}
                  {...register("email")}
                  sx={{
                    boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                  fullWidth
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.email?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Celular{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  ></MDTypography>
                </InputLabel>
                <MDInput
                  type="text"
                  mask={"(99) 99999-9999"}
                  error={!(errors.phone == null)}
                  {...register("phone")}
                  sx={{
                    boxShadow: "inset -3px 3px 6px  rgba(0, 0, 0, 0.103)",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                  fullWidth
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.phone?.message}
                </MDTypography>
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>
        <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
          <MDBox
            sx={{
              height: "13px",
              width: "5px",
              backgroundColor: "#0B2F36",
              mr: 1,
            }}
          ></MDBox>
          <MDTypography
            variant="subtitle2"
            sx={{ color: "#0B2F36", fontWeight: 500 }}
          >
            Endereço
          </MDTypography>
        </DialogContentText>
        <MDBox
          noValidate
          component="form"
          my={2}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  CEP{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.cep == null)}
                  {...register("cep")}
                  mask={"99999-999"}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.cep?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Rua{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.street == null)}
                  {...register("street")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.street?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Número{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.number == null)}
                  {...register("number")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.number?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Bairro{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.neighborhood == null)}
                  {...register("neighborhood")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.neighborhood?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Cidade{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.city == null)}
                  {...register("city")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.city?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Estado{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  >

                  </MDTypography>
                </InputLabel>

                <Autocomplete
                  disablePortal
                  size="small"
                  noOptionsText="Estado inexistente"
                  {...defaultPropsState}
                  value={state ?? null}
                  onChange={(event: any, newValue: any) => {
                    setState(newValue);

                    if (newValue) {
                      setValue("state", newValue.nome);
                    } else {
                      setValue("state", "");
                    }
                  }}
                  sx={{
                    "& input": { height: "27px" },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.state?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel sx={{ mb: 1, color: "#0B2F36" }}>
                  Complemento{" "}
                  <MDTypography
                    sx={{ fontSize: "12px", color: "#f00", display: "inline" }}
                  ></MDTypography>
                </InputLabel>
                <MDInput
                  error={!(errors.complement == null)}
                  {...register("complement")}
                  type="text"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.complement?.message}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <MDButton
          disabled={disableButton}
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          color="success"
          size="medium"
          sx={{
            width: "150px",
            textTransform: "capitalize",
            color: "#DDCA92",
            background:
              "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

            border: "1px solid #DDCA92",
            borderRadius: "15px",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#0B2F36",
              boxShadow: "none",
            },
          }}
        >
          {disableButton ? <CircularProgress color="primary" /> : "  Cadastrar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateUserDialog;
