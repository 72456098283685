/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler, Controller } from "react-hook-form";
import fileDownload from "js-file-download";

import { lawApi } from "../../../../../services/api";

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import FileInput from "../../FileInput";
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";
import dataUF from "../../../../../brazilian-states.json";
import { Link } from "react-router-dom";
import DeleteProcessFileDialog from "../DeleteProcessFileDialog";
import axios from "axios";

interface ICustomer {
  [x: string]: any;
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
  active: boolean;
}

interface IShowProcessDialog {
  openShowProcessDialog: boolean;
  handleCloseShowProcessDialog: () => void;
  handleOpenDeleteProcessDialog: (data: any) => void;
  toastFeedbackMessage: (type: string, message: string) => void;
  processData: any;
  customers: any;
  fetchData: () => Promise<void>;
  clientData: ICustomer[];
  matches: boolean;
}

interface IInputs {
  code: string;
  description: string | null;
  status: string;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  code: yup.string().default(""),
  description: yup.string().default(""),
  status: yup.string().default(""),
});

const processStatus = [
  { value: "Iniciado", text: "Iniciado", color: "#19953C" },
  { value: "Andamento", text: "Em andamento", color: "#DDCA92" },
  { value: "Finalizado", text: "Finalizado", color: "#6B1111" },
];

function ShowProcessDialog({
  openShowProcessDialog,
  handleCloseShowProcessDialog,
  handleOpenDeleteProcessDialog,
  toastFeedbackMessage,
  processData,
  customers,
  fetchData,
  clientData,
  matches,
}: IShowProcessDialog) {
  const {
    reset,
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      code: processData?.code,
    },
  });

  const [fileBaseURL, setFileBaseURL] = useState("");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [updateProcess, setUpdateProcess] = useState<boolean>(false);
  const [clientWasChose, setClientWasChose] = useState<boolean>(false);
  const [userEdited, setUserEdited] = useState<boolean>(false);
  const [isAllFilesSet, setIsAllFilesSet] = useState<boolean>(false);
  const [openDeleteProcessFileDialog, setOpenDeleteProcessFileDialog] =
    useState<boolean>(false);
  const [client, setClient] = useState<any>();
  const [errorClient, setErrorClient] = useState<string>("");
  const [errorState, setErrorState] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<string>("");
  const [errorCNJ, setErrorCNJ] = useState<string>("");
  const [attachedFiles, setAttachedFiles] = useState<any>([]);
  const [newFiles, setNewFiles] = useState<any>([]);
  const [UF, setUF] = useState<{ nome: string; sigla: string } | null>();
  const [indexProcessFileToDelete, setIndexProcessFileToDelete] =
    useState<any>();
  const [isRemoveProcessFileToDelete, setIsRemoveProcessFileToDelete] =
    useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);

  const handleOpenDeleteProcessFileDialog = (indexFile: number) => {
    setOpenDeleteProcessFileDialog(true);
    setIndexProcessFileToDelete(indexFile);
  };

  const defaultProps = {
    options: customers,
    getOptionLabel: (option: ICustomer) => `${option.fullname}${option.cpf ? ` - ${option.cpf}` : ""}`,
  };

  const defaultPropsUF = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.nome,
  };

  const getClient = useCallback(() => {
    if (customers[0]) {
      const _customer = customers.find((c: any) => c._id === clientData[0]._id);

      if (_customer !== undefined) {
        return _customer;
      }
    }

    return null;
  }, [customers, clientData]);

  useEffect(() => {
    const _currentClient = getClient();

    setClient(_currentClient);
  }, [getClient]);

  useEffect(() => {
    const BASE_URL_TO_FILE = process.env.REACT_APP_BASE_URL_TO_FILE;

    if (BASE_URL_TO_FILE !== undefined) {
      setFileBaseURL(BASE_URL_TO_FILE);
    }
  }, []);

  const getUF = useCallback(() => {
    if (processData) {
      const _state = dataUF.UF.find((c: any) => c.nome === processData.state);

      if (_state !== undefined) {
        return _state;
      }
    }

    return null;
  }, [processData]);

  useEffect(() => {
    const _currentUF = getUF();

    setUF(_currentUF);
  }, [getUF]);

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    setDisableButton(true);

    let isError = false;

    if (!client) {
      setErrorClient("Selecione um cliente.");
      isError = true;
    }

    if (!UF) {
      isError = true;
      setErrorState("Selecione um estado.");
    }

    if (!data.status) {
      isError = true;
      setErrorStatus("Selecione o status do processo.");
    }

    if (!data.code) {
      isError = true;
      setErrorCNJ("Deve ter no mínimo 8 caracteres");
    }

    if (isError) return;

    const formData = new FormData();
    const { description, code, status } = data;

    const processID = processData?._id;
    const dataSend = {
      client_id: client._id,
      application_id: client.application_id,
      description,
      code,
      notes: null,
      status,
      state: UF ? UF.nome : null,
    };

    files.map((file: any) => {
      formData.append(`arquivo`, file);
    });

    formData.append("data", JSON.stringify(dataSend));

    try {
      const response = await lawApi.put(`/process/${processID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const data = response.data;

      if (data) {
        toastFeedbackMessage("success", "Processo atualizado com sucesso.");
      }

      void fetchData();
      handleResetInputAndFunction();
    } catch (err: any) {
      // console.log(err.message);
      const code = err.response.data.statusCode;
      const message = err.response.data.message;

      if (code === 400) {
        toastFeedbackMessage("error", message);
      } else if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização do processo. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização do processo. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização do processo. Verifique os dados informados!",
        );
      }
    } finally {
      setDisableButton(false);
    }
  };

  const handleResetInputAndFunction = (): void => {
    setFiles([]);
    setUF(null);
    setClient(null);
    setErrorCNJ("");
    setErrorClient("");
    setErrorState("");
    setErrorStatus("");
    setClientWasChose(false);
    setIsAllFilesSet(false);
    reset();
    setUserEdited(false);
    setUpdateProcess(false);
    handleCloseShowProcessDialog();
  };

  const handleUpdateProcess = () => {
    const fields = ["code", "description", "state", "status"];
    fields.forEach((field: any) => {
      setValue(field, processData[field]);
    });

    setAttachedFiles(processData?.files);

    setUpdateProcess(true);
    const _uf = dataUF.UF.find((_state) => _state.nome === processData.state);
    setUF(_uf);
  };

  const handleCloseUpdateProcess = () => {
    setUpdateProcess(false);
  };

  const handleAddNewFile = () => {
    setNewFiles([
      ...newFiles,
      { file: { title: "", description: "", version: "", path: "" } },
    ]);
  };

  const handleRemoveAttachedFile = (index: number): void => {
    // eslint-disable-next-line prefer-const
    let data = [...attachedFiles];

    data.splice(index, 1);
    setAttachedFiles(data);
    // handleOpenDeleteProcessFileDialog(processData, index);
  };

  useEffect(() => {
    if (isRemoveProcessFileToDelete) {
      handleRemoveAttachedFile(indexProcessFileToDelete);
      setIsRemoveProcessFileToDelete(false);
    }
  }, [
    isRemoveProcessFileToDelete,
    handleRemoveAttachedFile,
    indexProcessFileToDelete,
  ]);

  const handleCloseDeleteProcessFileDialog = () => {
    setOpenDeleteProcessFileDialog(false);
  };

  const handleAttachedFileChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    // eslint-disable-next-line prefer-const
    let data = [...attachedFiles];
    data[index][event.target.name].title = `Processo ${index + 1}`;

    if (event.target.files != null) {
      data[index][event.target.name].path = event.target.files[0].name;
    }

    setAttachedFiles(data);
  };

  const handleNewFileChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    // eslint-disable-next-line prefer-const
    let data = [...newFiles];
    const dataFile = [...files];

    data[index][event.target.name].title = `Processo ${index + 1}`;

    if (event.target.files != null) {
      data[index][event.target.name].path = event.target.files[0].name;
      dataFile[index] = event.target.files[0];
    }

    setFiles(dataFile);
    setNewFiles(data);
  };

  const handleRemoveNewFile = (index: number): void => {
    // eslint-disable-next-line prefer-const
    let data = [...newFiles];
    const dataFiles = [...files];

    data.splice(index, 1);
    dataFiles.splice(index, 1);

    setNewFiles(data);
    setFiles(dataFiles);
  };

  useEffect(() => {
    // console.log(client, userEdited, clientWasChose);
    if (client?._id != null) {
      setClientWasChose(true);
    } else if (client?._id == null && !userEdited) {
      setClientWasChose(true);
    } else {
      setClientWasChose(false);
    }
  }, [client, clientWasChose, userEdited]);

  useEffect(() => {
    if (newFiles.length > 0 && attachedFiles.length === 0) {
      const totalFiles = newFiles
        .map((field: any) => {
          if (field.file.title !== "") {
            return field;
          }
        })
        .filter((elem: any) => elem !== undefined);

      if (totalFiles.length === newFiles.length) {
        setIsAllFilesSet(true);
      } else {
        setIsAllFilesSet(false);
      }
    } else if (newFiles.length > 0 && attachedFiles.length > 0) {
      const totalFiles = newFiles
        .map((field: any) => {
          if (field.file.title !== "") {
            return field;
          }
        })
        .filter((elem: any) => elem !== undefined);

      if (totalFiles.length === newFiles.length) {
        setIsAllFilesSet(true);
      } else {
        setIsAllFilesSet(false);
      }
    } else if (newFiles.length === 0 && attachedFiles.length > 0) {
      setIsAllFilesSet(true);
    } else if (attachedFiles.length === 0) {
      setIsAllFilesSet(false);
    }
  }, [newFiles, attachedFiles]);

  useEffect(() => {
    if (clientWasChose && isAllFilesSet) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [clientWasChose, isAllFilesSet]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setClient(null);
      setIsAllFilesSet(false);
      setUserEdited(false);
      setUpdateProcess(false);
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const handleDownload = async (url: string, filename: string) => {
    await axios
      .get(url, {
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "GET",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <Dialog
      open={openShowProcessDialog}
      onClose={() => {
        handleResetInputAndFunction();
      }}
      fullWidth={true}
      maxWidth="xl"
      fullScreen={!!matches}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          handleResetInputAndFunction();
        }}
      >
        <MDBox display="flex" flexDirection="column" alignItems="center">
          <MDTypography
            sx={{ color: "#0B2F36", fontSize: matches ? "20px" : "24px" }}
          >
            {updateProcess ? "Editar" : "Visualizar"} processo
          </MDTypography>
          <MDTypography
            sx={{
              color: "#0B2F36",
              fontSize: matches ? "18px" : "22px",
              fontWeight: 600,
            }}
          >
            {processData?.code}
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>
      <DialogContent
        sx={{ padding: matches ? "30px 1rem 0 1rem" : "0 50px 0 50px" }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <MDBox display="flex" flexDirection="column">
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDBox
                  sx={{
                    height: "13px",
                    width: "5px",
                    backgroundColor: "#DDCA92",
                    mr: 1,
                  }}
                ></MDBox>
                <MDTypography
                  variant="subtitle2"
                  sx={{ fontWeight: 500, color: "#0B2F36" }}
                >
                  Cliente
                </MDTypography>
              </MDBox>
              <MDBox
                noValidate
                component="form"
                my={2}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  {updateProcess ? (
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                          Nome{" "}
                          <MDTypography
                            sx={{
                              fontSize: "12px",
                              color: "#f00",
                              display: "inline",
                            }}
                          >
                            *
                          </MDTypography>
                        </InputLabel>
                        <Autocomplete
                          disablePortal
                          size="small"
                          {...defaultProps}
                          value={client ?? null}
                          noOptionsText="Nenhum cliente cadastrado"
                          // defaultValue={client}
                          onChange={(event: any, newValue: any) => {
                            setClient(newValue);
                          }}
                          sx={{
                            "& input": { height: "27px" },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          fullWidth
                        />
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errorClient}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                          Nome{" "}
                          <MDTypography
                            sx={{
                              fontSize: "12px",
                              color: "#f00",
                              display: "inline",
                            }}
                          >
                            *
                          </MDTypography>
                        </InputLabel>
                        <MDInput
                          disabled
                          type="text"
                          variant="outlined"
                          value={clientData[0]?.fullname}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      </MDBox>
                    </Grid>
                  )}
                </Grid>
              </MDBox>
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDBox
                  sx={{
                    height: "13px",
                    width: "5px",
                    backgroundColor: "#DDCA92",
                    mr: 1,
                  }}
                ></MDBox>
                <MDTypography
                  variant="subtitle2"
                  sx={{ fontWeight: 500, color: "#0B2F36" }}
                >
                  Dados do processo
                </MDTypography>
              </MDBox>
              <MDBox
                noValidate
                component="form"
                my={2}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                        CNJ{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      {updateProcess ? (
                        <>
                          <MDInput
                            error={!(errors.code == null)}
                            {...register("code")}
                            name="code"
                            type="text"
                            variant="outlined"
                            mask={"9999999-99.9999.9.99.9999"}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#DDCA92",
                                },
                              },
                            }}
                            fullWidth
                          />
                          <MDTypography
                            sx={{ fontSize: "12px", color: "#f00" }}
                          >
                            {errorCNJ || errors.code?.message}
                          </MDTypography>
                        </>
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          variant="outlined"
                          value={processData?.code}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.code?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                        Estado realizado o processo{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      {updateProcess ? (
                        <Autocomplete
                          disablePortal
                          noOptionsText="Estado inexistente"
                          size="small"
                          {...defaultPropsUF}
                          value={UF ?? null}
                          // defaultValue={client}
                          onChange={(event: any, newValue: any) => {
                            setUF(newValue);
                          }}
                          sx={{
                            "& input": { height: "27px" },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          fullWidth
                        />
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          variant="outlined"
                          value={processData?.state}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errorState}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                        Status{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      {updateProcess ? (
                        <Controller
                          name="status"
                          defaultValue={processData?.status}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              sx={{
                                height: "45px",
                                "&.MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#DDCA92",
                                  },

                                  ".css-1kvvgjv-MuiSvgIcon-root-MuiSelect-icon, .css-ixc2ow-MuiSvgIcon-root-MuiSelect-icon":
                                  {
                                    display: "initial",
                                  },
                                },
                              }}
                            >
                              {processStatus.map((status, index) => (
                                <MenuItem
                                  key={index}
                                  sx={{
                                    color: status.color,
                                  }}
                                  value={status.value}
                                >
                                  {status.text}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          value={processData?.status}
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errorStatus}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1, color: "#2C2C2C" }}>
                        Descrição
                      </InputLabel>
                      {updateProcess ? (
                        <MDInput
                          error={!(errors.description == null)}
                          {...register("description")}
                          type="text"
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          multiline
                          rows={5}
                          fullWidth
                        />
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          value={processData?.description}
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          multiline
                          rows={5}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.description?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <MDBox display="flex" flexDirection="column">
              <DialogContentText sx={{ mb: 3, color: "#2C2C2C" }}>
                Documentos anexados
              </DialogContentText>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDBox display="flex" flexDirection="column" gap="10px 0px">
                    {!updateProcess &&
                      processData?.files.map((file: any, index: number) => (
                        <MDBox display="flex" key={index} sx={{ mb: 1 }}>
                          <MDButton
                            variant="gradient"
                            sx={{
                              backgroundColor: "#0B2F36",
                              color: "#f1f1f1",
                              "&:hover": {
                                backgroundColor: "#0B1F36",
                              },
                              height: "44px",
                              borderRadius: "5px 0 0 5px",
                              // flex: 2,
                              textTransform: "capitalize",
                            }}
                            onClick={() => {
                              void handleDownload(
                                `${fileBaseURL}/${file.path}`,
                                file.title,
                              );
                            }}
                          >
                            {/* <a
                              download
                              href={`${fileBaseURL}/${file.path}`}
                              style={{ color: "#f1f1f1" }}
                            > */}
                            Visualizar
                            {/* </a> */}
                          </MDButton>
                          <MDInput
                            disabled
                            size="large"
                            // sx={{ height: "40px", borderRadius: "0 5px 5px 0" }}
                            // @ts-expect-error
                            InputProps={{
                              style: { borderRadius: "0 5px 5px 0", flex: 2 },
                            }}
                            label={file.title}
                            fullWidth
                          />
                        </MDBox>
                      ))}

                    {updateProcess &&
                      attachedFiles.map((file: any, index: number) => (
                        <FileInput
                          key={index}
                          index={index}
                          handleRemoveField={handleOpenDeleteProcessFileDialog}
                          handleFormChange={handleAttachedFileChange}
                          file={file}
                        />
                      ))}
                  </MDBox>
                </Grid>
              </Grid>
              {updateProcess && (
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <DialogContentText sx={{ mt: 3, mb: 3, color: "#2C2C2C" }}>
                    Anexar novos documentos
                  </DialogContentText>
                  <MDButton
                    onClick={handleAddNewFile}
                    variant="gradient"
                    size="small"
                    color="success"
                    sx={{
                      boxShadow: "none",
                      height: "35px",
                      "&:hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    Anexar&nbsp;{" "}
                    <Icon sx={{ color: "#DDCA92", fontSize: "40px" }}>add</Icon>
                  </MDButton>
                </MDBox>
              )}
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDBox display="flex" flexDirection="column">
                    {updateProcess &&
                      newFiles.map((file: any, index: number) => {
                        return (
                          <MDBox mb={1} key={index}>
                            <FileInput
                              index={index}
                              handleRemoveField={handleRemoveNewFile}
                              handleFormChange={handleNewFileChange}
                            />
                          </MDBox>
                        );
                      })}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {updateProcess ? (
          <MDButton
            onClick={() => {
              handleCloseUpdateProcess();
            }}
            variant="outlined"
            sx={{
              width: "130px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#0B2F36",
              border: "2px solid #DDCA92",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#fff",
                border: "2px solid #DDCA92",
              },
            }}
          >
            Cancelar
          </MDButton>
        ) : (
          <MDButton
            onClick={() => {
              handleOpenDeleteProcessDialog(processData);
            }}
            variant="outlined"
            sx={{
              width: "130px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#0B2F36",
              border: "2px solid #DDCA92",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#fff",
                border: "2px solid #DDCA92",
              },
            }}
          >
            Excluir
          </MDButton>
        )}
        <MDButton
          // disabled={updateProcess ? disableButton : false}
          onClick={updateProcess ? handleSubmit(onSubmit) : handleUpdateProcess}
          variant="outlined"
          sx={{
            width: "130px",

            padding: "10px",
            textTransform: "capitalize",
            color: "#DDCA92",
            background:
              "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

            border: "1px solid #DDCA92",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#0B2F36",
              border: "1px solid #DDCA92",
              color: "#DDCA92",
            },
          }}
        >
          {updateProcess ? "Confirmar" : "Atualizar"}
        </MDButton>
      </DialogActions>
      <DeleteProcessFileDialog
        openDeleteProcessDialog={openDeleteProcessFileDialog}
        processToDelete={processData}
        toastFeedbackMessage={toastFeedbackMessage}
        handleCloseDeleteProcessDialog={handleCloseDeleteProcessFileDialog}
        index={indexProcessFileToDelete}
        fetchSubusers={fetchData}
        setIsRemove={setIsRemoveProcessFileToDelete}
      />
    </Dialog>
  );
}

export default ShowProcessDialog;
