const textHelp = {
    copyText: (text) => {
        if (text) {
            navigator.clipboard.writeText(text);

            return true
        }
        return false
    }
}
export default textHelp