/* eslint-disable array-callback-return */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { lawApi } from "../../services/api";

// @mui material components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Data

// Dashboard components
import { toast } from "react-hot-toast";
import ProcessTable from "./components/ProcessTable";
import CreateProcessDialog from "./components/dialogs/CreateProcessDialog";
import DeleteProcessDialog from "./components/dialogs/DeleteProcessDialog";
import ShowProcessDialog from "./components/dialogs/ShowProcessDialog";
import secureLocalStorage from "react-secure-storage";
import DeleteProcessFileDialog from "./components/dialogs/DeleteProcessFileDialog";

// @ts-expect-error
const useEffectOnce = (callback, when) => {
  const hasRunOnce = useRef(false);
  useEffect(() => {
    if (when && !hasRunOnce.current) {
      callback();
      hasRunOnce.current = true;
    }
  }, [callback, when]);
};

interface ICustomer {
  [x: string]: any;
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
  active: boolean;
}

interface IActiveProcess {
  _id: string;
  active: boolean;
  application_id: string;
  client_id: string;
  code: string;
  created_at: string;
  deleted_at: string;
  description: string;
  files: object[];
  notes: object[];
  clientCpf: string;
  clientName: string;
}

function Process() {
  const { user } = useAuth();
  const theme = useTheme();

  const [customers, setCustomers] = useState<readonly ICustomer[]>([
    {
      address_id: "",
      application_id: "",
      birth_date: "",
      cpf: "",
      created_at: "",
      fullname: "",
      identity: "",
      issuing_body: "",
      occupation: "",
      social_status: "",
      uf: "",
      updated_at: "",
      __v: 0,
      _id: "",
      active: true,
    },
  ]);
  const [activeProcesses, setActiveProcesses] = useState<IActiveProcess>({
    _id: "",
    active: false,
    application_id: "",
    client_id: "",
    code: "",
    created_at: "",
    deleted_at: "",
    description: "",
    files: [],
    notes: [],
    clientCpf: "",
    clientName: "",
  });

  const [processData, setProcessData] = useState<any>();
  const [clientData, setClientData] = useState<ICustomer[]>([
    {
      address_id: "",
      application_id: "",
      birth_date: "",
      cpf: "",
      created_at: "",
      fullname: "",
      identity: "",
      issuing_body: "",
      occupation: "",
      social_status: "",
      uf: "",
      updated_at: "",
      __v: 0,
      _id: "",
      active: true,
    },
  ]);
  const [processToDelete, setProcessToDelete] = useState<any>();
  const [indexProcessFileToDelete, setIndexProcessFileToDelete] =
    useState<any>();
  const [analytics, setAnalytics] = useState<any>();

  const [openNewProcessDialog, setOpenNewProcessDialog] =
    useState<boolean>(false);
  const [openShowProcessDialog, setOpenShowProcessDialog] =
    useState<boolean>(false);
  const [openDeleteProcessDialog, setOpenDeleteProcessDialog] =
    useState<boolean>(false);
  const [openDeleteProcessFileDialog, setOpenDeleteProcessFileDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [customersFetched, setCustomersFetched] = useState<boolean>(false);

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenNewProcessDialog = () => {
    setOpenNewProcessDialog(true);
  };

  const handleCloseNewProcessDialog = () => {
    setOpenNewProcessDialog(false);
    secureLocalStorage.removeItem("client_id_selected");
  };

  const handleOpenShowProcessDialog = (data: any) => {
    setProcessData(data);

    const client = customers.filter(
      (customer: ICustomer) => customer._id === data.client_id,
    );

    setClientData(client);

    setOpenShowProcessDialog(true);
  };

  const handleCloseShowProcessDialog = () => {
    setOpenShowProcessDialog(false);
  };

  const handleOpenDeleteProcessDialog = (process: any | null) => {
    setOpenShowProcessDialog(false);
    setOpenDeleteProcessDialog(true);
    setProcessToDelete(process);
  };

  const handleOpenDeleteProcessFileDialog = (
    process: any | null,
    indexFile: number,
  ) => {
    // setOpenShowProcessDialog(false);
    setOpenDeleteProcessFileDialog(true);
    setProcessToDelete(process);
    setIndexProcessFileToDelete(indexFile);
  };

  const handleCloseDeleteProcessDialog = () => {
    setOpenDeleteProcessDialog(false);
  };

  const toastFeedbackMessage = (type: string, message: string): any => {
    if (type === "error") {
      return toast.error(`${message}`, {
        duration: 5000,
      });
    }

    return toast.success(`${message}`, {
      duration: 5000,
    });
  };

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    const client = secureLocalStorage.getItem("client_id_selected") as string;
    if (client) {
      handleOpenNewProcessDialog();
    }

    const endpoints = ["/clients", "/process", "/process/analytics/"];

    try {
      await Promise.all(
        endpoints.map(async (endpoint) => await lawApi.get(endpoint)),
      ).then(
        ([
          { data: clients },
          { data: processes },
          { data: processAnalytics },
        ]) => {
          const activeProcesses = processes.data.processes;

          setCustomers(clients.data.clients);
          setActiveProcesses(activeProcesses);
          setAnalytics(processAnalytics.data.analytics_processes);
        },
      );
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!customersFetched) {
      setCustomersFetched(true);
    }
  }, [customersFetched]);

  useEffectOnce(() => {
    void fetchData();
  }, customersFetched);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3} sx={{ maxWidth: 271 }}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="groups"
                title="Processos iniciado"
                count={analytics?.total_processes_init}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ maxWidth: 271 }}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="note_add"
                title="Processos em andamento"
                count={analytics?.total_processes_started}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ maxWidth: 271 }}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="folder"
                title="Processos finalizados"
                count={analytics?.total_completed_processes_}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <ProcessTable
                handleOpenNewProcessDialog={handleOpenNewProcessDialog}
                handleOpenShowProcessDialog={handleOpenShowProcessDialog}
                activeProcesses={activeProcesses}
                customers={customers}
                matches={matches}
                loading={loading}
              />
            </Grid>
          </Grid>
        </MDBox>
        <CreateProcessDialog
          openNewProcessDialog={openNewProcessDialog}
          handleCloseNewProcessDialog={handleCloseNewProcessDialog}
          toastFeedbackMessage={toastFeedbackMessage}
          fetchData={fetchData}
          customers={customers}
          matches={matches}
        />

        <ShowProcessDialog
          openShowProcessDialog={openShowProcessDialog}
          handleCloseShowProcessDialog={handleCloseShowProcessDialog}
          handleOpenDeleteProcessDialog={handleOpenDeleteProcessDialog}
          // handleOpenDeleteProcessFileDialog={handleOpenDeleteProcessFileDialog}
          toastFeedbackMessage={toastFeedbackMessage}
          processData={processData}
          customers={customers}
          fetchData={fetchData}
          clientData={clientData}
          matches={matches}
        />

        <DeleteProcessDialog
          openDeleteProcessDialog={openDeleteProcessDialog}
          processToDelete={processToDelete}
          toastFeedbackMessage={toastFeedbackMessage}
          handleCloseDeleteProcessDialog={handleCloseDeleteProcessDialog}
          fetchSubusers={fetchData}
        />

        {/* <DeleteProcessFileDialog
          openDeleteProcessDialog={openDeleteProcessFileDialog}
          processToDelete={processToDelete}
          toastFeedbackMessage={toastFeedbackMessage}
          handleCloseDeleteProcessDialog={handleCloseDeleteProcessFileDialog}
          index={indexProcessFileToDelete}
          fetchSubusers={fetchData}
        /> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Process;
