/* eslint-disable no-useless-escape */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import {
  Autocomplete,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  type RadioProps,
  TextField,
  styled,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import "dayjs/locale/br";
import { useEffect, useState } from "react";
import { lawApi } from "../../../../../services/api";
import dayjs from "dayjs";
import MDInput from "../../../../../components/MDInput";
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";
import toast from "react-hot-toast";

interface ISchedulingLink {
  matches: boolean;
  open: boolean;
  handleClose: () => void;
  user: any | undefined;
  edit: boolean;
  scheduleSelected?: string;
  toastFeedbackMessage: (type: string, message: string) => void;
  handleScheduleToDelete: (schedule: any) => void;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IClient {
  client_name: string;
  // client_last_name: string;
  client_email: string;
}

interface ISchedule {
  date: any;
  title: string;
  description: string;
  start_time: string;
  end_time: string;
  // guests: string[];
  link: string;
  local: string;
}

interface IInputs {
  title: string;
  // description: string;
  email: string | null;
  link: string | null;
  address: string | null;
  client_name: string;
  // client_last_name: string;
  client_email: string;
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "1px auto #0B2F36",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    // backgroundColor: "#0B2F36",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#0B2F36",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#0B2F36,#0B2F36 28%,transparent 32%)",
    border: "2px auto #fff",
    borderRadius: "50%",

    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#0B2F36",
  },
});

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  title: yup
    .string()
    .min(5, "No mínimo 5 caracteres")
    .max(100, "No máximo 100 caracteres")
    .required("Informe o nome título do agendamento"),
  // description: yup.string().nullable().default(""),
  address: yup.string().nullable().default(""),
  client_name: yup.string().default(""),
  // client_last_name: yup.string().required("Informe o sobrenome do cliente"),
  client_email: yup
    .string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Formato de e-mail inválido")
    .default(""),
  email: yup
    .string()
    .nullable()
    .default("")
    .matches(/^$|^.*@.*\..*$/, "Formato de e-mail inválido"),
  link: yup
    .string()
    .matches(
      /^$|^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
      "Formato de link inválido",
    )
    .nullable()
    .default(""),
});

const ShowScheduleForm = ({
  open,
  matches,
  handleClose,
  user,
  edit,
  scheduleSelected,
  toastFeedbackMessage,
  handleScheduleToDelete,
  setUpdated,
}: ISchedulingLink) => {
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });

  const [schedule, setSchedule] = useState<ISchedule>({
    date: "",
    start_time: "",
    end_time: "",
    title: "",
    description: "",
    link: "",
    local: "",
  });

  const [fieldClient, setFieldClient] = useState<IClient>({
    client_name: "",
    // client_last_name: "",
    client_email: "",
  });

  const [optionsStartTime, setOptionsStartTime] = useState<string[]>([]);
  const [optionsEndTime, setOptionsEndTime] = useState<string[]>([]);
  const [startTimeEdit, setStartTimeEdit] = useState("");
  const [endTimeEdit, setEndTimeEdit] = useState("");
  const [dateEdit, setDateEdit] = useState("");
  const [guest, setGuest] = useState<string>("");
  const [guests, setGuests] = useState<string[]>(["christian@live.com"]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [loadingAvailableHours, setLoadingAvailableHours] =
    useState<boolean>(false);
  const [blockEmptyMail, setBlockEmptyMail] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [typeMeet, setTypeMeet] = useState<string>("remota");
  const [isFirstTimeEdit, setIsFirstTimeEdit] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      void getAddress(user.address_id);
    }
  }, [typeMeet, user]);

  useEffect(() => {
    if (edit) {
      void getSchedule();
      setIsFirstTimeEdit(true);
    }
  }, [scheduleSelected, open, isUpdating]);

  const getStartTimeEdit = async (date: any | null) => {
    try {
      const checkValidDate = dayjs(date).format() === "Invalid Date";
      const currentDate = new Date();
      const _date = new Date(date);

      if (isUpdating) {
        if (dayjs(date).year().toString().length === 4) {
          if (checkValidDate) {
            return toast.error("Data inválida", { duration: 5000 });
          }

          if (
            _date.getDate() < currentDate.getDate() &&
            _date.getMonth() <= currentDate.getMonth() &&
            _date.getFullYear() <= currentDate.getFullYear()
          ) {
            return toast.error("Data informada é menor que a data atual", {
              duration: 5000,
            });
          }

          if (user) {
            const payload = {
              user_id: user.id,
              date: dayjs(date).format(),
            };

            const response = await lawApi.post(
              "/schedules/availableHourUser",
              payload,
              {
                params: {
                  current_id: scheduleSelected,
                },
              },
            );

            setSchedule((prevState) => ({
              ...prevState,
              date,
            }));

            setOptionsStartTime(response.data);

            if (!isFirstTimeEdit) {
              setOptionsEndTime([]);
              setSchedule((prevState) => ({
                ...prevState,
                end_time: "",
              }));
            }

            setIsFirstTimeEdit(false);
          }

          setLoadingAvailableHours(false);
        }

        // const payload = {
        //   user_id: user.id,
        //   date: schedule.date,
        // };

        // const responseHourStart = await lawApi.post(
        //   "/schedules/availableHourUser",
        //   payload,
        //   {
        //     params: {
        //       current_id: scheduleSelected,
        //     },
        //   },
        // );

        // setOptionsStartTime(responseHourStart.data);
      }
    } catch (error: any) {
      const code = error.response.data.statusCode;
      const message = error.response.data.message;

      if (code === 400) {
        toast.error(message, { duration: 5000 });
      } else if (code === 404) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      }
    } finally {
      setLoadingAvailableHours(false);
    }
  };

  const getEndTimeEdit = async () => {
    try {
      if (isUpdating) {
        const payload = {
          user_id: user.id,
          date: schedule.date,
        };

        const responseHourEnd = await lawApi.post(
          "/schedules/availableHourUser",
          payload,
          {
            params: {
              start_time: schedule.start_time,
              current_id: scheduleSelected,
            },
          },
        );

        const arrEndTime = responseHourEnd.data.filter(
          (hour: string) => hour > schedule.start_time,
        );

        // if (schedule.date === dateEdit) {
        //   arrEndTime.push(schedule.end_time);
        //   arrEndTime.sort();
        // }
        // console.log("arrEndTime ==> ", arrEndTime);

        setOptionsEndTime(arrEndTime);
      }
    } catch (error: any) {
      const code = error.response.data.statusCode;
      const message = error.response.data.message;

      if (code === 400) {
        toast.error(message, { duration: 5000 });
      } else if (code === 404) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      }
    }
  };

  const getSchedule = async (): Promise<void> => {
    if (scheduleSelected) {
      const response = await lawApi.get(`/schedules/${scheduleSelected}`);

      if (Boolean(response.data.data) && Boolean(response.data.data.schedule)) {
        const date = dayjs(response.data.data.schedule.date);

        setSchedule({
          date,
          title: response.data.data.schedule.title,
          description: response.data.data.schedule.description,
          start_time: response.data.data.schedule.start_time,
          end_time: response.data.data.schedule.end_time,
          link: response.data.data.schedule.link,
          local: response.data.data.schedule.local,
        });

        setGuests((prevState) => [
          // ...prevState,
          ...response.data.data.schedule.guests,
        ]);

        setValue("title", response.data.data.schedule.title);
        setValue("address", response.data.data.schedule.local);
        setValue("link", response.data.data.schedule.link);
        setValue("client_name", response.data.data.schedule.client.name);
        setValue("client_email", response.data.data.schedule.client.email);
        setValue("email", "");

        const _address = await getAddress(user.address_id);

        if (response.data.data.schedule.local === _address) {
          setTypeMeet("other");
        }

        void getStartTimeEdit(response.data.data.schedule.date);
        void getEndTimeEdit();

        setStartTimeEdit(response.data.data.schedule.start_time);
        setEndTimeEdit(response.data.data.schedule.end_time);
        setDateEdit(date.toString());

        setFieldClient({
          client_name: response.data.data.schedule.client.name,
          // client_last_name: response.data.data.schedule.client.last_name,
          client_email: response.data.data.schedule.client.email,
        });
      }
    }
  };

  const handleGuest = (event: any) => {
    const validEmail = event.target.value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    if (!validEmail) {
      setGuest(event.target.value);
      setBlockEmptyMail(true);
    } else {
      setGuest(event.target.value);
      setValue("email", event.target.value);
      setBlockEmptyMail(false);
    }
  };

  const handleAddGuest = (): void => {
    // setGuests((prevState) => [...prevState, guest]);
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validEmail = guest.match(validRegex);

    if (!validEmail) {
      toast.error("E-mail inválido", {
        duration: 5000,
      });
    } else {
      guests.push(guest);
      setGuests(guests);

      setGuest("");
      reset({
        email: "",
      });
      setBlockEmptyMail(true);
    }
  };

  const handleRemoveGuest = (index: number) => {
    let data = [...guests];

    data.splice(index, 1);
    setGuests(data);
  };

  const handleUpdateProcess = () => {
    const scheduleFields = ["title", "link"];

    const clientFields = ["client_name", "client_email"];

    scheduleFields.forEach((field: string) => {
      // @ts-expect-error
      setValue(field, schedule[field]);
    });

    clientFields.forEach((field: string) => {
      // @ts-expect-error
      setValue(field, fieldClient[field]);
    });

    setIsUpdating(true);
  };

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    setDisableButton(true);

    const formData = {
      title: data.title,
      client: {
        name: data.client_name,
        // last_name: data.client_last_name,
        email: data.client_email,
      },
      local: typeMeet !== "remota" ? data.address : null,
      user_id: user.id,
      guests,
      description: "",
      date: schedule.date,
      start_time: schedule.start_time,
      end_time: schedule.end_time,
      link: typeMeet === "remota" ? data.link : null,
    };

    try {
      const response = await lawApi.put(
        `/schedules/${scheduleSelected}`,
        formData,
      );
      const data = response;

      if (data.data.error) {
        toastFeedbackMessage(
          "error",
          data.data.message
            ? data.data.message
            : "Ocorreu um erro na atualização do agendamento. Talvez esse horário não esteja mais disponível!",
        );
      } else {
        toastFeedbackMessage("success", "Agendamento editado com sucesso!");
        setUpdated(true);
      }

      // void fetchData();

      handleClose();
    } catch (err: any) {
      console.log(err);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no edição do agendamento. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no edição do agendamento. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro no edição do agendamento. Talvez esse horário não esteja mais disponível!",
        );
      }
    } finally {
      setDisableButton(false);
      setIsUpdating(false);
      setBlockEmptyMail(false);
      setGuest("");
      setGuests([]);
      handleClose();
    }
  };

  const handleGetStartTime = (date: string | null) => {
    setSchedule((prevState) => ({
      ...prevState,
      date: "",
      start_time: "",
      end_time: "",
    }));

    if (!(dayjs(date).format() === "Invalid Date")) {
      setSchedule((prevState) => ({
        ...prevState,
        date,
      }));

      setLoadingAvailableHours(true);

      setTimeout(() => {
        void getStartTimeEdit(date);
      }, 1000);
    }
  };

  const getAddress = async (addressId: string) => {
    if (addressId) {
      const response = await lawApi(`addresses/${addressId}`);

      const { street, cep, number, neighborhood, city, state, complement } =
        response.data.data.address;

      setValue(
        "address",
        `${street}, ${number}, ${neighborhood}. ${city} - ${state}. CEP: ${cep}`,
      );

      return `${street}, ${number}, ${neighborhood}. ${city} - ${state}. CEP: ${cep}`;
    }

    return "";
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email: "",
        title: "",
        // description: "",
        link: "",
        address: "",
        client_name: "",
        // client_last_name: "",
        client_email: "",
      });
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    setGuest("");
    setGuests([]);
    setBlockEmptyMail(false);
    setSchedule((prevState) => ({
      ...prevState,
      date: "",
      start_time: "",
      end_time: "",
    }));
    setIsUpdating(false);
    reset({
      email: "",
      title: "",
      // description: "",
      link: "",
      address: "",
      client_name: "",
      // client_last_name: "",
      client_email: "",
    });
  }, [handleClose, reset]);

  // useEffect(() => {}, [isUpdating]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xl">
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <MDBox display="flex" justifyContent="center">
          <MDTypography
            sx={{
              color: "#0B2F36",
              fontWeight: "bold",
              fontSize: matches ? "20px" : "24px",
            }}
          >
            Agendamento - Adv {user?.fullname}
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>
      <DialogContent
        sx={{ padding: matches ? "30px 30px 0 30px" : "30px 50px 0 50px" }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                noValidate
                component="form"
                my={2}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>
                        Título{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      {isUpdating ? (
                        <MDInput
                          error={!(errors.title == null)}
                          {...register("title")}
                          type="text"
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          variant="outlined"
                          placeholder={schedule?.title}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.title?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDBox display="flex" flexDirection="column">
                      {isUpdating ? (
                        <>
                          <InputLabel>Data</InputLabel>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="br"
                          >
                            <DemoContainer components={["DateField"]}>
                              <DateField
                                value={schedule.date}
                                onChange={(newValue: string | null) => {
                                  handleGetStartTime(newValue);
                                }}
                                fullWidth
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </>
                      ) : (
                        <>
                          <InputLabel sx={{ mb: 1 }}>
                            Data{" "}
                            <MDTypography
                              sx={{
                                fontSize: "12px",
                                color: "#f00",
                                display: "inline",
                              }}
                            >
                              *
                            </MDTypography>
                          </InputLabel>
                          <MDInput
                            disabled
                            type="text"
                            variant="outlined"
                            placeholder={dayjs(schedule?.date).format(
                              "DD/MM/YYYY",
                            )}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#DDCA92",
                                },
                              },
                            }}
                            fullWidth
                          />
                        </>
                      )}
                      <MDTypography sx={{ fontSize: "12px" }}>
                        {loadingAvailableHours && "Buscando horários..."}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <MDBox display="flex" flexDirection="column" width="100%">
                      <InputLabel sx={{ mb: 1 }}>
                        Início{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      {isUpdating ? (
                        <Select
                          value={schedule.start_time}
                          onChange={(e) => {
                            setSchedule((prevState) => ({
                              ...prevState,
                              start_time: e.target.value,
                            }));
                            setOptionsEndTime([]);
                            setSchedule((prevState) => ({
                              ...prevState,
                              end_time: "",
                            }));

                            // void (edit ? getEndTimeEdit() : getEndTime());
                            // void getEndTime(e.target.value);
                            void getEndTimeEdit();
                          }}
                          disabled={!schedule.date}
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "43px",
                            backgroundColor: "transparent !important",

                            boxShadow: "none !important",
                          }}
                        >
                          {optionsStartTime.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          variant="outlined"
                          placeholder={schedule?.start_time}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px" }}>
                        {!schedule.start_time &&
                          schedule.date &&
                          "Escolha o horário de início da reunião"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <MDBox display="flex" flexDirection="column" width="100%">
                      <InputLabel sx={{ mb: 1 }}>
                        Fim{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      {isUpdating ? (
                        <Select
                          disabled={schedule.start_time === ""}
                          value={schedule?.end_time}
                          onChange={(e) => {
                            setSchedule((prevState) => ({
                              ...prevState,
                              end_time: e.target.value,
                            }));
                          }}
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "43px",
                            backgroundColor: "transparent !important",

                            boxShadow: "none !important",
                          }}
                        >
                          {optionsEndTime.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          variant="outlined"
                          placeholder={schedule?.end_time}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px" }}>
                        {!schedule.end_time &&
                          schedule.start_time &&
                          "Escolha o horário em que a reunião deve terminar"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>
                        Tipo de reuniao:{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="remota"
                        name="radio-buttons-group"
                        value={typeMeet}
                        onChange={(e) => {
                          setTypeMeet(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="remota"
                          control={<BpRadio />}
                          label="Remota"
                        />
                        <FormControlLabel
                          value="presencial"
                          control={<BpRadio />}
                          label="Presencial"
                        />
                        <FormControlLabel
                          value="other"
                          control={<BpRadio />}
                          label="Presencial - Endereço do escritório"
                        />
                      </RadioGroup>
                    </MDBox>
                  </Grid>
                  {typeMeet !== "remota" ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1 }}>
                          Local{" "}
                          <MDTypography
                            sx={{
                              fontSize: "12px",
                              color: "#f00",
                              display: "inline",
                            }}
                          >
                            *
                          </MDTypography>
                        </InputLabel>
                        {isUpdating ? (
                          <MDInput
                            disabled={typeMeet === "other"}
                            error={!(errors.address == null)}
                            {...register("address")}
                            type="text"
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#DDCA92",
                                },
                              },
                            }}
                            fullWidth
                          />
                        ) : (
                          <MDInput
                            disabled
                            placeholder={schedule?.local}
                            type="text"
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#DDCA92",
                                },
                              },
                            }}
                            fullWidth
                          />
                        )}

                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.address?.message}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1 }}>
                          Link da reunião{" "}
                          <MDTypography
                            sx={{
                              fontSize: "12px",
                              color: "#f00",
                              display: "inline",
                            }}
                          >
                            *
                          </MDTypography>
                        </InputLabel>
                        {isUpdating ? (
                          <MDInput
                            error={!(errors.link == null)}
                            {...register("link")}
                            type="text"
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#DDCA92",
                                },
                              },
                            }}
                            fullWidth
                          />
                        ) : (
                          <MDInput
                            disabled
                            type="text"
                            variant="outlined"
                            placeholder={schedule?.link}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#DDCA92",
                                },
                              },
                            }}
                            fullWidth
                          />
                        )}
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.link?.message}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>
                        Nome completo do cliente
                      </InputLabel>
                      {isUpdating ? (
                        <MDInput
                          error={!(errors.client_name == null)}
                          {...register("client_name")}
                          type="text"
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          variant="outlined"
                          placeholder={fieldClient?.client_name}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.client_name?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>E-mail do cliente</InputLabel>
                      {isUpdating ? (
                        <MDInput
                          error={!(errors.client_email == null)}
                          {...register("client_email")}
                          type="text"
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      ) : (
                        <MDInput
                          disabled
                          type="text"
                          variant="outlined"
                          placeholder={fieldClient?.client_email}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      )}
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.client_email?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <MDBox display="flex" flexDirection="column">
              <MDBox
                my={2}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      sx={{ width: "100%" }}
                    >
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        sx={{ width: "100%" }}
                      >
                        <InputLabel sx={{ mb: 1, mt: 1 }}>
                          Convidados
                        </InputLabel>
                        <Paper
                          component="form"
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "43px",
                            backgroundColor: "transparent !important",
                            border: !(errors.email == null)
                              ? "1px solid #f00"
                              : "1px solid #DDCA92",
                            boxShadow: "none !important",
                          }}
                        >
                          <InputBase
                            disabled={!isUpdating}
                            value={guest}
                            onChange={(event) => {
                              handleGuest(event);
                            }}
                            type="email"
                            sx={{
                              ml: 1,
                              flex: 1,
                              "& ::placeholder": { fontSize: "14px", mx: 1 },
                              "&": { fontSize: "14px" },
                            }}
                            placeholder="Convidados"
                            fullWidth
                          />
                        </Paper>
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.email?.message}
                        </MDTypography>
                        <MDInput
                          error={!(errors.email == null)}
                          {...register("email")}
                          type="text"
                          variant="outlined"
                          sx={{
                            display: "none",
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                      </MDBox>
                      <MDButton
                        disabled={!isUpdating ? true : blockEmptyMail}
                        onClick={() => {
                          handleAddGuest();
                        }}
                        variant="contained"
                        sx={{
                          height: "43px",
                          width: "43px",
                          backgroundColor: "#DDCA92",
                          ml: 1,
                          "&:hover": {
                            backgroundColor: "#DDCA92",
                          },
                          "&.MuiButtonBase-root.Mui-disabled": {
                            backgroundColor: "#DDCA92",
                          },
                        }}
                      >
                        <AddIcon sx={{ color: "#000" }} />
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      {guests?.map((guestEmail: string, index: number) => (
                        <MDBox
                          key={index}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          mb={0.5}
                        >
                          <MDTypography sx={{ fontSize: "14px" }}>
                            - {guestEmail}
                          </MDTypography>
                          <IconButton
                            disabled={!isUpdating}
                            onClick={() => {
                              handleRemoveGuest(index);
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                color: "#f00",
                                fontSize: 10,
                                ml: 1,
                                display: !isUpdating ? "none" : "",
                              }}
                            />
                          </IconButton>
                        </MDBox>
                      ))}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDButton
          onClick={() => {
            // eslint-disable-next-line no-void
            isUpdating
              ? handleClose()
              : handleScheduleToDelete({ ...schedule, scheduleSelected });
          }}
          sx={{
            width: "130px",

            padding: "10px",
            textTransform: "capitalize",
            color: "#0B2F36",
            border: "2px solid #DDCA92",
            borderRadius: "10px",
          }}
        >
          {isUpdating ? "Cancelar" : "Excluir"}
        </MDButton>
        {isUpdating ? (
          <MDButton
            onClick={handleSubmit(onSubmit)}
            sx={{
              width: "130px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#DDCA92",
              background:
                "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

              border: "1px solid #DDCA92",
              borderRadius: "10px",
            }}
          >
            Confirmar edição
          </MDButton>
        ) : (
          <MDButton
            onClick={handleUpdateProcess}
            sx={{
              width: "130px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#DDCA92",
              background:
                "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

              border: "1px solid #DDCA92",
              borderRadius: "10px",
            }}
          >
            Editar
          </MDButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ShowScheduleForm;
