/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../components/BasicLayout";
import { useCallback, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../../../services/api";
import { CircularProgress } from "@mui/material";

const BORDER_GRADIENT =
  "linear-gradient(to top, #DDCA92, rgba(0, 0, 0, 0)) 1 100%";

interface IFormInput {
  email: string;
}

function RequestPassword(): JSX.Element {
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleOpenLoading = () => {
    setOpenLoading(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = useCallback(
    async (data) => {
      handleOpenLoading();
      try {
        const response = await api.post("/authentication/password/forgot", {
          email: data.email,
        });

        navigate("/verify-email");
      } catch (err) {
        console.log(err);
        // setOpen(true);
      } finally {
        handleCloseLoading();
      }
    },
    [navigate],
  );

  return (
    <BasicLayout background="#0B2F36">
      <MDBox
        minWidth="100%"
        height="100vh"
        pt={4}
        pb={3}
        px={4}
        sx={{
          border: "3px solid",
          borderRadius: "11px",
          borderImage: BORDER_GRADIENT,
        }}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          mb={6}
        >
          <MDTypography
            variant="h3"
            color="white"
            sx={{ textAlign: "center", mb: "70px" }}
          >
            Recuperação de senha
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mb={10}>
              <MDTypography
                variant="body2"
                color="white"
                sx={{ textAlign: "center", mb: 4 }}
              >
                Preencha o campo abaixo para solicitar a recuperação de senha
              </MDTypography>
              <MDInput
                type="email"
                label="Endereço de e-mail"
                // onChange={(e: any) => {}}
                {...register("email", {
                  required: true,
                  pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                })}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "transparent !important",
                    },
                  background: "#ffffff",
                  borderRadius: "8px",
                  boxShadow: " inset 3px 4px 11px rgba(0, 0, 0, 0.25)",
                  border: "none",

                  outline: "none",
                }}
                // @ts-expect-error
                InputLabelProps={{
                  sx: {
                    color: "gray",
                    "&.Mui-focused": {
                      color: "#7b809a", // nenhuma cor fica boa
                      top: "-1rem",
                    },
                  },
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt={4}
              mb={1}
            >
              <MDButton
                onClick={handleSubmit(onSubmit)}
                variant="none"
                sx={{
                  width: "20em",
                  maxWidth: "100%",
                  padding: 1,
                  mb: 2,
                  textTransform: "capitalize",
                  color: "#DDCA92",
                  background:
                    "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                  border: "1px solid #DDCA92",
                  borderRadius: "10px",
                  boxShadow: "rgba(255, 255, 255, 0.25) -8px 11px 101px",
                }}
              >
                {openLoading ? <CircularProgress color="primary" /> : "Enviar"}
              </MDButton>
              <MDTypography
                component={Link}
                to="/login"
                variant="button"
                fontWeight="medium"
                sx={{ color: "#fff", fontWeight: "400" }}
              >
                Voltar ao login
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default RequestPassword;
