/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import PageLayout from "../../../../examples/LayoutContainers/PageLayout";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

interface Props {
  background?: string;
  children: React.ReactNode;
}

function BasicLayout({ children, background = "#F1F1F1" }: Props) {
  return (
    <PageLayout background={background}>
      <Grid container justifyContent="center" minHeight="100vh" height="100%">
        <MDBox
          width="100%"
          pr="auto"
          pl={2}
          mt={2}
          sx={{
            position: "absolute",
            display: "flex",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          <MDTypography
            component={Link}
            to="/home"
            variant="button"
            fontWeight="bold"
            color="text"
            sx={{ cursor: "pointer", userSelect: "none", fontSize: "30px" }}
          >
            <Icon>arrow_back</Icon>
          </MDTypography>

          {/* <MDBox>
            <img
              src={logo}
              alt=""
              style={{ maxWidth: "130px", width: "100%" }}
            />
          </MDBox> */}
        </MDBox>
        <Grid item minHeight="100%" xs={10} sm={10} md={6} lg={5} xl={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default BasicLayout;
