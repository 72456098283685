/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Signup components
import CreditCard from "./components/CreditCard";

import BasicLayout from "./components/BasicLayout";

function CardUpdate() {
  return (
    <BasicLayout>
      <MDBox
        height="auto"
        maxWidth="1280px"
        minWidth="80vw"
        pt={4}
        pb={3}
        my={8}
        px={4}
        sx={{
          borderRadius: "11px",
          boxShadow: 2,
        }}
      >
        <MDBox>
          <MDBox>
            <CreditCard />
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default CardUpdate;
