/* eslint-disable no-useless-escape */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import dayjs, { type Dayjs } from "dayjs";
import "dayjs/locale/pt-br";

import CryptoJS from "crypto-js";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm, type SubmitHandler } from "react-hook-form";

import toast from "react-hot-toast";
import { lawApi } from "../../services/api";

// react-router-dom components

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  InputLabel,
  Paper,
  Switch,
  TextField,
} from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { ptBR } from "@mui/x-date-pickers/locales";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";

import Layout from "./components/Layout";

import logo from "../../assets/images/logos/law_color_secondary.svg";
import useEffectOnce from "../../hooks/useEffectOnce";
import ScheduleConfirmDialog from "./components/ScheduleConfirmationDialog";

interface IProfessional {
  _id: string;
  oab: string;
  name: string;
  last_name: string;
}

interface IAddress {
  cep: string;
  city: string;
  complement: string | null;
  neighborhood: string;
  number: number;
  state: string;
  street: string;
}

interface IScheduleData {
  client: {
    name: string;
    last_name: string;
    phone: string;
    email: string;
  };
  title: string | null;
  description: string;
  user_id: string;
  date: string;
  location: string;
  start_time: string;
}

const isWeekend = (date: Dayjs) => {
  const day = date.day();

  return day === 0 || day === 6;
};

interface IInputs {
  // phone: string;
  name: string;
  last_name: string;
  email: string;
  title: string | null;
  description: string | null;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  // phone: yup
  //   .string()
  //   .required("Informe seu número de telefone")
  //   .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Número inválido"),
  email: yup
    .string()
    .required("Informe seu email")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email inválido"),
  name: yup
    .string()
    .required("Informe seu nome")
    .min(3, "Seu nome deve ter no mínimo 3 caracteres"),
  last_name: yup
    .string()
    .required("Informe seu sobrenome")
    .min(3, "Seu sobrenome deve ter no mínimo 3 caracteres"),
  title: yup.string().default(""),
  description: yup.string().nullable().default(""),
});

function ExternalSchedule() {
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });
  const [formStep, setFormStep] = useState<number>(0);
  const [phone, setPhone] = useState<string>("");
  const [meetingLocation, setMeetingLocation] = useState<number>(0);
  const [openConfirmSchedule, setOpenConfirmSchedule] =
    useState<boolean>(false);
  const [professionalID, setProfessionalID] = useState<string>("");
  const [professional, setProfessional] = useState<IProfessional>({
    _id: "",
    oab: "",
    name: "",
    last_name: "",
  });
  const [availableHours, setAvailableHours] = useState<string[] | null>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>("");
  const [meetingDate, setMeetingDate] = useState<string>("");
  const [lockButton, setLockButton] = useState<boolean>(true);
  const [isSubmitSuccessfull, setIsSubmitSuccessfull] =
    useState<boolean>(false);
  const [address, setAddress] = useState<IAddress>({
    cep: "",
    city: "",
    complement: "",
    neighborhood: "",
    number: 0,
    state: "",
    street: "",
  });
  const [scheduleData, setScheduleData] = useState<IScheduleData>({
    client: {
      name: "",
      last_name: "",
      phone: "",
      email: "",
    },
    title: "",
    description: "",
    user_id: "",
    date: "",
    location: "",
    start_time: "",
  });
  const [terms, setTerms] = useState<boolean>(false);

  const watchTitle = watch("title");

  // const handleChange = (newPhone: string): void => {
  //   setPhone(newPhone);
  //   setValue("phone", newPhone);
  // };

  const handleSetTerms = () => {
    setTerms(!terms);
  };

  const handleChangeFormStep = (newStep: number) => {
    setFormStep(newStep);
  };

  const handleSetMeetingLocation = (type: number) => {
    setMeetingLocation(type);
  };

  const handleOpenConfirmSchedule = () => {
    setOpenConfirmSchedule(true);
  };

  const handleCloseConfirmSchedule = () => {
    setOpenConfirmSchedule(false);
  };

  const handleIsSubmitSuccessfull = () => {
    setIsSubmitSuccessfull(true);
  };

  const decryptUserID = () => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const encryptedUserID = urlParams.get("id");

    if (encryptedUserID) {
      const decryptParse = CryptoJS.enc.Base64url.parse(encryptedUserID);
      const decryptedUser = CryptoJS.enc.Utf8.stringify(decryptParse);
      const [id, name] = decryptedUser.split("+");
      setProfessionalID(id);
    } else {
      toast.error("Link inválido.", {
        duration: 5000,
      });
    }
  };

  const fetchProfessionalData = async () => {
    try {
      const userResponse = await lawApi.get(`/subusers/${professionalID}`);

      setProfessional(userResponse.data.data.subuser);

      const addressResponse = await lawApi.get(
        `/addresses/${userResponse.data.data.subuser.application.address_id}`,
      );
      setAddress(addressResponse.data.data.address);
    } catch (err: any) {
      console.log(err);
    }
  };

  const fetchAvailableHours = async (date: Dayjs | null) => {
    setLoading(true);

    const convertedData = new Date(dayjs(date).format()).toISOString();

    setMeetingDate(convertedData);

    const queryData = {
      user_id: professionalID,
      date: convertedData,
    };

    try {
      const response = await lawApi.post(
        "/schedules/availableHourUser",
        queryData,
      );

      setAvailableHours(response.data);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const submitFirstStep = () => {
    if (!watchTitle) {
      setError("title", {
        message: "Informe o título",
      });
      return;
    }
    handleChangeFormStep(1);
  };

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    const formData = {
      client: {
        name: data.name,
        last_name: data.last_name,
        phone: "",
        email: data.email,
      },
      user_id: professionalID,
      date: meetingDate,
      title: data.title,
      description: data.description ?? "",
      location:
        meetingLocation === 1
          ? `CEP ${address?.cep} - ${address?.street}, ${address?.neighborhood}, ${address?.city}, nº ${address?.number} - ${address?.state}`
          : "O link da reunião será enviado para o seu e-mail.",
      start_time: startTime,
    };

    setScheduleData(formData);
    handleOpenConfirmSchedule();
  };

  useEffectOnce(() => {
    decryptUserID();
    setIsSubmitSuccessfull(false);
    reset();
  }, []);

  useEffect(() => {
    if (professionalID) {
      void fetchProfessionalData();
    }
  }, [professionalID]);

  useEffect(() => {
    if (isSubmitSuccessfull) {
      reset();
      setPhone("");
    }
  }, [isSubmitSuccessfull]);

  return (
    <Layout>
      <MDBox display="flex" flexDirection="column" alignItems="center" mb={4}>
        <MDBox display="flex" justifyContent="center" my={4}>
          <img
            src={logo}
            alt="logo da jupiter"
            style={{ maxWidth: "50%", width: "50%" }}
          />
        </MDBox>

        <MDBox sx={{ minWidth: "50vw" }}>
          <Paper
            variant="outlined"
            sx={{ border: "3px solid #DDCA92", borderRadius: "20px" }}
          >
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: "#DDCA92",
                borderRadius: "10px",
              }}
            >
              {/* Headers */}
              {formStep === 0 && (
                <MDTypography
                  // @ts-expect-error
                  py={2}
                  variant="h4"
                  sx={{ color: "#0B2F36" }}
                >
                  Agende um horário
                </MDTypography>
              )}
              {formStep === 1 && (
                // @ts-expect-error
                <MDTypography py={2} variant="h4" sx={{ color: "#0B2F36" }}>
                  Entre ou crie a sua conta
                </MDTypography>
              )}
              {formStep === 2 && (
                <MDTypography
                  // @ts-expect-error
                  py={2}
                  variant="h4"
                  sx={{ color: "#0B2F36" }}
                >
                  Agendamento
                </MDTypography>
              )}
            </MDBox>
            <MDBox
              noValidate
              component="form"
              m={2}
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 30px 0 30px",
              }}
            >
              {formStep === 0 && (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>Agendamento com</InputLabel>
                      <MDInput
                        disabled
                        type="text"
                        variant="outlined"
                        value={
                          professionalID
                            ? `${professional?.name.trim()} ${professional?.last_name.trim()}`
                            : ""
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>OAB</InputLabel>
                      <MDInput
                        disabled
                        value={
                          professionalID && professional.oab
                            ? professional?.oab
                            : ""
                        }
                        type="text"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>
                        Título{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <MDInput
                        // error={isTitleEmpty}

                        // onChange={(event) => {
                        //   checkFirstStep(event);
                        // }}
                        error={!(errors.title == null)}
                        {...register("title")}
                        type="text"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.title?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDBox display="flex" flexDirection="column">
                      <InputLabel sx={{ mb: 1 }}>Descrição</InputLabel>
                      <MDInput
                        error={!(errors.description == null)}
                        {...register("description")}
                        type="text"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                        multiline
                        rows={3}
                      />
                      <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                        {errors.description?.message}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                      localeText={
                        ptBR.components.MuiLocalizationProvider.defaultProps
                          .localeText
                      }
                    >
                      <DemoItem>
                        <DateCalendar
                          onChange={(date) => {
                            void fetchAvailableHours(date);
                          }}
                          disablePast
                          sx={{ width: "100%" }}
                          showDaysOutsideCurrentMonth
                          shouldDisableDate={isWeekend}
                          views={["day"]}
                        />
                      </DemoItem>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDBox display="flex" flexDirection="column">
                      <Autocomplete
                        inputValue={startTime}
                        onInputChange={(event: any, newValue: string) => {
                          setStartTime(newValue);
                        }}
                        size="small"
                        disablePortal
                        noOptionsText="Nenhum horário a mostrar"
                        options={availableHours ?? []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              loading
                                ? "Buscando horários..."
                                : "Horários disponíveis"
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress
                                      sx={{ color: "#0b2f36" }}
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              )}
            </MDBox>
            <MDBox>
              {formStep === 0 && startTime.length > 0 && (
                <MDBox display="flex" flexDirection="column" mx={6} my={3}>
                  <MDTypography
                    mb={2}
                    sx={{ color: "#0B2F36", fontWeight: 600, fontSize: "14px" }}
                  >
                    Onde será a reunião
                  </MDTypography>
                  <MDBox display="flex" gap="10px 10px">
                    <MDButton
                      onClick={() => {
                        handleSetMeetingLocation(1);
                      }}
                      color="light"
                      sx={{
                        border:
                          meetingLocation === 1 ? "1px solid #0B2F36" : "",
                        color: "#0B2F36",
                        textTransform: "capitalize",
                        fontSize: "14px",
                      }}
                    >
                      Presencial
                    </MDButton>
                    <MDButton
                      onClick={() => {
                        handleSetMeetingLocation(2);
                      }}
                      color="light"
                      sx={{
                        border:
                          meetingLocation === 2 ? "1px solid #0B2F36" : "",
                        color: "#0B2F36",
                        textTransform: "capitalize",
                        fontSize: "14px",
                      }}
                    >
                      Online
                    </MDButton>
                  </MDBox>
                  <MDTypography my={2} variant="caption">
                    {meetingLocation === 1 &&
                      `CEP ${address?.cep} - ${address?.street}, ${address?.neighborhood}, ${address?.city}, nº ${address?.number} - ${address?.state}`}
                    {meetingLocation === 2 &&
                      "O link da reunião será enviado para o seu e-mail."}
                  </MDTypography>
                </MDBox>
              )}
              {formStep === 1 && (
                <MDBox display="flex" flexDirection="column" mx={6} my={3}>
                  {/* <Grid container justifyContent="center" mt={-4} mb={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1, fontSize: "0.7rem" }}>
                          Entre com o número do seu celular, enviaremos um
                          código via SMS para verificação.
                        </InputLabel>
                        <MuiTelInput
                          value={phone}
                          onChange={handleChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: !(errors.phone == null)
                                  ? "#f00"
                                  : "#DDCA92",
                              },
                              "& fieldset": {
                                borderColor: !(errors.phone == null)
                                  ? "#f00"
                                  : "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.phone?.message}
                        </MDTypography>
                        <MDInput
                          type="text"
                          variant="outlined"
                          error={!(errors.phone == null)}
                          {...register("phone")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                            display: "none",
                          }}
                        />
                      </MDBox>
                    </Grid>
                  </Grid> */}
                  <MDTypography mb={2} variant="h6">
                    Estamos quase lá
                  </MDTypography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1 }}>Nome</InputLabel>
                        <MDInput
                          type="text"
                          variant="outlined"
                          error={!(errors.name == null)}
                          {...register("name")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                        />
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.name?.message}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <MDBox display="flex" flexDirection="column">
                        <InputLabel sx={{ mb: 1 }}>Sobrenome</InputLabel>
                        <MDInput
                          type="text"
                          variant="outlined"
                          error={!(errors.last_name == null)}
                          {...register("last_name")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                        />
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.last_name?.message}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MDBox display="flex" flexDirection="column" mb={2}>
                        <InputLabel sx={{ mb: 1 }}>Seu email</InputLabel>
                        <MDInput
                          type="text"
                          variant="outlined"
                          error={!(errors.email == null)}
                          {...register("email")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#DDCA92",
                              },
                            },
                          }}
                          fullWidth
                        />
                        <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                          {errors.email?.message}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml={-1}
                    mt={1}
                    flexWrap="wrap"
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Switch checked={terms} onChange={handleSetTerms} />
                      </Grid>
                      <Grid item xs={11} sm={11} md={11} lg={11}>
                        <MDTypography
                          fontWeight="regular"
                          color="text"
                          sx={{
                            // cursor: "pointer",
                            userSelect: "none",
                            // ml: 0.5,
                            fontSize: "13px",
                          }}
                        >
                          &nbsp;&nbsp;Eu concordo com os Termos de Uso e a
                          Política de Privacidade&nbsp;{" "}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              )}
              {formStep === 2 && (
                <MDBox
                  mb={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 30px 0 30px",
                  }}
                  gap="50px 0"
                >
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <MDTypography
                      sx={{
                        fontWeight: 600,
                        fontSize: "24px",
                        color: "#0B2F36",
                        textAlign: "center",
                      }}
                    >
                      Agendamento feito com sucesso
                    </MDTypography>
                    <MDTypography
                      sx={{
                        fontWeight: "regular",
                        fontSize: "16px",
                        color: "#0B2F36",
                        textAlign: "center",
                      }}
                    >
                      Seu agendamento foi confirmado, veja abaixo o resumo.
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="10px 0"
                  >
                    <MDBox
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap="0 10px"
                    >
                      <CalendarTodayIcon
                        fontSize="medium"
                        sx={{ color: "#0B2F36" }}
                      />
                      <MDTypography
                        sx={{
                          fontWeight: 500,
                          color: "#0B2F36",
                          fontSize: "16px",
                        }}
                      >
                        {dayjs(scheduleData?.date)
                          .locale("pt-br")
                          .format("DD/MM/YYYY")}
                        , {scheduleData?.start_time} -{" "}
                        {Number(scheduleData?.start_time.split(":")[0]) + 1}:
                        {scheduleData?.start_time.split(":")[1]}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap="0 10px"
                      // sx={{ maxWidth: "90%" }}
                    >
                      <RoomOutlinedIcon
                        fontSize="medium"
                        sx={{ color: "#0B2F36" }}
                      />
                      <MDTypography
                        sx={{
                          fontWeight: 500,
                          color: "#0B2F36",
                          fontSize: "14px",
                        }}
                      >
                        {meetingLocation === 1
                          ? `CEP ${address?.cep} - ${address?.street}, ${address?.neighborhood}, ${address?.city}, nº ${address?.number} - ${address?.state}`
                          : "O link da reunião será enviado para o seu e-mail."}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              )}
            </MDBox>

            {/* Footer */}
            {formStep === 0 && (
              <MDBox display="flex" justifyContent="center" mb={2}>
                <MDButton
                  // disabled={lockButton}
                  onClick={submitFirstStep}
                  variant="outlined"
                  sx={{
                    width: "130px",

                    padding: "10px",
                    textTransform: "capitalize",
                    color: "#fff",
                    background:
                      "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                    border: "1px solid #DDCA92",
                    borderRadius: "10px",
                    "&:hover": {
                      color: "#fff",
                      border: "1px solid #DDCA92",
                      background:
                        "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                    },
                    "&.active": {
                      color: "#fff",
                      border: "1px solid #DDCA92",
                      background:
                        "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",
                    },
                  }}
                >
                  Avançar
                </MDButton>
              </MDBox>
            )}
            {formStep === 1 && (
              <MDBox display="flex" justifyContent="center" mb={2} gap="0 10px">
                <MDButton
                  onClick={() => {
                    handleChangeFormStep(0);
                  }}
                  sx={{
                    width: "130px",

                    padding: "10px",
                    textTransform: "capitalize",
                    color: "#0B2F36",
                    border: "2px solid #DDCA92",
                    borderRadius: "10px",
                  }}
                >
                  Voltar
                </MDButton>
                <MDButton
                  // onClick={handleOpenConfirmSchedule}
                  disabled={!terms}
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    width: "130px",

                    padding: "10px",
                    textTransform: "capitalize",
                    color: "#DDCA92",
                    background:
                      "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                    border: "1px solid #DDCA92",
                    borderRadius: "10px",
                  }}
                >
                  Cadastrar
                </MDButton>
              </MDBox>
            )}
            {formStep === 2 && (
              <MDBox display="flex" justifyContent="center" mb={2} gap="0 10px">
                <MDButton
                  onClick={() => {
                    handleChangeFormStep(0);
                    setMeetingLocation(0);
                    setTerms(false);
                  }}
                  sx={{
                    width: "200px",

                    padding: "10px",
                    textTransform: "capitalize",
                    color: "#DDCA92",
                    background:
                      "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

                    border: "1px solid #DDCA92",
                    borderRadius: "10px",
                  }}
                >
                  Novo agendamento
                </MDButton>
              </MDBox>
            )}
          </Paper>
        </MDBox>
      </MDBox>
      <ScheduleConfirmDialog
        scheduleData={scheduleData}
        openConfirmSchedule={openConfirmSchedule}
        handleChangeFormStep={handleChangeFormStep}
        handleIsSubmitSuccessfull={handleIsSubmitSuccessfull}
        handleCloseConfirmSchedule={handleCloseConfirmSchedule}
      />
    </Layout>
  );
}

export default ExternalSchedule;
