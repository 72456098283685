import { useRef, useState } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import { Button } from "@mui/material";

// Material Dashboard 2 Components
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";

interface IFileInputProps {
  handleRemoveField: (index: number) => void;
  handleFormChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  index: number;
  file?: any;
}

export default function FileInput({
  index,
  handleRemoveField,
  handleFormChange,
  file,
}: IFileInputProps) {
  const hiddenFileInput = useRef();
  const [fileName, setFileName] = useState<string>("");

  const handleAttachFile = () => {
    // @ts-expect-error
    hiddenFileInput.current.click();
  };

  const changeFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error
    setFileName(event.target.files[0].name);
  };

  return (
    <MDBox display="flex">
      {!file && (
        <MDButton
          onClick={handleAttachFile}
          variant="gradient"
          sx={{
            backgroundColor: "#0B2F36",
            color: "#f1f1f1",
            "&:hover": {
              backgroundColor: "#0B1F36",
            },
            // height: "43.8px",
            borderRadius: "5px 0 0 5px",
          }}
        >
          Anexar
        </MDButton>
      )}
      <MDInput
        type="file"
        size="large"
        // @ts-expect-error
        inputRef={hiddenFileInput}
        // name={`process-${index}`}
        name="file"
        sx={{ display: "none" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleFormChange(index, event);
          changeFileName(event);
        }}
        fullWidth
      />
      {file !== undefined && fileName === "" ? (
        <MDInput
          disabled
          name="fileName"
          value={file.title}
          // @ts-expect-error
          InputProps={{
            style: {
              borderRadius: "5px",
            },
          }}
          fullWidth
        />
      ) : (
        <MDInput
          disabled
          name="fileName"
          value={fileName}
          // @ts-expect-error
          InputProps={{
            style: {
              borderRadius: "0 5px 5px 0",
            },
          }}
          fullWidth
        />
      )}
      <Button
        onClick={() => {
          handleRemoveField(index);
        }}
        variant="contained"
        disableElevation
        sx={{
          backgroundColor: "#DDCA92",
          color: "#f1f1f1",
          "&:hover": {
            backgroundColor: "#DDCA92",
          },
          height: "43.8px",
          marginLeft: "5px",
        }}
      >
        <Icon sx={{ color: "#fff", fontSize: "300px" }}>remove</Icon>
      </Button>
    </MDBox>
  );
}
