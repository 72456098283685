import React from "react";

import dayjs from "dayjs";

import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IDetails {
  record: any;
  handleCloseDetails: () => void;
  handleOpenDeleteRecordDialog: (data: any) => void;
  handleShowEditForm: (data: any, type: string) => void;
}

export default function Details({
  record,
  handleCloseDetails,
  handleShowEditForm,
  handleOpenDeleteRecordDialog,
}: IDetails) {
  return (
    <MDBox sx={{ width: "100%", px: 5, my: 0 }} id="viewTransaction">
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <MDTypography
          variant="h4"
          sx={{ color: record.type === "Entrada" ? "#0B2F36" : "#6B1111" }}
        >
          Detalhes
        </MDTypography>
        <IconButton onClick={handleCloseDetails}>
          <CloseIcon />
        </IconButton>
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        sx={{
          border:
            record.type === "Entrada"
              ? "1px solid #0B2F36"
              : "1px solid #6B1111",
          padding: "20px",
          borderRadius: 3,
        }}
        gap="25px 0"
      >
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <MDTypography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: record.type === "Entrada" ? "#0B2F36" : "#6B1111",
            }}
            mb={1}
          >
            {record.type}
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: record.type === "Entrada" ? "#0B2F36" : "#6B1111",
            }}
          >
            Valor
          </MDTypography>
          <MDTypography variant="h6" sx={{ fontWeight: 600, color: "#7b8187" }}>
            R$ {record?.value}
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: record.type === "Entrada" ? "#0B2F36" : "#6B1111",
            }}
          >
            Data
          </MDTypography>
          <MDTypography variant="h6" sx={{ fontWeight: 600, color: "#7b8187" }}>
            {dayjs(record?.date).format("DD/MM/YYYY")}
          </MDTypography>
        </MDBox>
        {record?.title && (
          <MDBox>
            <MDTypography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: record.type === "Entrada" ? "#0B2F36" : "#6B1111",
              }}
            >
              Título
            </MDTypography>
            <MDTypography
              variant="h6"
              sx={{ fontWeight: 600, color: "#7b8187" }}
            >
              {record?.title}
            </MDTypography>
          </MDBox>
        )}
        {record?.description && (
          <MDBox>
            <MDTypography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: record.type === "Entrada" ? "#0B2F36" : "#6B1111",
              }}
            >
              Descrição
            </MDTypography>
            <MDTypography
              variant="h6"
              sx={{ fontWeight: 600, color: "#7b8187" }}
            >
              {record?.description}
            </MDTypography>
          </MDBox>
        )}
        {record?.is_monthly && (
          <>
            {" "}
            <MDBox>
              <MDTypography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: record.type === "Entrada" ? "#0B2F36" : "#6B1111",
                }}
              >
                Regularidade
              </MDTypography>
              <MDTypography
                variant="h6"
                sx={{ fontWeight: 600, color: "#7b8187" }}
              >
                Mensal
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: record.type === "Entrada" ? "#0B2F36" : "#6B1111",
                }}
              >
                Parcelamento
              </MDTypography>
              <MDTypography
                variant="h6"
                sx={{ fontWeight: 600, color: "#7b8187" }}
              >
                {record?.current_installment} de {record?.total_installment}
              </MDTypography>
            </MDBox>
          </>
        )}
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="20px 0"
        my={3}
      >
        <MDButton
          onClick={() => {
            handleShowEditForm(record, record?.type);
          }}
          variant="contained"
          sx={{
            color: "#fff",
            width: "170px",
            borderRadius: "15px",
            backgroundColor: record.type === "Entrada" ? "#0B2F36" : "#6B1111",
            "&:hover": {
              backgroundColor:
                record.type === "Entrada" ? "#0B2F36" : "#6B1111",
            },
            "& .Mui-focused": {
              backgroundColor:
                record.type === "Entrada" ? "#0B2F36" : "#6B1111",
            },
          }}
        >
          Editar
        </MDButton>
        <MDButton
          onClick={() => {
            handleOpenDeleteRecordDialog(record);
          }}
          variant="outlined"
          sx={{
            color: "#6B1111",
            width: "170px",
            borderRadius: "15px",
            border: "2px solid #6B1111",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
              border: "2px solid #6B1111",
            },
          }}
        >
          Excluir
        </MDButton>
      </MDBox>
    </MDBox>
  );
}
