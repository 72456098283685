import * as React from "react";

import { lawApi } from "../../../../../services/api";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

interface ISubuser {
  _id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    role: string;
    permissions: string[];
  };
  birth_date: string;
  cpf: string;
  identity: string;
  issuing_body: string;
  name: string;
  last_name: string;
  office: string;
  occupation: string;
  oab: string;
  social_status: string;
  uf: string;
  created_at: string;
}

interface IDeleteSubuserDialog {
  subuserToDelete: ISubuser;
  openDeleteSubuserDialog: boolean;
  fetchSubusers: () => Promise<void>;
  handleCloseDeleteSubuserDialog: () => void;
  toastFeedbackMessage: (type: string, message: string) => any;
}

export default function DeleteSubuserDialog({
  fetchSubusers,
  subuserToDelete,
  openDeleteSubuserDialog,
  handleCloseDeleteSubuserDialog,
  toastFeedbackMessage,
}: IDeleteSubuserDialog) {
  const handleDeleteSubuser = async () => {
    // console.log(subuserToDelete?._id);
    try {
      const response = await lawApi.delete(`/subusers/${subuserToDelete?._id}`);
      const data = response.data;

      if (data) {
        toastFeedbackMessage("success", "Usuário deletado com sucesso.");
      }

      // console.log(data);
    } catch (err: any) {
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do usuário. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do usuário. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do usuário. Verifique os dados informados!",
        );
      }
    } finally {
      void fetchSubusers();
      handleCloseDeleteSubuserDialog();
    }
  };

  return (
    <Dialog
      open={openDeleteSubuserDialog}
      onClose={handleCloseDeleteSubuserDialog}
    >
      <DialogContent
        sx={{ padding: "40px 50px 30px 50px", backgroundColor: "#0B2F36" }}
      >
        <DialogContentText sx={{ textAlign: "center" }}>
          <MDTypography sx={{ color: "#fff" }}>
            Tem certeza que deseja excluir o sub usuário
          </MDTypography>
          <MDTypography sx={{ fontWeight: 700, color: "#fff" }}>
            {subuserToDelete?.name} {subuserToDelete?.last_name}
          </MDTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseDeleteSubuserDialog}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Não
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleDeleteSubuser}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Confirmar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
