/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useAuth } from "../../hooks/useAuth";
import useEffectOnce from "../../hooks/useEffectOnce";
import { api, lawApi } from "../../services/api";

// @mui material components
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

// Images
import team2 from "../../assets/images/logo_law_profile_2.svg";

import DeactivateDialog from "./components/dialogs/ConfirmDeleteAccount";
import AdminForm from "./components/forms/Admin";
import SubuserForm from "./components/forms/Subuser";
import AdminHeader from "./components/headers/AdminHeader";
import UserHeader from "./components/headers/UserHeader";

interface IUser {
  _id: string;
  auth_user_id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    permission: [] | null;
    role: string;
  };
  cpf: string;
  birth_date: string;
  social_status: string;
  identity: string;
  email: string;
  name: string;
  last_name: string;
  oab: string | null;
  office: string | null;
}

interface IAddress {
  cep: string;
  city: string;
  complement: string;
  created_at: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  updated_at: string;
  _id: string;
}

interface IApplication {
  _id: string;
  name: string;
  plan_id: string;
  payday: number;
  code: string;
  address_id: string;
  assinatura_pagBank_id: string | null;
}

interface IPlan {
  active: boolean;
  benefits: object[] | null;
  code: string;
  name: string;
  price: number;
}

function Profile() {
  const { user } = useAuth();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [personalDataFetched, setPersonalDataFetched] =
    useState<boolean>(false);
  const [personalData, setPersonalData] = useState<IUser>({
    _id: "",
    auth_user_id: "",
    active: false,
    active_schedule: false,
    address_id: "",
    application: {
      id: "",
      permission: [],
      role: "",
    },
    cpf: "",
    birth_date: "",
    social_status: "",
    identity: "",
    email: "",
    name: "",
    last_name: "",
    oab: "",
    office: "",
  });
  const [address, setAddress] = useState<IAddress | null>({
    cep: "",
    city: "",
    complement: "",
    created_at: "",
    neighborhood: "",
    number: "",
    state: "",
    street: "",
    updated_at: "",
    _id: "",
  });
  const [addressApplication, setAddressApplication] = useState<IAddress | null>(
    {
      cep: "",
      city: "",
      complement: "",
      created_at: "",
      neighborhood: "",
      number: "",
      state: "",
      street: "",
      updated_at: "",
      _id: "",
    },
  );
  const [application, setApplication] = useState<IApplication>({
    _id: "",
    name: "",
    code: "",
    plan_id: "",
    payday: 0,
    address_id: "",
    assinatura_pagBank_id: "",
  });
  const [plan, setPlan] = useState<IPlan>({
    active: false,
    benefits: null,
    code: "",
    name: "",
    price: 0,
  });
  const [updatingProcess, setUpdatingProcess] = useState<boolean>(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] =
    useState<boolean>(false);
  const [applicationCode, setApplicationCode] = useState<string>("");

  const handleUpdateProcess = (): void => {
    setUpdatingProcess(!updatingProcess);
  };

  const handleOpenDeactivateAccount = (code: string) => {
    setApplicationCode(code);

    setOpenDeactivateDialog(true);
  };

  const handleCloseDeactivateAccount = () => {
    setOpenDeactivateDialog(false);
  };

  const toastFeedbackMessage = (type: string, message: string): any => {
    if (type === "error") {
      return toast.error(`${message}`, {
        duration: 5000,
      });
    }

    return toast.success(`${message}`, {
      duration: 5000,
    });
  };

  const checkRole = (
    plan: IPlan,
    role: string,
    personalData: IUser,
    address: IAddress | null,
    addressApplication: IAddress | null,
    application: IApplication,
  ) => {
    if (role === "admin") {
      return (
        <AdminForm
          plan={plan}
          matches={matches}
          address={address}
          addressApplication={addressApplication}
          user={personalData}
          application={application}
          updatingProcess={updatingProcess}
          fetchPersonalData={fetchPersonalData}
          handleDeactivateAccount={handleOpenDeactivateAccount}
          handleUpdate={handleUpdateProcess}
          toastFeedbackMessage={toastFeedbackMessage}
        />
      );
    }
    return (
      <SubuserForm
        matches={matches}
        address={address}
        user={personalData}
        application={application}
        updatingProcess={updatingProcess}
        fetchPersonalData={fetchPersonalData}
        handleUpdateProcess={handleUpdateProcess}
        toastFeedbackMessage={toastFeedbackMessage}
        handleDeactivateAccount={handleOpenDeactivateAccount}
      />
    );
  };

  const checkRoleHeader = (
    role: string,
    personalData: IUser,
    application: IApplication,
    plan: IPlan,
  ) => {
    if (role === "admin") {
      return (
        <AdminHeader
          matches={matches}
          user={personalData}
          application={application}
          plan={plan}
          updatingProcess={updatingProcess}
          handleUpdateProcess={handleUpdateProcess}
        />
      );
    }
    return (
      <UserHeader
        matches={matches}
        user={personalData}
        updatingProcess={updatingProcess}
        handleUpdateProcess={handleUpdateProcess}
      />
    );
  };

  const fetchPersonalData = async () => {
    const userID = user._id;
    const applicationID = user.applications[0].application_id;
    const endpoints = [`/subusers/auth/${userID}`, "/addresses/"];

    try {
      await Promise.all(
        endpoints.map(async (endpoint) => await lawApi.get(endpoint)),
      ).then(([{ data: personalData }, { data: addresses }]) => {
        // console.log(personalData, addresses);
        setPersonalData(personalData);
      });

      const application = await api.get(`/applications/${applicationID}`);

      const _addressApplication = await lawApi.get(
        `/addresses/${application.data.address_id}`,
      );
      if (personalData.address_id) {
        const addressUser = await lawApi.get(
          `/addresses/${personalData.address_id}`,
        );

        setAddress(addressUser.data);
      }

      const planID = application.data.plan_id;

      const plan = await api.get(`/plans/${planID}`);

      // const data = application.data.data;
      setApplication(application.data);
      setPlan(plan.data);

      setAddressApplication(_addressApplication.data.data.address);

      // console.log(application);
    } catch (err: any) {
      console.log(err.message);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      }
    }
  };

  useEffect(() => {
    if (!personalDataFetched) {
      setPersonalDataFetched(true);
    }
  }, [personalDataFetched]);

  useEffectOnce(() => {
    void fetchPersonalData();
  }, personalDataFetched);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox
        sx={{
          backgroundColor: "#0B2F36",
          borderRadius: "10px",
          padding: matches ? "40px 40px" : "40px 0px",
        }}
        display="flex"
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <MDBox display="flex" justifyContent="center">
              <Avatar alt="" src={team2} sx={{ width: 150, height: 150 }} />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            {checkRoleHeader(
              user.applications[0].role,
              personalData,
              application,
              plan,
            )}
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={4} />
      <DeactivateDialog
        openDeleteRecordDialog={openDeactivateDialog}
        applicationCode={applicationCode}
        handleCloseDeactivateAccountDialog={handleCloseDeactivateAccount}
        toastFeedbackMessage={toastFeedbackMessage}
      />
      {checkRole(
        plan,
        user.applications[0].role,
        personalData,
        address,
        addressApplication,
        application,
      )}
    </DashboardLayout>
  );
}

export default Profile;
