import * as React from "react";

import { lawApi } from "../../../../../services/api";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";

interface IDeleteUserDialog {
  openDeleteUser: boolean;
  fetchCustomers: () => Promise<void>;
  handleCloseDeleteUserDialog: () => void;
  userToDelete: ICustomer | null | undefined;
  toastFeedbackMessage: (type: string, message: string) => void;
  setIsRemoveClient: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ICustomer {
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
}

export default function DeleteUserDialog({
  openDeleteUser,
  userToDelete,
  fetchCustomers,
  setIsRemoveClient,
  toastFeedbackMessage,
  handleCloseDeleteUserDialog,
}: IDeleteUserDialog) {
  const handleDeleteUser = async () => {
    setIsRemoveClient(true);
    try {
      const response = await lawApi.delete(`/clients/${userToDelete?._id}`);

      if (response) {
        toastFeedbackMessage("Success", "Cliente deletado com sucesso.");
      }
      void fetchCustomers();
      setIsRemoveClient(false);
    } catch (err: any) {
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do cliente. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do cliente. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do cliente. Verifique os dados informados!",
        );
      }
    } finally {
      handleCloseDeleteUserDialog();
    }
  };

  return (
    <Dialog
      open={openDeleteUser}
      onClose={handleCloseDeleteUserDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ padding: "40px 50px 30px 50px", backgroundColor: "#0B2F36" }}
      >
        <DialogContentText sx={{ textAlign: "center" }}>
          <MDTypography sx={{ color: "#fff" }}>
            Tem certeza que deseja excluir o cliente
          </MDTypography>
          <MDTypography sx={{ fontWeight: 700, color: "#fff" }}>
            {userToDelete?.fullname}
          </MDTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseDeleteUserDialog}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Não
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleDeleteUser}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Confirmar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
