import * as React from "react";

import { lawApi } from "../../../../../services/api";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";

interface IDeleteUserDialog {
  openDeleteSchedule: boolean;
  // fetchCustomers: () => Promise<void>;
  handleCloseDeleteScheduleDialog: () => void;
  schedule: any;
  toastFeedbackMessage: (type: string, message: string) => void;
}

export default function DeleteScheduleDialog({
  openDeleteSchedule,
  schedule,
  // fetchCustomers,
  toastFeedbackMessage,
  handleCloseDeleteScheduleDialog,
}: IDeleteUserDialog) {
  const handleDeleteUser = async () => {
    try {
      const response = await lawApi.delete(
        `/schedules/${schedule?.scheduleSelected}`,
      );

      if (response) {
        toastFeedbackMessage("Success", "Agendamento deletado com sucesso.");
      }

      // void fetchCustomers();
    } catch (err: any) {
      console.log(err);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do agendamento. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do agendamento. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do agendamento. Talvez esse horário já tenha sido deletado.",
        );
      }
    } finally {
      handleCloseDeleteScheduleDialog();
    }
  };

  return (
    <Dialog
      open={openDeleteSchedule}
      onClose={handleCloseDeleteScheduleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ padding: "40px 50px 30px 50px", backgroundColor: "#0B2F36" }}
      >
        <DialogContentText sx={{ textAlign: "center" }}>
          <MDTypography sx={{ color: "#fff" }}>
            Tem certeza que deseja excluir o agendamento
          </MDTypography>
          <MDTypography sx={{ fontWeight: 700, color: "#fff" }}>
            {schedule?.title}
          </MDTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseDeleteScheduleDialog}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Não
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleDeleteUser}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Confirmar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
