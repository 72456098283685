import * as React from "react";

import { lawApi } from "../../../../../services/api";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";

interface IDialog {
  openModal: boolean;
  handleCloseModalDialog: () => void;
  handleCancelPlan: () => Promise<void>;
}

export default function CancelPlanDialog({
  openModal,
  handleCancelPlan,
  handleCloseModalDialog,
}: IDialog) {
  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModalDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ padding: "40px 50px 30px 50px", backgroundColor: "#0B2F36" }}
      >
        <DialogContentText sx={{ textAlign: "center" }}>
          <MDTypography sx={{ color: "#fff" }}>
            Tem certeza que deseja cancelar o plano e ir para o plano gratuito?
          </MDTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseModalDialog}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Não
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleCancelPlan}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Confirmar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
