/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

import { api, lawApi } from "../../services/api";

interface IUser {
  _id: string;
  name: string;
  last_name: string;
  email: string;
  password: string;
  active: boolean;
  role: "user";
  applications: [
    {
      _id: string;
      application_id: string;
      role: string;
      permissions: [];
    },
  ];
  payment_realized: boolean;
  canceled_plan: boolean;
  code: string;
}

interface IAuthState {
  token: string;
  user: IUser;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContextData {
  user: IUser;
  signIn: (credentials: ISignInCredentials) => Promise<void>;
  signOut: () => void;
  checkLogin: () => Promise<void>;
  loadingPage: boolean;
  // updatedUser: (data: IUser) => void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [data, setData] = useState<IAuthState | {}>({});

  const checkLogin = async (): Promise<void> => {
    const userToken = JSON.stringify(secureLocalStorage.getItem("token"));
    try {
      const response = await api.get("/authentication/check-login/", {
        params: {
          token: userToken,
        },
      });
      const token = secureLocalStorage.getItem("token") as string;
      const user = secureLocalStorage.getItem("user") as string;
      if (token && user) {
        api.defaults.headers.authorization = `Bearer ${token}`;
        lawApi.defaults.headers.authorization = `Bearer ${token}`;
        setData({ token, user: JSON.parse(user) });
      }
      console.log("oiii", response)
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {

    const getData = async () => {
      try {
        setLoadingPage(true);
        await checkLogin()
        // Inserindo e definindo o token para todas as requisições.
      } catch (error) {
        console.log(error)
      } finally {
        setTimeout(() => {
          console.log("OIOI");

          setLoadingPage(false);
        }, 1000);
      }

    }
    getData()
  }, [])


  // call this function when you want to authenticate the user
  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await api.post("/authentication/login", {
        email,
        password,
      });

      // console.log(response.);

      const {
        token,
        user: userData,
        status_payment,
        // userLaw,
        canceled_plan,
        applications,
      } = response.data[0].data;

      const user = {
        ...userData,
        status_payment,
        canceled_plan,
        code: applications[0].code,
      };

      secureLocalStorage.setItem("token", token);
      secureLocalStorage.setItem("user", JSON.stringify(user));
      secureLocalStorage.setItem("statusPayment", status_payment);

      // Inserindo e definindo o token para todas as requisições.
      api.defaults.headers.authorization = `Bearer ${token}`;
      lawApi.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });
    },
    [data],
  );

  // call this function to sign out logged in user
  const signOut = useCallback(() => {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("user");

    setData({} as IAuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        // paymentRealized: data.paymentRealized,
        signIn,
        signOut,
        checkLogin,
        loadingPage,
        // updatedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
