/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

import toast from "react-hot-toast";

import { lawApi } from "../../services/api";
import { useAuth } from "../../hooks/useAuth";

import useEffectOnce from "../../hooks/useEffectOnce";

// @mui material components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import CustomersTable from "./components/CustomersTable";
import ShowUserDialog from "./components/dialogs/ShowUser";
import DeleteUserDialog from "./components/dialogs/DeleteUser";
import CreateUserDialog from "./components/dialogs/CreateUser";
import ShowProcessDialog from "../processos/components/dialogs/ShowProcessDialog";
import DeleteProcessDialog from "../processos/components/dialogs/DeleteProcessDialog";
import CreateServiceDialog from "./components/dialogs/CreateService";
import PopUpStatusPayment from "./components/dialogs/BlockedAccount";
import secureLocalStorage from "react-secure-storage";

interface ICustomer {
  [x: string]: any;
  address_id: string;
  application_id: string;
  birth_date: string;
  cpf: string;
  created_at: string;
  fullname: string;
  identity: string;
  issuing_body: string;
  occupation: string;
  social_status: string;
  uf: string;
  updated_at: string;
  __v: number;
  _id: string;
  active: boolean;
}

interface IAddress {
  [x: string]: any;
  cep: string;
  city: string;
  complement: string;
  created_at: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  updated_at: string;
  __v: number;
  _id: string;
}

interface ISubuser {
  _id: string;
  auth_user_id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    role: string;
    permissions: string[];
  };
  birth_date: string;
  cpf: string;
  identity: string;
  issuing_body: string;
  name: string;
  last_name: string;
  office: string;
  occupation: string;
  oab: string;
  social_status: string;
  uf: string;
  created_at: string;
}

interface IService {
  active: boolean;
  user_id: string;
  client_id: string;
  created_at: Date | string;
  code: string;
  messages: IChat[];
  label: string;
  _id: string;
}

interface IChat {
  message: string;
  user_id: string;
  created_at: Date | string;
  user: ISubuser;
}

interface IProcess {
  active: boolean;
  application_id: string;
  client_id: string;
  created_at: Date | string;
  code: string;
  files: object[] | null;
  notes: object[] | null;
  _id: string;
}

function Dashboard() {
  // const { checkLogin } = useAuth();
  const theme = useTheme();
  const [openNewService, setOpenNewService] = useState<boolean>(false);
  const [customers, setCustomers] = useState<ICustomer>({
    address_id: "",
    application_id: "",
    birth_date: "",
    cpf: "",
    created_at: "",
    fullname: "",
    identity: "",
    issuing_body: "",
    occupation: "",
    social_status: "",
    uf: "",
    updated_at: "",
    __v: 0,
    _id: "",
    active: true,
  });
  const [processData, setProcessData] = useState<any>();
  const [clientData, setClientData] = useState<ICustomer[]>([
    {
      address_id: "",
      application_id: "",
      birth_date: "",
      cpf: "",
      created_at: "",
      fullname: "",
      identity: "",
      issuing_body: "",
      occupation: "",
      social_status: "",
      uf: "",
      updated_at: "",
      __v: 0,
      _id: "",
      active: true,
    },
  ]);

  const [client, setClient] = useState<ICustomer>({
    address_id: "",
    application_id: "",
    birth_date: "",
    cpf: "",
    created_at: "",
    fullname: "",
    identity: "",
    issuing_body: "",
    occupation: "",
    social_status: "",
    uf: "",
    updated_at: "",
    __v: 0,
    _id: "",
    active: true,
  });
  const [clientAddress, setClientAddress] = useState<IAddress>({
    cep: "",
    city: "",
    complement: "",
    created_at: "",
    neighborhood: "",
    number: "",
    state: "",
    street: "",
    updated_at: "",
    __v: 0,
    _id: "",
  });

  const [customersFetched, setCustomersFetched] = useState<boolean>(false);
  const [openPopUpStatusPayment, setOpenPopUpStatusPayment] =
    useState<boolean>(false);
  const [statusPayment, setStatusPayment] = useState<string>("ACTIVE");

  const [openNewUser, setOpenNewUser] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [openDeleteUser, setOpenDeleteUser] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<ICustomer | null>();
  const [allUsers, setAllUsers] = useState<ISubuser[]>([] as ISubuser[]);
  const [clientProcesses, setClientProcesses] = useState<readonly IProcess[]>([
    {
      active: true,
      application_id: "",
      client_id: "",
      created_at: "",
      code: "",
      files: null,
      notes: null,
      _id: "",
    },
  ]);
  const [clientServices, setClientServices] = useState<IService[]>([]);
  const [processToDelete, setProcessToDelete] = useState<any>();
  const [hasUpdateInformationClient, setHasUpdateInformationClient] =
    useState<boolean>(false);
  const [analytics, setAnalytics] = useState<any>();
  const [openShowProcessDialog, setOpenShowProcessDialog] =
    useState<boolean>(false);
  const [openDeleteProcessDialog, setOpenDeleteProcessDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const toastFeedbackMessage = (type: string, message: string): any => {
    if (type === "error") {
      return toast.error(`${message}`, {
        duration: 5000,
      });
    }

    return toast.success(`${message}`, {
      duration: 5000,
    });
  };

  const handleCloseNewServiceDialog = (): void => {
    setOpenNewService(false);
  };

  const handleOpenUser = useCallback(
    (data: ICustomer) => async () => {
      const adressID = data.address_id;
      try {
        if (!adressID) {
          setOpenUser(true);
          setClient(data);
        }
        const response = await lawApi.get(`/addresses/${adressID}`);
        const { address } = response.data.data;

        setClientAddress(address);
        setOpenUser(true);
        setClient(data);
      } catch (err: any) {
        // console.log(err.message);
        const code = err.response && err.response.data && err.response.data.statusCode;

        if (code === 404) {
          toast.error(
            "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
            {
              duration: 5000,
            },
          );
        } else if (code === 500) {
          toast.error(
            "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
            {
              duration: 5000,
            },
          );
        }
      }
    },
    [],
  );

  const handleCloseUser = (): void => {
    setOpenUser(false);
  };

  const handleOpenNewUserDialog = (): void => {
    setOpenNewUser(true);
  };

  const handleCloseNewUserDialog = (): void => {
    setOpenNewUser(false);
  };

  const fetchData = async (): Promise<void> => {
    setLoading(true);

    try {
      const response = await lawApi.get("/clients");
      const data = response.data.data;

      setCustomers(data.clients);

      const responseAnalytics = await lawApi.get("/clients/analytics/");
      setAnalytics(responseAnalytics.data.data.analytics_client);

      const responseAllUsers = await lawApi.get("/subusers");
      setAllUsers(responseAllUsers.data.data.subusers);
    } catch (err: any) {
      console.log(err);
      const code = err.response.data.statusCode;
      const message = err.response.data.message;

      if (code === 400) {
        toast.error(message, {
          duration: 5000,
        });
      } else if (code === 404) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Tente novamente mais tarde.",
          {
            duration: 5000,
          },
        );
      } else if (code === 500) {
        toast.error(
          "Ocorreu um erro na busca dos dados. Mas não se preocupe, não foi culpa sua.",
          {
            duration: 5000,
          },
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchClientProcesses = async (): Promise<void> => {
    const clientID = client?._id;

    if (clientID !== "") {
      try {
        const response = await lawApi.get(`/process/client/${clientID}`);
        const { processes } = response.data.data;

        setClientProcesses(processes);
      } catch (err: any) {
        console.log(err.message);
      }
    }
  };

  const fetchClientServices = async (): Promise<void> => {
    const clientID = client?._id;

    if (clientID !== "") {
      try {
        const response = await lawApi.get(`/services/client/${clientID}`);
        const { services } = response.data.data;
        setClientServices(services);
      } catch (err: any) {
        console.log(err.message);
      }
    }
  };

  const handleCloseShowProcessDialog = (): void => {
    setOpenShowProcessDialog(false);
  };

  useEffect(() => {
    if (!customersFetched) {
      setCustomersFetched(true);
    }

    updateShowModalPayment();
  }, [customersFetched]);

  const updateShowModalPayment = () => {
    const _statusPayment = secureLocalStorage.getItem("statusPayment");

    if (Boolean(_statusPayment) && _statusPayment !== "ACTIVE") {
      setOpenPopUpStatusPayment(true);
      secureLocalStorage.removeItem("statusPayment");
      setStatusPayment(String(_statusPayment));
    }
  };

  useEffectOnce(() => {
    void fetchData();
  }, customersFetched);

  const handleOpenShowProcessDialog = (data: any): void => {
    // handleCloseUser();
    setProcessData(data);

    const client = customers.filter(
      (customer: ICustomer) => customer._id === data.client_id,
    );

    setClientData(client);

    setOpenShowProcessDialog(true);
  };

  const handleOpenShowServiceDialog = (): void => {
    setOpenNewService(true);
  };

  const handleOpenDeleteUserDialog = (client: ICustomer | null): void => {
    setOpenUser(false);
    setOpenDeleteUser(true);
    setUserToDelete(client);
  };

  const handleCloseDeleteUserDialog = (): void => {
    setOpenDeleteUser(false);
  };

  const handleOpenDeleteProcessDialog = (process: any | null): void => {
    setOpenShowProcessDialog(false);
    setOpenDeleteProcessDialog(true);
    setProcessToDelete(process);
  };

  const handleCloseDeleteProcessDialog = (): void => {
    handleCloseShowProcessDialog();
    setOpenDeleteProcessDialog(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3} sx={{ maxWidth: 271 }}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="groups"
                title="Total de clientes"
                count={analytics?.total_clients}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ maxWidth: 271 }}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="folder_shared"
                title="Clientes com processos atuais"
                count={analytics?.total_clients_with_processes}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ maxWidth: 271 }}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="group_add"
                title="Novos clientes"
                count={analytics?.total_clients_month}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <CustomersTable
                loading={loading}
                customers={customers}
                handleOpenUser={handleOpenUser}
                handleOpenNewUserDialog={handleOpenNewUserDialog}
                isUpdate={hasUpdateInformationClient}
                matches={matches}
                setIsUpdateClient={setHasUpdateInformationClient}
              />
            </Grid>
          </Grid>
        </MDBox>
        {/* Cadastrar novo cliente */}
        <CreateUserDialog
          matches={matches}
          openNewUser={openNewUser}
          fetchCustomers={fetchData}
          toastFeedbackMessage={toastFeedbackMessage}
          handleCloseNewUserDialog={handleCloseNewUserDialog}
          setHasAddClient={setHasUpdateInformationClient}
        />

        {/* Mostrar dados do cliente */}
        <ShowUserDialog
          open={openUser}
          matches={matches}
          userData={client}
          fetchCustomers={fetchData}
          userAddress={clientAddress}
          processes={clientProcesses}
          services={clientServices}
          allUsers={allUsers}
          handleCloseUser={handleCloseUser}
          toastFeedbackMessage={toastFeedbackMessage}
          fetchClientProcesses={fetchClientProcesses}
          fetchClientServices={fetchClientServices}
          handleOpenDeleteUserDialog={handleOpenDeleteUserDialog}
          handleOpenShowProcessDialog={handleOpenShowProcessDialog}
          handleOpenShowServiceDialog={handleOpenShowServiceDialog}
          setIsUpdateClient={setHasUpdateInformationClient}
        // matches={matches}
        />

        {/* Excluir cliente */}
        <DeleteUserDialog
          fetchCustomers={fetchData}
          userToDelete={userToDelete}
          openDeleteUser={openDeleteUser}
          toastFeedbackMessage={toastFeedbackMessage}
          handleCloseDeleteUserDialog={handleCloseDeleteUserDialog}
          setIsRemoveClient={setHasUpdateInformationClient}
        />

        {/* Cadastrar novo atendimento */}
        <CreateServiceDialog
          matches={matches}
          openNewService={openNewService}
          fetchServices={fetchClientServices}
          toastFeedbackMessage={toastFeedbackMessage}
          handleCloseNewServiceDialog={handleCloseNewServiceDialog}
          userData={client}
          processes={clientProcesses}
          setOpenUser={setOpenUser}
        />

        {/* Mostrar informações de um processo */}
        <ShowProcessDialog
          matches={matches}
          customers={customers}
          clientData={clientData}
          processData={processData}
          // handleOpenDeleteProcessFileDialog={handleOpenDeleteProcessFileDialog}
          fetchData={fetchClientProcesses}
          toastFeedbackMessage={toastFeedbackMessage}
          openShowProcessDialog={openShowProcessDialog}
          handleCloseShowProcessDialog={handleCloseShowProcessDialog}
          handleOpenDeleteProcessDialog={handleOpenDeleteProcessDialog}
        />

        {/* Deletar um processo */}
        <DeleteProcessDialog
          processToDelete={processToDelete}
          fetchSubusers={fetchClientProcesses}
          toastFeedbackMessage={toastFeedbackMessage}
          openDeleteProcessDialog={openDeleteProcessDialog}
          handleCloseDeleteProcessDialog={handleCloseDeleteProcessDialog}
        />
        {/* Payment blocked or pending */}
        <PopUpStatusPayment
          handleClosePopUp={setOpenPopUpStatusPayment}
          openPopUp={openPopUpStatusPayment}
          statusPayment={statusPayment}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
