/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-floating-promises */
import * as React from "react";

import dayjs from "dayjs";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";

// @mui material components
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogContentText from "@mui/material/DialogContentText";
import dataUF from "../../../../../brazilian-states.json";

// Material Dashboard 2 Components
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";

import { lawApi } from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/useAuth";
import BootstrapDialogTitle from "../../../../../components/BootstrapDialogTitle";
import { Autocomplete, TextField } from "@mui/material";
import cep from "cep-promise";

interface ISubuser {
  auth_user_id: string;
  _id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    role: string;
    permissions: string[];
  };
  birth_date: string;
  cpf: string;
  identity: string;
  issuing_body: string;
  name: string;
  last_name: string;
  office: string;
  occupation: string;
  oab: string;
  social_status: string;
  uf: string;
  created_at: string;
}

interface IAddress {
  [x: string]: any;
  cep: string;
  city: string;
  complement: string;
  created_at: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  updated_at: string;
  __v: number;
  _id: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IShowUserDialogProps {
  open: boolean;
  matches: boolean;
  permissions: any;
  subuserData: ISubuser;
  subuserAddress: IAddress;
  handleCloseSubuser: () => void;
  fetchData: () => Promise<void>;
  handleOpenDeleteSubuserDialog: (subuser: any) => Promise<void>;
  toastFeedbackMessage: (type: string, message: string) => any;
}

interface IInputs {
  birth_date: string;
  cpf: string;
  identity: string;
  issuing_body: string;
  name: string;
  last_name: string;
  office: string | null;
  oab: string | null;
  social_status: string;
  uf: string;
  cep: string;
  city: string;
  complement: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  name: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .max(50, "No máximo 50 caracteres")
    .required("Informe o nome do usuário"),
  last_name: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .max(50, "No máximo 50 caracteres")
    .required("Informe o nome do usuário"),
  oab: yup.string().nullable().default(""),
  office: yup.string().nullable().default(""),
  cpf: yup.string().required("CPF não pode ser vazio"),
  birth_date: yup.string().required("Data inválida"),
  social_status: yup.string().required("Informe o estado civil"),
  identity: yup
    .string()
    .required("RG não pode ser vazio")
    .max(20, "No máximo 20 caracteres"),
  issuing_body: yup
    .string()
    .min(3, "Deve ter no mínimo 3 caracteres")
    .required("Informe o órgão expedidor"),
  uf: yup
    .string()
    .min(2, "Deve ter no mínimo 2 caracteres")
    .required("Informe o UF"),
  occupation: yup.string().default(""),
  cep: yup
    .string()
    .required("Informe o CEP")
    .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP inválido"),
  street: yup.string().required("Informe a rua"),
  number: yup
    .string()
    .matches(/(\d+)| /g, "Número inválido")
    .required("Informe o número da casa ou apt.")
    .min(1, "Número inválido"),
  neighborhood: yup.string().required("Informe o bairro"),
  city: yup.string().required("Informe a cidade"),
  state: yup.string().required("Informe o estado"),
  complement: yup.string().default(""),
});

export default function ShowSubuserDialog({
  open,
  matches,
  fetchData,
  permissions,
  subuserData,
  subuserAddress,
  handleCloseSubuser,
  handleOpenDeleteSubuserDialog,
  toastFeedbackMessage,
}: IShowUserDialogProps) {
  const { user } = useAuth();
  const [UF, setUF] = React.useState<{ nome: string; sigla: string }>();
  const [state, setState] = React.useState<{ nome: string; sigla: string }>();
  const [value, setTabValue] = React.useState(0);
  const [updatingSubuser, setUpdatingSubuser] = React.useState<boolean>(false);
  const [disableButton, setDisableButton] = React.useState<boolean>(false);
  const [subuserPermissions, setSubuserPermissions] = React.useState<any>();
  const [maritalStatus, setMaritalStatus] =
    React.useState<{ value: string; label: string }>();

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });

  const defaultPropsUF = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.sigla,
  };

  const defaultPropsState = {
    options: dataUF.UF,
    getOptionLabel: (option: { nome: string; sigla: string }) => option.nome,
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setTabValue(newValue);
  };

  const watchCep = watch("cep");

  React.useEffect(() => {
    void consultCEP();
  }, [watchCep]);

  const consultCEP = async () => {
    try {
      if (watchCep.length === 9) {
        const newCEPFormatted = watchCep.replace("-", "");

        const response = await cep(newCEPFormatted);

        if (response) {
          const { city, neighborhood, state, street } = response;
          const _currentState = dataUF.UF.find((uf) => uf.sigla === state);
          if (_currentState) {
            setState(_currentState);
          }

          setValue("city", city);
          setValue("neighborhood", neighborhood);
          setValue("state", state);
          setValue("street", street);
        }
      }
    } catch (error) { }
  };

  const handleChangePermissions = (checkedPermission: string): void => {
    const hasPermission = subuserPermissions.includes(checkedPermission);

    if (hasPermission) {
      const filteredPermissions = subuserPermissions.filter(
        (permission: string) => {
          return permission !== checkedPermission;
        },
      );

      setSubuserPermissions(filteredPermissions);
    } else {
      setSubuserPermissions([...subuserPermissions, checkedPermission]);
    }
  };

  const handleCloseUpdateProcess = (): void => {
    setUpdatingSubuser(false);
  };

  const getSocialStatus = React.useCallback(() => {
    const options = [
      { value: "solteiro", label: "Solteiro" },
      { value: "casado", label: "Casado" },
      { value: "separado", label: "Separado" },
      { value: "divorciado", label: "Divorciado" },
      { value: "viúvo", label: "Viúvo" },
    ];
    if (subuserData) {
      const _socialStatus = options.find(
        (c: { value: string; label: string }) =>
          c.label.toLocaleLowerCase() ===
          subuserData.social_status.toLocaleLowerCase(),
      );

      if (_socialStatus !== undefined) {
        return _socialStatus;
      }
    }

    return null;
  }, [subuserData]);

  React.useEffect(() => {
    if (subuserData.social_status) {
      const _currentSocialStatus = getSocialStatus();

      if (_currentSocialStatus) setMaritalStatus(_currentSocialStatus);
    }
  }, [getSocialStatus]);

  const getUF = React.useCallback(() => {
    if (subuserData) {
      const _state = dataUF.UF.find((c: any) => c.sigla === subuserData.uf);

      if (_state !== undefined) {
        return _state;
      }
    }

    return null;
  }, [subuserData]);

  React.useEffect(() => {
    const _currentUF = getUF();

    if (_currentUF) setUF(_currentUF);
  }, [getUF]);

  const getState = React.useCallback(() => {
    if (subuserAddress) {
      const _state = dataUF.UF.find(
        (c: any) => c.nome === subuserAddress.state,
      );

      if (_state !== undefined) {
        return _state;
      }
    }

    return null;
  }, [subuserAddress]);

  React.useEffect(() => {
    const _currentState = getState();
    // console.log(_currentUF);

    if (_currentState) setState(_currentState);
  }, [getState]);

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    const isAfterThenToday = dayjs(data.birth_date).isAfter(dayjs());
    const isBeforeThen1900 = dayjs(dayjs(data.birth_date)).isBefore(
      "1900-01-01",
    );

    if (isAfterThenToday || isBeforeThen1900) {
      setError("birth_date", {
        message: "Data inválida",
      });
    } else {
      const subuserID = subuserData._id;

      const addressData = {
        cep: data.cep,
        street: data.street,
        number: data.number,
        neighborhood: data.neighborhood,
        city: data.city,
        state: state?.nome,
        complement: data.complement,
      };

      let subuserInfo: any = {
        name: data.name,
        last_name: data.last_name,
        cpf: data.cpf,
        birth_date: data.birth_date,
        identity: data.identity,
        issuing_body: data.issuing_body,
        social_status: maritalStatus?.label,
        uf: UF?.sigla,
        permissions: [...subuserPermissions],
        active_schedule: subuserData.active_schedule,
      };

      // console.log(formData);

      try {
        if (subuserData.address_id) {
          const response = await Promise.all([
            lawApi.put(`/subusers/${subuserID}`, subuserInfo),
            lawApi.put(`/addresses/${subuserAddress._id}`, addressData),
          ]);

          const data = response.map((res) => res.data);

          if (data) {
            toastFeedbackMessage(
              "success",
              "Dados do subusuário atualizados com sucesso",
            );
          }
        } else {
          const addressResponse = await lawApi.post(`/addresses`, addressData);

          subuserInfo = {
            ...subuserInfo,
            address_id: addressResponse.data._id,
          };

          const userResponse = await lawApi.put(
            `/subusers/${subuserID}`,
            subuserInfo,
          );

          if (addressResponse && userResponse) {
            toastFeedbackMessage("succes", "Dados atualizados com sucesso.");
          }
        }
      } catch (err: any) {
        // console.log(err.message);
        console.log(err);
        if (!err.response) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro na atualização do usuário. Mas não se preocupe, não foi culpa sua.",
          );
          return;
        }

        const code = err.response.data.statusCode;
        const message = err.response.data.message;
        if (code === 400) {
          toastFeedbackMessage("error", message);
        } else if (code === 404) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro na atualização do usuário. Tente novamente mais tarde!",
          );
        } else if (code === 500) {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro na atualização do usuário. Mas não se preocupe, não foi culpa sua.",
          );
        } else {
          toastFeedbackMessage(
            "error",
            "Ocorreu um erro na atualização do usuário. Verifique os dados informados!",
          );
        }
      } finally {
        void fetchData();
        setDisableButton(false);
        handleCloseSubuser();
      }
    }
  };

  const handleUpdateProcess = (): void => {
    const subuserDataFields = [
      "birth_date",
      "cpf",
      "identity",
      "issuing_body",
      "name",
      "last_name",
      "office",
      "oab",
      "social_status",
      "uf",
      "created_at",
    ];

    const subuserAddressFields = [
      "cep",
      "city",
      "complement",
      "neighborhood",
      "number",
      "state",
      "street",
    ];

    subuserDataFields.forEach((field: any) => {
      // @ts-expect-error
      if (field === "birth_date" && subuserData[field]) {
        // @ts-expect-error
        const date = subuserData[field].split("/").reverse().join("-");
        setValue(field, date);
      } else {
        // @ts-expect-error
        setValue(field, subuserData[field]);
      }
    });

    subuserAddressFields.forEach((field: any) => {
      setValue(field, subuserAddress[field]);
    });

    const permissions = subuserData.application.permissions.map(
      (permission: string) => {
        return permission.split("_").slice(-1)[0];
      },
    );
    const uniquePermissions = Array.from(new Set(permissions));

    setSubuserPermissions(uniquePermissions);
    setUpdatingSubuser(true);
  };

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      setUpdatingSubuser(false);
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const defaultPropsMaritalStatus = {
    options: [
      { value: "solteiro", label: "Solteiro" },
      { value: "casado", label: "Casado" },
      { value: "separado", label: "Separado" },
      { value: "divorciado", label: "Divorciado" },
      { value: "viúvo", label: "Viúvo" },
    ],
    getOptionLabel: (option: { value: string; label: string }) => option.label,
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setUpdatingSubuser(false);
        handleCloseSubuser();
        reset();
      }}
      fullWidth={true}
      maxWidth="xl"
      fullScreen={!!matches}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setUpdatingSubuser(false);
          handleCloseSubuser();
          reset();
        }}
      >
        <MDBox display="flex" flexDirection="column" alignItems="center">
          <MDTypography
            sx={{ color: "#0B2F36", fontSize: matches ? "20px" : "24px" }}
          >
            Visualizar sub usuário
          </MDTypography>
          <MDTypography
            sx={{
              color: "#0B2F36",
              fontSize: matches ? "18px" : "22px",
              fontWeight: 800,
            }}
          >
            {subuserData.name} {subuserData.last_name}
          </MDTypography>
        </MDBox>
      </BootstrapDialogTitle>
      <MDBox display="flex" justifyContent="space-between" mx={6}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Detalhes"
            {...a11yProps(0)}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#0B2F36",
              textTransform: "capitalize",
            }}
          />
          <Tab
            label="Informações"
            {...a11yProps(1)}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#0B2F36",
              textTransform: "capitalize",
            }}
          />
        </Tabs>
      </MDBox>
      <TabPanel value={value} index={0}>
        <DialogContent
          sx={{
            marginTop: "-1.5em",
            mx: 1,
            padding: matches ? "30px -30px 0 30px" : "30px 50px 0 50px",
          }}
        >
          <DialogContentText>Dados pessoais</DialogContentText>
          <MDBox
            noValidate
            component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Nome{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.name == null)}
                      {...register("name")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.name}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.name?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Sobrenome{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.last_name == null)}
                      {...register("last_name")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.last_name}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.last_name?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    CPF{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.cpf == null)}
                      {...register("cpf")}
                      mask={"999.999.999-99"}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.cpf}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.cpf?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <MDBox display="flex" flexDirection="column">
                  {updatingSubuser ? (
                    <>
                      <InputLabel sx={{ mb: 1 }}>
                        Data Nascimento{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <MDInput
                        type="date"
                        error={!(errors.birth_date == null)}
                        {...register("birth_date")}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <InputLabel sx={{ mb: 1 }}>
                        Data Nascimento{" "}
                        <MDTypography
                          sx={{
                            fontSize: "12px",
                            color: "#f00",
                            display: "inline",
                          }}
                        >
                          *
                        </MDTypography>
                      </InputLabel>
                      <MDInput
                        disabled
                        type="text"
                        variant="outlined"
                        placeholder={
                          subuserData?.birth_date &&
                          subuserData?.birth_date.split("-").reverse().join("/")
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#DDCA92",
                            },
                          },
                        }}
                      />
                    </>
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.birth_date?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Status social{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      noOptionsText="Sem opções"
                      {...defaultPropsMaritalStatus}
                      value={maritalStatus ?? null}
                      // defaultValue={client}
                      onChange={(event: any, newValue: any) => {
                        setMaritalStatus(newValue);

                        newValue
                          ? setValue("social_status", newValue.label)
                          : setValue("social_status", "");
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.social_status}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.social_status?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    RG{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.identity == null)}
                      {...register("identity")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.identity}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.identity?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Órgão emissor{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.issuing_body == null)}
                      {...register("issuing_body")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.issuing_body}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.issuing_body?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    UF{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      noOptionsText="Estado inexistente"
                      {...defaultPropsUF}
                      value={UF ?? null}
                      // defaultValue={client}
                      onChange={(event: any, newValue: any) => {
                        setUF(newValue);
                        if (newValue) {
                          setValue("uf", newValue.sigla);
                        } else {
                          setValue("uf", "");
                        }
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.uf}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.uf?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <DialogContentText>Endereço</DialogContentText>
          <MDBox
            noValidate
            component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    CEP{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.cep == null)}
                      {...register("cep")}
                      mask={"99999-999"}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserAddress?.cep}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.cep?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Rua{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.street == null)}
                      {...register("street")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserAddress?.street}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.street?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Número{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.number == null)}
                      {...register("number")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserAddress?.number}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.number?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Bairro{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.neighborhood == null)}
                      {...register("neighborhood")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserAddress?.neighborhood}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.neighborhood?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Cidade{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.city == null)}
                      {...register("city")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserAddress?.city}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.city?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Estado{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <Autocomplete
                      disablePortal
                      size="small"
                      noOptionsText="Estado inexistente"
                      {...defaultPropsState}
                      value={state ?? null}
                      onChange={(event: any, newValue: any) => {
                        setState(newValue);
                        if (newValue) {
                          setValue("state", newValue.nome);
                        } else {
                          setValue("state", "");
                        }
                      }}
                      sx={{
                        "& input": { height: "27px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserAddress?.state}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.state?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Complemento{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      {/* * */}
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.complement == null)}
                      {...register("complement")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserAddress?.complement}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                  <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                    {errors.complement?.message}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DialogContent sx={{ marginTop: "-1.5em" }}>
          <DialogContentText>Sobre</DialogContentText>
          <MDBox
            noValidate
            component="form"
            my={2}
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>
                    Cargo{" "}
                    <MDTypography
                      sx={{
                        fontSize: "12px",
                        color: "#f00",
                        display: "inline",
                      }}
                    >
                      *
                    </MDTypography>
                  </InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      error={!(errors.office == null)}
                      {...register("office", {
                        required: true,
                        minLength: 3,
                        maxLength: 30,
                      })}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.office}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>OAB</InputLabel>
                  {updatingSubuser ? (
                    <MDInput
                      // error={!(errors.oab == null)}
                      {...register("oab")}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  ) : (
                    <MDInput
                      disabled={!updatingSubuser}
                      value={subuserData?.oab}
                      type="text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#DDCA92",
                          },
                        },
                      }}
                    />
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Cadastrado desde</InputLabel>
                  <MDInput
                    disabled
                    value={dayjs(subuserData?.created_at).format("DD/MM/YYYY")}
                    type="text"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#DDCA92",
                        },
                      },
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDBox display="flex" flexDirection="column">
                  <InputLabel sx={{ mb: 1 }}>Permissões</InputLabel>
                  <MDBox display="flex">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        {updatingSubuser ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => {
                                  handleChangePermissions("CLIENTS");
                                }}
                                checked={subuserPermissions?.includes(
                                  "CLIENTS",
                                )}
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Clientes"
                          />
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={
                                  permissions?.includes("clients") ||
                                  permissions?.includes("ALL")
                                }
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Clientes"
                          />
                        )}
                        {/* <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!updatingSubuser}
                              onChange={() => {
                                handleChangePermissions("CLIENTS");
                              }}
                              checked={
                                !updatingSubuser
                                  ? permissions?.includes("clients")
                                  : subuserPermissions?.includes("CLIENTS")
                              }
                              sx={{
                                color: "#DDCA92",
                                "&.Mui-checked": {
                                  color: "#DDCA92",
                                },
                              }}
                            />
                          }
                          label="Clientes"
                        /> */}
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        {updatingSubuser ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => {
                                  handleChangePermissions("PROCESSES");
                                }}
                                checked={subuserPermissions?.includes(
                                  "PROCESSES",
                                )}
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Processos"
                          />
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={
                                  permissions?.includes("processes") ||
                                  permissions?.includes("ALL")
                                }
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Processos"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        {updatingSubuser ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => {
                                  handleChangePermissions("SCHEDULES");
                                }}
                                checked={subuserPermissions?.includes(
                                  "SCHEDULES",
                                )}
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Agendamentos"
                          />
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={
                                  permissions?.includes("schedules") ||
                                  permissions?.includes("ALL")
                                }
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Agendamentos"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        {updatingSubuser ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => {
                                  handleChangePermissions("SUBUSER");
                                }}
                                checked={subuserPermissions?.includes(
                                  "SUBUSER",
                                )}
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Sub usuários"
                          />
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={
                                  permissions?.includes("subuser") ||
                                  permissions?.includes("ALL")
                                }
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Sub usuários"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        {updatingSubuser ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => {
                                  handleChangePermissions("FINANCIAL");
                                }}
                                checked={subuserPermissions?.includes(
                                  "FINANCIAL",
                                )}
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Fluxo de caixa"
                          />
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={
                                  permissions?.includes("financial") ||
                                  permissions?.includes("ALL")
                                }
                                sx={{
                                  color: "#DDCA92",
                                  "&.Mui-checked": {
                                    color: "#DDCA92",
                                  },
                                }}
                              />
                            }
                            label="Fluxo de caixa"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
      </TabPanel>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {updatingSubuser ? (
          <MDButton
            onClick={() => {
              handleCloseUpdateProcess();
            }}
            variant="gradient"
            sx={{
              width: "130px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#0B2F36",
              border: "2px solid #DDCA92",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            Cancelar
          </MDButton>
        ) : (
          <MDButton
            disabled={subuserData?.auth_user_id === user._id}
            onClick={() => {
              handleOpenDeleteSubuserDialog(subuserData);
            }}
            variant="gradient"
            sx={{
              width: "130px",

              padding: "10px",
              textTransform: "capitalize",
              color: "#0B2F36",
              border: "2px solid #DDCA92",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            Excluir
          </MDButton>
        )}
        <MDButton
          disabled={
            subuserData?.auth_user_id === user._id
              ? true
              : updatingSubuser
                ? disableButton
                : false
          }
          onClick={
            updatingSubuser ? handleSubmit(onSubmit) : handleUpdateProcess
          }
          variant="None"
          sx={{
            width: "130px",

            padding: "10px",
            textTransform: "capitalize",
            color: "#DDCA92",
            background:
              "linear-gradient(112.18deg, rgba(255,250,250,0.2) -37.45%, rgba(0,0,0,0) 176.32%), #0B2F36",

            border: "1px solid #DDCA92",
            borderRadius: "10px",
          }}
        >
          {updatingSubuser ? "Confirmar edição" : "Editar"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
