import React, { useState, useEffect } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, type SubmitHandler } from "react-hook-form";

import dayjs from "dayjs";

import { useAuth } from "../../../../hooks/useAuth";

import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import { Checkbox, FormControlLabel, Grid, InputLabel } from "@mui/material";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import { lawApi } from "../../../../services/api";

interface IInputs {
  value: number | string;
  date: string;
  date_payment: number | string;
  total_installment: number | string;
  description: string | null;
}

interface IEditForm {
  handleCloseEditForm: () => void;
  fetchRecords: () => Promise<void>;
  type: string;
  record: any;
  toastFeedbackMessage: (type: string, message: string) => any;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  value: yup
    .string()
    .matches(/^\d{1,6}$|(?=^.{1,6}$)^\d+\.\d{0,2}$/, "Valor inválido")
    .required("Informe o valor do registro"),
  date: yup.string().required("Informe a data do registro"),
  date_payment: yup
    .string()
    .matches(/^[0-9]*$/, "Dia inválido")
    .default(""),
  // .required("Informe o dia previsto."),
  total_installment: yup
    .string()
    .matches(/^[0-9]*$/, "Total de parcelas inválido")
    .default(""),
  // .required("Informe o total de parcelas"),
  description: yup.string().nullable().default(""),
});

export default function EditForm({
  handleCloseEditForm,
  fetchRecords,
  type,
  record,
  toastFeedbackMessage,
}: IEditForm) {
  const { user } = useAuth();
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });

  const [hasInstallments, setHasInstallments] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const handleChange = () => {
    setHasInstallments(!hasInstallments);
  };

  const onSubmit: SubmitHandler<IInputs> = async (data) => {
    setDisableButton(true);

    data.value = +data.value;
    data.total_installment =
      +data.total_installment > 0 ? +data.total_installment : 1;
    const _date = new Date(data.date);
    _date.setDate(_date.getDate() + 1);
    data.date_payment = +data.date_payment;

    const formData = {
      ...data,
      type,
      is_monthly: hasInstallments,
      date: _date,
    };

    // console.log(formData, record);

    try {
      const response = await lawApi.put(`/financial/${record?._id}`, formData);
      const data = response.data;

      if (data) {
        toastFeedbackMessage("success", `${type} atualizada com sucesso.`);
      }

      // console.log(data);
      void fetchRecords();
    } catch (err: any) {
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização do registro. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização do registro. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na atualização do registro. Verifique os dados informados!",
        );
      }
    } finally {
      setDisableButton(false);
      setHasInstallments(false);
      handleCloseEditForm();
    }
  };

  useEffect(() => {
    const fields = [
      "value",
      "date",
      "date_payment",
      "total_installment",
      "description",
    ];
    fields.forEach((field: any) => {
      if (field === "date") {
        const date = dayjs(record[field])
          .format("DD/MM/YYYY")
          .split("/")
          .reverse()
          .join("-");
        setValue(field, date);
      } else {
        setValue(field, record[field]);
      }
    });

    setHasInstallments(record?.is_monthly);
  }, [record, type]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        value: 0,
        date: "",
        date_payment: "",
        total_installment: 0,
        description: "",
      });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <MDBox
      sx={{ width: "90%" }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <MDTypography
        variant="h5"
        sx={{ color: type === "Entrada" ? "#0B2F36" : "#6B1111", pb: 2 }}
      >
        Editar {type}
      </MDTypography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox display="flex" flexDirection="column">
            <InputLabel
              sx={{ color: type === "Entrada" ? "#0B2F36" : "#6B1111", mb: 1 }}
            >
              Valor{" "}
              <MDTypography
                sx={{
                  fontSize: "12px",
                  color: "#f00",
                  display: "inline",
                }}
              >
                *
              </MDTypography>
            </InputLabel>
            <MDInput
              size="small"
              type="text"
              error={!(errors.value == null)}
              {...register("value")}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#DDCA92",
                  },
                },
              }}
              fullWidth
            />
            <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
              {errors.value?.message}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox display="flex" flexDirection="column">
            <InputLabel
              sx={{ color: type === "Entrada" ? "#0B2F36" : "#6B1111", mb: 1 }}
            >
              {type === "Entrada" ? "Data pagamento" : "Data de saída"}{" "}
              <MDTypography
                sx={{
                  fontSize: "12px",
                  color: "#f00",
                  display: "inline",
                }}
              >
                *
              </MDTypography>
            </InputLabel>
            <MDInput
              size="small"
              type="date"
              error={!(errors.date == null)}
              {...register("date")}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#DDCA92",
                  },
                },
              }}
              fullWidth
            />
            <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
              {errors.date?.message}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox display="flex" flexDirection="column">
            <InputLabel
              sx={{ color: type === "Entrada" ? "#0B2F36" : "#6B1111", mb: 1 }}
            >
              Descrição
            </InputLabel>
            <MDInput
              type="text"
              error={!(errors.description == null)}
              {...register("description")}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#DDCA92",
                  },
                },
              }}
              multiline
              rows={3}
              fullWidth
            />
            <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
              {errors.description?.message}
            </MDTypography>
          </MDBox>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox display="flex" flexDirection="column">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={hasInstallments}
                  color="primary"
                />
              }
              label={
                <MDTypography
                  variant="h6"
                  sx={{
                    color: type === "Entrada" ? "#0B2F36" : "#6B1111",
                    fontWeight: 500,
                  }}
                >
                  {type === "Entrada"
                    ? "Parcelado por mês"
                    : "Recorrência mensal"}
                </MDTypography>
              }
            />
          </MDBox>
        </Grid> */}
        {/* {hasInstallments && (
          <>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel
                  sx={{
                    color: type === "Entrada" ? "#0B2F36" : "#6B1111",
                    mb: 1,
                  }}
                >
                  {type === "Entrada" ? "Nº de parcelas" : "Nº de meses"}{" "}
                  <MDTypography
                    sx={{
                      fontSize: "12px",
                      color: "#f00",
                      display: "inline",
                    }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  size="small"
                  type="number"
                  error={!(errors.total_installment == null)}
                  {...register("total_installment")}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                  fullWidth
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.total_installment?.message}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <MDBox display="flex" flexDirection="column">
                <InputLabel
                  sx={{
                    color: type === "Entrada" ? "#0B2F36" : "#6B1111",
                    mb: 1,
                  }}
                >
                  Dia do mês previsto{" "}
                  <MDTypography
                    sx={{
                      fontSize: "12px",
                      color: "#f00",
                      display: "inline",
                    }}
                  >
                    *
                  </MDTypography>
                </InputLabel>
                <MDInput
                  size="small"
                  type="number"
                  error={!(errors.date_payment == null)}
                  {...register("date_payment")}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DDCA92",
                      },
                    },
                  }}
                  fullWidth
                />
                <MDTypography sx={{ fontSize: "12px", color: "#f00" }}>
                  {errors.date_payment?.message}
                </MDTypography>
              </MDBox>
            </Grid>
          </>
        )} */}
      </Grid>
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ mt: 3 }}
        gap="10px 0"
      >
        <MDButton
          disabled={disableButton}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{
            backgroundColor: type === "Entrada" ? "#0B2F36" : "#6B1111",
            color: "#fff",
            width: "176px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: type === "Entrada" ? "#0B2F36" : "#6B1111",
            },
          }}
        >
          Confirmar
        </MDButton>
        <MDButton
          onClick={() => {
            handleCloseEditForm();
            reset();
          }}
          variant="outlined"
          sx={{
            color: "#6B1111",
            width: "176px",
            borderRadius: "15px",
            border: "2px solid #6B1111",
            backgroundColor: "#transparent",
            "&:hover": {
              backgroundColor: "#transparent",
              border: "2px solid #6B1111",
            },
          }}
        >
          Cancelar
        </MDButton>
      </MDBox>
    </MDBox>
  );
}
