import { useState, useEffect } from "react";

import CryptoJS from "crypto-js";

import { Grid, Icon } from "@mui/material";
import { useNavigate, createSearchParams } from "react-router-dom";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import textHelp from "../../../../../utils/textHelp";
import toast from "react-hot-toast";

interface IUserHeader {
  matches: boolean;
  user: IUser;
  updatingProcess: boolean;
  handleUpdateProcess: () => void;
}

interface IUser {
  _id: string;
  auth_user_id: string;
  active: boolean;
  active_schedule: boolean;
  address_id: string;
  application: {
    id: string;
    permission: [] | null;
    role: string;
  };
  cpf: string;
  birth_date: string;
  social_status: string;
  identity: string;
  email: string;
  name: string;
  last_name: string;
  oab: string | null;
  office: string | null;
}

export default function UserHeader({
  matches,
  user,
  updatingProcess,
  handleUpdateProcess,
}: IUserHeader) {
  const navigate = useNavigate();
  const [encryptedUserID, setEncryptedUserID] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  const encryptUserID = () => {
    const parse = CryptoJS.enc.Utf8.parse(`${user._id}+${user.name}`);
    const encrypted = CryptoJS.enc.Base64url.stringify(parse);

    setEncryptedUserID(encrypted);

    const _url = process.env.REACT_APP_LAW_URL;
    setUrl(_url ?? "");
  };

  const navigateExternal = () => {
    navigate({
      pathname: "/agendamento",
      search: createSearchParams({
        id: encryptedUserID,
      }).toString(),
    });
  };

  useEffect(() => {
    encryptUserID();
  }, [user]);

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={12} md={4} lg={4}>
        {matches ? (
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            // alignItems="start"
            mt={matches ? 2 : 0}
            mb={matches ? 2 : 0}
          >
            <MDTypography
              sx={{ fontSize: "20px", color: "#fff", fontWeight: 700 }}
            >
              {`${user?.name} ${user?.last_name}`}
            </MDTypography>
            <MDTypography
              sx={{ fontSize: "14px", color: "#fff", fontWeight: 500 }}
            >
              OAB: {user?.oab}
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox display="flex" flexDirection="column">
            <MDTypography
              sx={{ fontSize: "20px", color: "#fff", fontWeight: 700 }}
            >
              {`${user?.name} ${user?.last_name}`}
            </MDTypography>
            <MDTypography
              sx={{
                fontSize: "14px",
                color: "#fff",
                fontWeight: 500,
                opacity: "50%",
              }}
            >
              OAB: {user?.oab}
            </MDTypography>
          </MDBox>
        )}
      </Grid>
      <Grid item lg={12} mt="40px">
        <MDBox></MDBox>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems={matches ? "center" : ""}
          sx={{
            mt: matches ? "16px" : "0px",
            mb: matches ? "16px" : "0px",
          }}
        >
          <MDTypography
            sx={{ color: "#fff", fontWeight: 500, fontSize: "14px" }}
          >
            Tipo de usuário:{" "}
            {user.application.role === "admin" ? "Administrador" : "Usuário"}
          </MDTypography>
          <MDBox display="flex" alignItems="center">
            <MDTypography
              sx={{
                color: "#FFFFFF",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {user.active_schedule
                ? "Agendamento ativo"
                : "Agendamento inativo"}
            </MDTypography>
            <MDBox
              sx={{
                width: 10,
                height: 10,
                borderRadius: 50,
                backgroundColor: user.active_schedule ? "#27FF23" : "#6B1111",
                ml: 1,
              }}
            ></MDBox>
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={5}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems={matches ? "center" : ""}
        >
          <MDTypography
            sx={{ color: "#fff", fontWeight: 500, fontSize: "14px" }}
          >
            Link de agendamento
            <Icon fontSize="small" sx={{ fontSize: 15, marginLeft: 2 }} style={{ cursor: "pointer" }} onClick={() => {
              const isCopied = textHelp.copyText(`${window.location.protocol}//${window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname}/agendamento?id=${encryptedUserID}`)
              if (isCopied) {
                toast.success(`Link copiado com sucesso`, {
                  duration: 5000,
                });
                return
              }

              toast.error(`Erro ao copiar link`, {
                duration: 5000,
              });

            }}>
              content_copy
            </Icon>
          </MDTypography>
          {user.active_schedule ? (
            <a href={`${window.location.protocol}//${window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname}/agendamento?id=${encryptedUserID}`} target="_blank" rel="noopener noreferrer">
              <MDTypography
                // component={Link}
                // to={`/agendamento?professional_id=${encryptedUserID}`}
                // onClick={navigateExternal}
                sx={{
                  color: "#DDCA92",
                  fontWeight: "regular",
                  fontSize: "14px",
                  // opacity: "50%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: 'center',
                  wordBreak: "break-all",
                }}
              >
                {`${window.location.protocol}//${window.location.hostname === "localhost" ? "localhost:3000" : window.location.hostname}/agendamento?id=${encryptedUserID}`}
                <Icon fontSize="small" sx={{ fontSize: 15, marginLeft: 2 }}>
                  open_in_new
                </Icon>
              </MDTypography>
            </a>
          ) : (
            <MDTypography
              sx={{
                color: "#DDCA92",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              Link indisponível
            </MDTypography>
          )}
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <MDBox
          display="flex"
          justifyContent={matches ? "center" : ""}
          mt={matches ? 2 : 0}
        >
          <MDButton
            onClick={handleUpdateProcess}
            variant="contained"
            sx={{
              color: "#000",
              textTransform: "capitalize",
              backgroundColor: updatingProcess ? "#f00" : "#fff",
              "&:hover": {
                backgroundColor: updatingProcess ? "#f00" : "#fff",
                color: updatingProcess ? "#fff" : "#000",
              },
              "&:active": {
                backgroundColor: updatingProcess ? "#f00" : "#fff",
              },
            }}
          >
            {updatingProcess ? "Cancelar Edição" : "Editar Perfil"}
          </MDButton>
        </MDBox>
      </Grid>
    </Grid>
  );
}
