/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import InputMask from "react-input-mask";

// Custom styles for MDInput
import MDInputRoot from "./MDInputRoot";

const MDInput = forwardRef(
  ({ error, success, disabled, mask, value, ...rest }, ref) =>
    mask ? (
      <InputMask
        // maskChar={null}
        mask={mask}
        value={value}
        {...rest}
        {...{ ...rest, maskChar: null, alwaysShowMask: false }}
        // alwaysShowMask={false}
        maskPlaceholder={null}
        maskChar={null}
        disabled={disabled}
      >
        {/* <> */}
        <MDInputRoot
          {...rest}
          value={value}
          ref={ref}
          ownerState={{ error, success, disabled }}
        ></MDInputRoot>
        {/* </> */}
      </InputMask>
    ) : (
      <MDInputRoot
        {...rest}
        value={value}
        ref={ref}
        ownerState={{ error, success, disabled }}
      ></MDInputRoot>
    ),
);

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  mask: "",
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
};

export default MDInput;
