import * as React from "react";

import { lawApi } from "../../../../../services/api";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";

interface IDeleteUserDialog {
  openDeleteProcessDialog: boolean;
  processToDelete: any;
  index: number;
  handleCloseDeleteProcessDialog: () => void;
  fetchSubusers: () => Promise<void>;
  toastFeedbackMessage: (type: string, message: string) => void;
  setIsRemove: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DeleteProcessFileDialog({
  openDeleteProcessDialog,
  processToDelete,
  index,
  handleCloseDeleteProcessDialog,
  fetchSubusers,
  setIsRemove,
  toastFeedbackMessage,
}: IDeleteUserDialog) {
  const handleDeleteProcess = async () => {
    try {
      const response = await lawApi.put(
        `/process/files/${processToDelete?._id}/${index}`,
      );
      const data = response.data;

      if (data) {
        toastFeedbackMessage("success", "Arquivo deletado com sucesso.");
        setIsRemove(true);
      }

      // console.log(data);
      void fetchSubusers();
    } catch (err: any) {
      // console.log(err.message);
      const code = err.response.data.statusCode;

      if (code === 404) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do processo. Tente novamente mais tarde!",
        );
      } else if (code === 500) {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do processo. Mas não se preocupe, não foi culpa sua.",
        );
      } else {
        toastFeedbackMessage(
          "error",
          "Ocorreu um erro na exclusão do processo. Verifique os dados informados!",
        );
      }
    } finally {
      handleCloseDeleteProcessDialog();
    }
  };

  return (
    <Dialog
      open={openDeleteProcessDialog}
      onClose={handleCloseDeleteProcessDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ padding: "40px 50px 30px 50px", backgroundColor: "#0B2F36" }}
      >
        <DialogContentText sx={{ textAlign: "center" }}>
          <MDTypography sx={{ color: "#fff" }}>
            Tem certeza que deseja excluir o arquivo do processo
          </MDTypography>
          <MDTypography sx={{ fontWeight: 700, color: "#fff" }}>
            {index ? processToDelete?.files[index].title : ""}
          </MDTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0B2F36",
        }}
      >
        <MDButton
          variant="outlined"
          onClick={handleCloseDeleteProcessDialog}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Não
        </MDButton>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <MDButton
          variant="outlined"
          onClick={handleDeleteProcess}
          sx={{ width: "150px", borderColor: "#DDCA92", borderRadius: "15px" }}
        >
          Confirmar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
