/* eslint-disable no-useless-escape */
import { useCallback, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, type SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import {
  Avatar,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import DiscordIcon from "@mui/icons-material/Discord";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { MuiTelInput } from "mui-tel-input";
import toast from "react-hot-toast";
import DiscordIcon from "../../assets/images/icons/discord.svg";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import { lawApi } from "../../services/api";
import "./index.css"

interface IInputs {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  // topic: string;
  message: string;
}

const schema: yup.ObjectSchema<IInputs> = yup.object({
  email: yup
    .string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "E-mail inválido")
    .required("Informe seu e-mail"),
  phone: yup
    .string()
    // .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Telefone inválido")
    .required("Informe seu telefone"),
  name: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .required("Informe seu nome"),
  lastname: yup
    .string()
    .min(3, "No mínimo 3 caracteres")
    .required("Informe seu sobrenome"),
  message: yup.string().required("Informe seu mensagem"),
});

export default function SupportForm(): JSX.Element {
  const theme = useTheme();
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
  });
  const [openLoading, setOpenLoading] = useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleOpenLoading = () => {
    setOpenLoading(true);
  };

  const [phone, setPhone] = useState<string>("");
  const [topic, setTopic] = useState<string>("");
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (newPhone: string): void => {
    setPhone(newPhone);
    setValue("phone", newPhone);
  };

  // const handleChangeTopic = (topi: string): void => {

  // };

  const onSubmit: SubmitHandler<IInputs> = useCallback(async (data) => {
    handleOpenLoading();

    try {
      const response = await lawApi.post("/email/support", {
        email: data.email,
        name: data.name,
        lastname: data.lastname,
        phone: data.phone,
        message: data.message,
        topic,
      });

      reset({
        email: "",
        lastname: "",
        message: "",
        name: "",
        phone: "",
      });
      toast.success(
        "E-mail enviado com sucesso! Aguarde o nosso suporte te responder",
        {
          duration: 5000,
        },
      );
    } catch (err) {
      console.log(err);
    } finally {
      handleOpenLoading();
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar title="Suporte" />
      <MDBox
        display="flex"
        mt={4}
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Grid
          container
          direction="row"
          spacing={matches ? 0 : 2}
          sx={{
            backgroundColor: "#FFFFFF",
            boxShadow: "box-shadow: 0px 0px 60px 30px rgba(0,0,0,0.3)",
            padding: "10px",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              sx={{
                backgroundColor: "#0B2F36",
                padding: "40px",
                borderRadius: "10px",
                // minHeight: "600px",
              }}
              gap="50px 0"
            >
              <MDBox display="flex" flexDirection="column">
                <MDTypography
                  sx={{ color: "#FFFFFF", fontWeight: 600, fontSize: "24px" }}
                >
                  Fale com o nosso suporte
                </MDTypography>
                <MDTypography
                  sx={{
                    color: "#C9C9C9",
                    fontWeight: "regular",
                    fontSize: "16px",
                  }}
                >
                  Dúvidas, ouvidoria e melhorias
                </MDTypography>
              </MDBox>
              <MDBox display="flex" flexDirection="column" gap="30px 0">
                <MDBox
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap="0 10px"
                >
                  <PhoneIcon sx={{ color: "#FFFFFF" }} />
                  <MDTypography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
                    +55 73 99142-0671
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap="0 10px"
                >
                  <EmailIcon sx={{ color: "#FFFFFF" }} />
                  <MDTypography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
                    contato@jupiterspace.com.br
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox display="flex" justifyContent="flex-start" gap="0 20px">
                {/* <IconButton
                  sx={{
                    backgroundColor: "#BFA350",
                    "&:hover": { backgroundColor: "#fff" },
                  }}
                >
                  <LinkedInIcon
                    sx={{ color: "#FFFFFF", "&:hover": { color: "#BFA350" } }}
                  />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: "#BFA350",
                    "&:hover": { backgroundColor: "#fff", color: "#BFA350" },
                  }}
                >
                  <InstagramIcon
                    sx={{ color: "#FFFFFF", "&:hover": { color: "#BFA350" } }}
                  />
                </IconButton> */}
                {/* <IconButton
                  sx={{
                    backgroundColor: "#BFA350",
                    "&:hover": { backgroundColor: "#fff" },
                  }}
                >
                  <Avatar
                    src={DiscordIcon}
                    sx={{
                      width: 25,
                      height: 25,
                      // mask: `url(${DiscordIcon}) no-repeat center`,
                      "&:hover": {
                        filter:
                          "brightness(0) saturate(100%) invert(82%) sepia(29%) saturate(859%) hue-rotate(356deg) brightness(82%) contrast(78%)",
                      },
                    }}
                  />
                </IconButton> */}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Grid container spacing={2} sx={{ padding: "50px 20px" }}>
              <MDBox display="flex" flexDirection="column">
                <MDTypography
                  sx={{ color: "#0B2F36", fontWeight: 600, fontSize: "24px" }}
                >
                  Acesse nossos canal oficial de suporte!
                </MDTypography>
                <MDTypography
                  sx={{
                    color: "#0B2F36",
                    fontWeight: "regular",
                    fontSize: "16px",
                  }}
                >
                  Dúvidas, ouvidoria e melhorias
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid container spacing={2} sx={{ padding: "20px 20px" }}>
              <MDBox display="flex" flexDirection="row" style={{ width: "100%" }}>
                <a className="link-suport" href="https://jupiterspace.com.br/suporte" target="_blank" rel="noopener noreferrer">
                  Suporte
                </a>
                <a className="link-suport" href="https://jupiterspace.com.br/law#price" target="_blank" rel="noopener noreferrer" >
                  Planos
                </a>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
